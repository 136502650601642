@import 'project_variables.scss';

.refresh-page {
  background: $LightBlue;
  text-align: center;
  line-height: 30px;
  height: 0;
  overflow: hidden;
  transition: $middle;
  color: $Blue;

  &.refresh-page_visible {
    height: 32px;
    overflow: visible;
  }
}

.refresh-page__link {
  font-weight: bold;
  color: $Blue;
  cursor: pointer;
}
