@import 'project_variables.scss';

.api-settings-container {
  .content {
    box-shadow: $LightBoxShadow;
    padding: 20px 12px;
    background: $White;
  }
  .ant-typography {
    margin-bottom: 0;
    height: 32px;
  }
  .api-settings__log-table-header {
    text-transform: none;
  }
  .api-settings__log-table-row {
    border-bottom: 1px solid $AntTableBorderGrey;
  }
  .info-text {
    padding-right: 36px;
  }
}

.api-settings__token {
  border: 1px solid $AntBorderGrey;
  border-radius: 2px;
}

.api-settings__log-spinner.ant-spin {
  width: 100%;
  margin-top: 50px;
}

.api-settings__log-table {
  box-shadow: $LightBoxShadow;

  .ReactVirtualized__Grid__innerScrollContainer {
    box-shadow: $LightBoxShadow;
  }
}
