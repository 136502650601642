@import 'project_variables.scss';

.xml-preview-modal {
  background: $White;
  width: 70%;
  min-height: 200px;
  max-height: 80%;
  overflow: auto;

  pre {
    margin: 0;
  }

  .loader__wrapper {
    display: flex;
    align-items: center;
    height: 200px;
  }
}

.ant-menu .ant-menu-item a {
  text-decoration: none;
  color: inherit;
}

.main-navigation {
  .ant-layout-sider-trigger {
    height: 100px;
  }

  .main-navigation__bottom {
    height: 100px;
    cursor: default;
  }

  .main-navigation__header {
    display: flex;
    justify-content: flex-end;

    .trigger {
      padding-top: 20px;
    }

    &.small {
      justify-content: center;

      .trigger {
        &.qa,
        &.dev {
          width: 100%;
        }
      }
    }
  }

  &.main-navigation-collapsed {
    .ant-layout-sider-trigger {
      height: 100px;
    }
    .main-navigation__bottom {
      height: 100px;
    }
    &.sidebar-left {
      margin-bottom: 100px;
    }
  }

  .trigger {
    font-size: 24px;
    color: $FontColorWhite;
    padding: 16px 12px;
    cursor: pointer;
    transition: color 0.3s;

    &.qa {
      background: yellow;
      color: black;
    }
    &.dev {
      background: $Green;
      color: black;
    }
  }

  .trigger:hover {
    color: $White;
  }

  .sidebar__brand-search-wrapper {
    padding: 5px;
  }

  .sidebar__brand-search {
    &.ant-input-affix-wrapper {
      background-color: inherit;
      color: $White;

      input {
        background-color: inherit;
        color: $White;
      }
    }
  }
}

.sidebar-menu__with-badge {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .ant-badge {
    .ant-scroll-number {
      height: 16px;
      padding-right: 6px;
      line-height: 16px;
    }
    .ant-badge-count {
      box-shadow: none;
    }
  }
}

.env-hint {
  flex: 1;
  display: flex;
  font-size: 20px;
  justify-content: center;
  padding-top: 16px;

  &.qa {
    background: yellow;
  }

  &.dev {
    background: $Green;
  }
}
