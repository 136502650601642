@import 'project_variables.scss';

.shop-preview__main-container {
  flex-direction: column;
  width: 100%;
}

.header__shop-preview {
  min-width: 500px;
  height: 118px;
  overflow: hidden;
  position: relative;
  width: 100%;
  margin-left: 50px;
  margin-bottom: 20px;
  border: 1px solid $Grey;
  background: $LightGrey;
  display: none;
  @media (min-width: 1600px) {
    display: flex;
  }

  &_not-single-item {
    @extend .header__shop-preview;
    background-color: $White;
  }

  &-text {
    color: $Blue;
    margin: 0;
    padding: 10px;
  }
}

.small-shop-preview__no-value {
  color: $Grey;
}

.small-shop-preview__title {
  color: #188ca6;
  font-size: 18px;
  line-height: 30px;
  height: 30px;
  overflow: hidden;
}

.small-shop-preview__value {
  margin-right: 5px;
}

.header__shop-preview-img {
  flex: 0 0 120px;
  position: relative;
  background: $White;
  margin: 10px;
  margin-right: 40px;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

.header__shop-preview-center {
  width: 100%;
}

.small-shop-preview__main-information,
.small-shop-preview__attributes-information {
  width: 50%;
}

.small-shop-preview__attributes {
  line-height: 20px;
  height: 60px;
  overflow: hidden;
}

.header__shop-preview-right {
  flex-direction: column;
  line-height: 30px;
  flex: 0 0 100px;
  width: 100px;
  text-align: right;
  margin-right: 5px;
  margin-left: auto;
}

.small-shop-preview__categories {
  margin-right: 0;
  margin-left: auto;
  color: $Grey;
  line-height: 30px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  position: relative;
  width: 90%;
}

.small-shop-preview__quickview {
  color: $Blue;
  .icon-preview--cart {
    position: relative;
    font-size: 25px;
    bottom: -5px;
  }
}

.small-shop-preview__price {
  position: relative;
  margin-bottom: 0;
  margin-top: auto;
  font-weight: bold;
}
