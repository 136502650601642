@import 'project_variables.scss';

.login__background {
  height: 100vh;
  width: 100%;
  background-image: url(../images/bg/bg_login.jpg);
  background-position: center center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  overflow: scroll;
  display: flex;
  flex-direction: column;
}

.login__box {
  flex: 1;
  padding-top: 40px;
  padding-bottom: 40px;
  margin: 0 auto;
  width: 420px;
}

.login__box-content {
  padding: 40px;
  background: $White;
  box-shadow: $LightBoxShadow;

  .login__error-message {
    padding-top: 20px;
  }

  .ant-form-item {
    margin-bottom: 16px;
  }
}

.login__box-header {
  text-align: center;
  margin-bottom: 30px;
}

.login--header-content {
  line-height: 1.5;
  margin-bottom: 40px;
  text-align: center;
}

.login__title {
  font-size: 32px;
  font-weight: 500;
}

.login-logo {
  background: url(../images/logos/PDM-Logo.svg);
  width: 218px;
  height: 80px;
  margin-left: auto;
  margin-right: auto;
}

.login__footer {
  width: 100%;
  text-align: center;
  margin-bottom: 20px;
  font-size: 0.8em;
  color: $White;
}

.login__footer-link {
  color: $White;
  margin-left: 20px;
}

.verify__content-icon {
  color: $Blue;
  font-size: 70px;
  margin-left: auto;
  margin-right: auto;
}
