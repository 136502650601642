@import 'project_variables.scss';

.kit__edit-form {
  .ant-form-item {
    margin-bottom: 8px;
  }

  .ant-input-group-addon {
    text-align: left;

    .ant-form-item {
      padding-bottom: 0;
    }

    .ant-select-single {
      .ant-select-selector {
        width: 130px;
      }

      &.ant-select {
        .ant-select-selector:hover {
          border: 1px solid $InputFocusBlue;
        }
      }
    }

    .ant-select-focused {
      .ant-select-selection {
        border: 1px solid $InputFocusBlue;
        box-shadow: 0 0 2px rgba(24, 140, 166, 0.2);
      }
    }
  }
}

.kit__edit__button {
  &.ant-btn-sm {
    height: 21px;
  }
}

.kit__page__table {
  .ant-table-thead {
    background-color: $AntTableHeaderGrey;
  }
  .ant-table-small .ant-table-content .ant-table-header {
    background-color: $AntTableHeaderGrey;
  }
}

.kit__input-addon {
  .ant-form-item-control {
    height: 30px;
  }
  .ant-form-item-control-input {
    min-height: 30px;
  }

  &.ant-form-item {
    margin-bottom: 0px;
  }
}

.kit__standalone-form-label {
  .ant-form-item-control {
    height: 0;
  }
}

.kit__analyses-alert-icon {
  padding: 0 !important;
}
