@import 'project_variables.scss';

.import-page__header {
  max-width: 1240px;
}

.import-page__content {
  background: $White;
  overflow-y: scroll;
  padding-top: 20px;
  padding-left: 20px;
  padding-right: 10px;
}

.import-format-options {
  max-width: 1240px;
  margin: 32px 0 !important;
  .ant-form-item-label > label::after {
    display: none;
  }
  .ant-form-item-label {
    font-weight: 500;
  }
  &__multi-select {
    .ant-select-selector .ant-select-selection-item {
      background-color: $LightBlue;
      border-color: $Blue;
      color: $Blue;
    }
  }
}

.automate-import-form {
  margin-top: 40px !important;
}

.gap-coverage-step-2 {
  .ant-tag {
    margin: 0;
    color: $Blue;
    border-radius: 8px;
    border-color: $Blue;
    line-height: 14px;
    align-content: center;
  }
  .anticon-info-circle {
    svg {
      color: $Blue;
      font-size: 18px;
    }
  }
}

.export-format-options__multi-select {
  .ant-select-selector .ant-select-selection-item {
    background-color: $LightBlue;
    border-color: $Blue;
    color: $Blue;
  }
}

.export__receiver-form {
  margin: 32px 0 !important;
  .ant-form-item-label label {
    &::after {
      content: '';
    }
  }
}
.export-started {
  margin: 32px 0;
  text-align: center;
  .info-started {
    font-size: 20px;
    color: $Green;
  }
  font-size: 15px;
}
.export-steps__form {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
}
.export-steps {
  padding-right: 20px !important;

  .ant-steps-item-container {
    height: 54px;
  }
  .ant-steps-item-process .ant-steps-item-container .ant-steps-item-content .ant-steps-item-title {
    color: $Blue;
  }
  .ant-steps-item-container .ant-steps-item-content .ant-steps-item-description {
    white-space: nowrap;
  }
}
.export-format-options {
  margin: 32px 0 !important;
  .ant-divider-horizontal.ant-divider-with-text-right::after {
    width: 0%;
  }
  .antdivider__toggle {
    margin-left: 0;
  }
  .ant-form-item-label > label::after {
    display: none;
  }
  .channel__switch-tag {
    font-weight: 500;
    font-size: 16px;
    padding-right: 0;
  }
  &__advanced-options {
    .ant-divider-horizontal {
      border-top: 1px solid !important;
      --tw-border-opacity: 0.6;
      border-top-width: 4px;
      border-color: rgba(0, 0, 0, var(--tw-border-opacity));
    }
  }
  .ant-form-item-label {
    font-weight: 500;
  }
}
.export-select-format {
  margin: 32px 0 !important;
}
.radio-style {
  display: block !important;
  margin-bottom: 10px !important;
}
.export-select-parts {
  margin: 32px 0;
  .ant-form {
    display: grid;
  }
  .select-options {
    .ant-form-item {
      width: 180px;
    }
    .ant-form-item-control-input {
      align-items: flex-start;
    }
  }
  .database-selection {
    margin-top: 22px;
  }
}

.import-export-container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.excel-flyout__tabs {
  display: flex;
  padding-left: 10px;

  .tab {
    padding-right: 20px;
    cursor: pointer;

    &.tab-selected {
      color: $Blue;
    }
    &.tab-disabled {
      color: $Grey;
      cursor: default;
    }
  }
}

.excel-flyout__inner-container {
  margin: 10px;
  display: flex;
  flex: 1;
  padding: 1px;
}

.excel-flyout__row {
  padding: 0 20px;
  cursor: pointer;
  display: block;
}

.excel-flyout__export-title {
  padding: 0 20px;
  display: inline-block;
}

.excel-flyout__loader {
  border: 3px solid $LightGrey;
  border-top: 3px solid $Blue;
  border-radius: 50%;
  width: 10px;
  height: 10px;
  margin-left: 10px;
  animation: spin 2s linear infinite;
}

#excel-flyout__import {
  width: 100%;
  height: 100%;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  cursor: pointer;
}

.excel-import__table {
  width: 100%;
  height: 100%;
  overflow: auto;

  .ReactVirtualized__Table__headerRow {
    border: 1px solid #e8e8e8;
  }

  .ReactVirtualized__Table__row {
    border-left: 1px solid #e8e8e8;
    border-right: 1px solid #e8e8e8;
  }
}

.export__info-box {
  flex: 1;
}

.export-log__buttons {
  display: flex;
  justify-content: space-around;
  align-items: stretch;
  height: 22.5px;
}

.export-log__button-error {
  margin-right: 5px;
}

.export-log__title {
  margin-bottom: 5px;
}

.export-log__title-text {
  margin-right: 10px;
  color: $Blue;
}

.export-log__dates-text {
  margin-right: 10px;
}

.export-log__date {
  color: $Grey;
  margin-right: 20px;
}

.export-log__icon {
  color: $IconGrey;
  top: 2px;
  position: relative;
}

.export-wrapper {
  width: 100%;
  height: 100%;
}

.export__brand-selection {
  display: flex;
  align-items: center;
  margin: 20px 20px 0;
  height: 5%;
}

.export__brand-name {
  margin-right: 10px;
  size: 2rem;
}

.export__gap-vehicle-wrapper {
  .application__column {
    width: 100%;
  }

  .application__title {
    height: 29px;
  }
}

.import-export {
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  width: 100%;
  height: 100%;
  padding: 20px;
  overflow: scroll;
}

.import-export__tilte-container {
  margin-bottom: 10px;
}

.import-export__title {
  margin: 0;
  font-weight: 600;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  color: $Blue;
}

.import-export__sub-title {
  margin: 0;
  padding: 0;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
}

.import-export__current-step {
  color: $Blue;
}

.import-export__col {
  display: flex;
  flex-direction: column;
  width: 33%;
  padding-right: 40px;
  margin-bottom: 40px;
  min-width: 400px;
}

.import-export__box {
  position: relative;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 15px;
  padding: 5px;
  flex: 0 0 110px;
  height: 150px;

  &.import-export__selection {
    cursor: pointer;
    &:hover {
      background-color: $LightBlue;
    }
  }

  &.import-export__no-selection {
    cursor: not-allowed;
    color: $Grey;
    &:hover {
      background-color: $White;
    }
  }
}

.import-export__box-options {
  padding: 10px;
  display: flex;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
  background: $LightBlue;
  color: $Black;
  align-items: center;

  .options-row {
    display: flex;
    width: 100%;

    input {
      margin-right: 3px;
      margin-bottom: 3px;
      vertical-align: bottom;
    }
  }

  .options-row__name {
    width: 100px;
    margin-bottom: 10px;
  }

  .option-row__option {
    margin-right: 10px;
  }
}

.import-export__dropdown {
  border: 1px solid $Black;
  background: $White !important;
  padding: 0 3px;
}

.import-export__inactive {
  opacity: 0.3;
  user-select: none;
}

.import-export__box--selected {
  background-color: $Blue;
  color: $White;
}

.import-export__box-background {
  opacity: 0.1;
  position: absolute;
  font-size: 6em;
}

.import-export__box--full {
  @extend .import-export__box;
  flex: 0 0 235px;
}

.import-export__button {
  border: 1px solid $Blue;
  color: $Blue;
  display: flex;
  flex-direction: row;
  cursor: pointer;

  &:hover {
    background-color: $LightBlue;
  }
}

.import-export__upload {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  flex: 1;
  &--hover {
    @extend .import-export__upload;
    border: 1px dashed $Blue;
    color: $Blue;
  }
}

.import-export__upload-info {
  color: $Blue;
  font-size: 0.8em;
}

.import-export__select-segments-container {
  margin-bottom: 10px;
  flex: 0 0 115px;
}

.import-export__select-all {
  margin-bottom: 5px;
}

.import-export__select-segment-container {
  display: flex;
  flex-wrap: wrap;
  line-height: initial;
}

.import-export__select-segment {
  width: 50%;

  input {
    margin: 3px;
    vertical-align: bottom;
  }
}

.import-export__application-grouping {
  margin-top: 20px;
}

.export__info-container {
  width: 100%;
}

.button-pdm {
  font-size: 0.8em;

  .button__icon {
    font-size: 1.4em;
    bottom: 0;
  }
}

.import-export__export-template-select {
  width: 200px;
}

.ant-alert.ant-alert-info.import-export__alert {
  margin-top: 15px;
  list-style-position: inside;
}

.import-export__alert-link {
  text-decoration: underline;
  cursor: pointer;
  color: $Blue;
}

.import-export-logs__colum-order-form {
  .ant-form-item {
    margin-bottom: 0;
    margin-right: 7px;
  }
}

.import-export__order-row {
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 3px;
  padding-bottom: 3px;
  margin-bottom: 7px;
  border: 1px solid $AntBorderGrey;
}

.import-export__row-dragging {
  background: #fafafa;
  border: 1px solid #ccc;
  z-index: 1010;

  .ant-form-item {
    margin-bottom: 0;
    margin-right: 7px;
  }
}

.import-export__drag-icon {
  color: $AntIconGrey;
  cursor: grab;
  font-size: 20px;
}

.translation-attribute-import-modal {
  .ant-upload.ant-upload-select-picture-card {
    width: 100%;
  }
  .background-icon-excel {
    opacity: 0.1;
    font-size: 4em;
  }
}

.import-receiver__upload {
  .ant-upload.ant-upload-select-picture-card {
    width: 100%;
    height: 150px;
  }

  .background-icon-excel {
    opacity: 0.1;
    font-size: 4em;
  }
}
