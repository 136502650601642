@import 'project_variables.scss';

.text-editor-container {
  display: flex;
  flex-direction: column;
  border: 1px solid $AntBorderGrey;
  border-radius: 4px;
  min-height: 150px;
  li {
    list-style: initial;
  }
  ul {
    padding-left: 20px;
  }

  .DraftEditor-root {
    flex: 1;
  }

  div.block {
    padding-left: 20px;
  }

  .public-DraftEditorPlaceholder-root {
    // for placeholder staying in same line
    position: absolute;
    padding-left: 20px;
  }

  .DraftEditor-root,
  .DraftEditor-editorContainer,
  .public-DraftEditor-content {
    .public-DraftEditorPlaceholder-inner {
      color: $Grey;
      white-space: nowrap !important;
    }
  }
}
.text-editor__options {
  border-top: 1px solid $AntBorderGrey;

  .text-editor__count {
    margin-top: initial;
  }
}

.text-editor__buttons {
  z-index: 26;
  margin-right: 0;
  transition: 0.3s;
  overflow: hidden;
}

.RichEditor-styleButton {
  margin: 8px;
  cursor: pointer;
}
