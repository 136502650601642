@import 'project_variables.scss';

.insights {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.insights__report {
  flex: auto;
}

.insights__report-input {
  width: 310px !important;
  margin-right: 5px !important;
}
