@import 'project_variables.scss';

.clearfix {
  &:after {
    content: '';
    display: table;
    clear: both;
  }
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

#content {
  margin-top: 30px;
  font-weight: bold;
}

.content__wrapper {
  height: 100%;
  min-height: 70vh;
  overflow: hidden;
}

.content {
  background: $White;
  overflow: hidden;
  width: 100%;
  position: relative;
  padding: 20px;
}

.content__center-container {
  width: 100%;
  flex-direction: column;
}

.content__mapping-container {
  display: flex;
  flex: 1;
  background-color: $White;
  padding: 0 10px 10px 10px;
  overflow: auto;
  width: 100%;
}

.title {
  color: $Blue;
  font-size: 1.2em;
  line-height: 30px;
  user-select: none;
}

.hidden {
  display: none;
}

.disabled {
  pointer-events: none;
  opacity: 0.4;
  filter: grayscale(100%);
  -webkit-filter: grayscale(100%);
  -moz-filter: grayscale(100%);
  -ms-filter: grayscale(100%);
  -o-filter: grayscale(100%);
  filter: gray;
}

.float__left {
  float: left;
}

.button {
  border: solid 1px $Grey;
  border-radius: 0;
  cursor: pointer;
  background: $White;
}

.divider {
  border-top: 1px solid $Blue;
}

.box {
  border-left: 1px solid $Grey;
  border-bottom: 1px solid $Grey;
}

.box_background {
  box-shadow: -3px 3px 4px rgba(0, 0, 0, 0.08), 0px 0px 3px rgba(0, 0, 0, 0.15);
}

.box_content {
  box-shadow: -1px 1px 2px rgba(0, 0, 0, 0.15) inset;
}

.rank0 {
  border-left: 3px solid $Blue;
}
.rank1 {
  border-left: 3px solid $Green;
}
.rank2 {
  border-left: 3px solid $Yellow;
}
.rank3 {
  border-left: 3px solid $Red;
}

.rank0.mapped,
.mapped {
  background: $LightBlue;
}
.rank1.mapped {
  background: $LightGreen !important;
}
.rank2.mapped {
  background: $LightYellow !important;
}
.rank3.mapped {
  background: $LightRed !important;
}
.numeric.mapped {
  background: $LightGreen !important;
}

.placeholder-for-item-name {
  height: 50px;
  margin-bottom: 15px;
}

.loader-background {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 999;
  background: $BlackTransparent;

  .loader {
    top: 50%;
    position: relative;
    transform: translateY(-50%);
  }
}

.loader {
  border: 4px solid #f3f3f3; /* Light grey */
  border-top: 4px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 23px;
  height: 23px;
  animation: spin 1.5s linear infinite;
  margin: 0 auto;
}

.tooltip {
  max-width: 280px;
  white-space: normal;
}

input,
button,
select,
textarea {
  font-family: inherit;
}

.info-text {
  color: $Grey;
  font-size: 14px;
}

.temp {
  background-color: $LightBlue;
}

img {
  vertical-align: initial;
}

input[type='text'],
input[type='number'],
input[type='date'],
input[type='password'] {
  appearance: textfield;
}
