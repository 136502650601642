@import 'project_variables.scss';

.attribute-table__header {
  font-weight: 500;
  color: $Black;
  line-height: 40px;
}
.attributes-base-table {
  .BaseTable__row--hovered {
    background-color: $AntHoverBlue;
  }
  .attribute__values-cell {
    width: 100%;
    line-height: 28px;
    padding-top: 6px;
    padding-bottom: 4px;

    &:hover {
      background-color: $AntHoverBlue;

      &.attributes__selected-cell {
        background-color: $Blue;
      }

      .attribute__add-value {
        display: inline-block;
      }
    }
  }

  .attribute__add-value-wrapper {
    width: 18px;
  }
  .attribute__add-value {
    display: none;
    margin-left: 3px;
    font-size: 16px;

    &.visible {
      display: inline-block;
    }
  }
}
.attributes-table__attribute-name {
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.attributes-table__attribute-values {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.attribute-name {
  margin-bottom: 24px;
  .ant-form-item {
    margin: 0;
  }
}

// ---------------------------------------------------------

.attributes-table___border {
  border-bottom: 1px solid $MediumGrey;
}

.attribute__divider {
  .ant-divider-horizontal {
    margin: 2px 0px;
  }
}

.attributes-values-search {
  display: flex;
  align-items: center;
  border: 1px solid $AntBorderGrey;
  border-bottom: 0;
  height: 40px;
  margin: 0 10px;
  padding: 4px;
  width: 328px;
  &.bottom-border {
    border-bottom: 1px solid $AntBorderGrey;
  }
  .anticon-plus-circle {
    color: $Blue;
  }
  .ant-btn {
    width: 15%;
  }
}

.attributes-values-list {
  margin: 0 10px;
  border: 1px solid $AntBorderGrey;
  .ant-btn {
    color: $Blue !important;
  }
  .attributes__drawer-value-cell {
    padding-right: 5px;

    .edit-value {
      display: none;
    }
    .edit-button__values {
      display: inline-block;
      color: $Blue;
    }
    &:hover {
      background-color: $AntHoverBlue;
      .edit-value {
        display: inline-block;
        color: $Blue;
      }
    }
    &.mapped_value_blue {
      background-color: $LightBlue;
    }
    &.mapped_value_green {
      background-color: $Green;
    }
  }
  .count {
    color: $Blue;
  }
}

.attribute__values-drawer {
  position: absolute;
  background-color: white;
  border: 1px solid $AntBorderGrey;
  padding-bottom: 15px;
  margin-top: -41px;
  right: 0;
  height: 100%;
}

.attributes__selected-cell {
  background-color: $Blue;
}
.attributes__copied-cell {
  border: 1px dashed $White;
}

.attributes-table__cells {
  &:hover {
    background-color: $AntHoverBlue;
  }
  .anticon {
    color: $Blue;
  }
}

.attributes-table__virtualized {
  margin: 8px;
  font-weight: 500;
  padding-left: 12px;
}

.attributes-table__recommendation {
  height: 40px;
  &.green-color {
    border: 1px solid $Green;
    background-color: $Green;
  }
  &.blue-color {
    border: 1px solid $Blue;
    background-color: $Blue;
  }
}

.attribute_popover {
  .ant-btn-background-ghost {
    background: $White !important;
  }
}
.attribute__form {
  min-width: 400px;
  .ant-card-body {
    padding: 8px;
  }
  .ant-select-selector {
    height: 32px;
  }
  .attribute__warn-text {
    color: $Red;
  }
}

.attribute-translation-table {
  height: 150px;
  margin: 4px 0;
  border: 1px solid $AntBorderGrey;
}

.attribute__popover_visible {
  .edit-button {
    display: inline-block !important;
    color: $Blue;
  }
}

.attributes__name-cell {
  padding-right: 3px;
  font-size: 14px;
  height: 100%;
  width: 100%;

  .attributes__name-cell_border {
    height: 100%;
    width: 5px;

    &.green-border {
      border-left: 5px solid $Green;
    }
    &.blue-border {
      border-left: 5px solid $Blue;
    }
  }

  .edit-button {
    display: none;
    cursor: pointer;
  }
  &:hover {
    .edit-button {
      display: inline-block;
      color: $Blue;
    }
  }

  &.attributes__selected-cell {
    color: $White;
  }

  .attributes-star {
    padding: 0 8px;
  }

  .ant-tag {
    margin: 0;
    color: $Blue;
    border-radius: 8px;
    border-color: $Blue;
    line-height: 14px;
    align-content: center;
    padding-left: 4px;
    padding-right: 4px;
  }
}

.attributes-table {
  .attributes-name {
    padding-left: 0 !important;

    .blue-color {
      border: 1px solid $Blue;
      background-color: $Blue;
      height: 40px;
    }
    .green {
      border: 1px solid $Green;
      background-color: $Green;
      height: 40px;
    }
  }
}

.attributes__value-tag {
  &.ant-tag-checkable {
    border: 1px solid $Grey;
    height: 24px;
    line-height: 23px;
    margin-right: 7px;
    margin-bottom: 3px;
    &:hover {
      border-color: $Blue;
    }
    background-color: $White !important;
  }

  &.attributes__checkable-tag_blue {
    &.ant-tag.ant-tag-checkable {
      border-color: $Blue;
      color: $Blue;
      background-color: $LightBlue !important;
    }
  }

  &.attributes__checkable-tag_green {
    &.ant-tag.ant-tag-checkable {
      border-color: $Green;
      color: $Green;
      background-color: $LightGreen !important;
    }
  }
}

.attributes__analysis-message {
  &.blue {
    color: $Blue;
  }
  &.yellow {
    color: $Yellow;
  }
  &.red {
    color: $Red;
  }
}
