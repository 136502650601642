@import 'project_variables.scss';

.filter-drawer {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 120px);
}

.filter-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 10px;
  margin-bottom: 25px;
  overflow: hidden;
  flex: 1;

  .filter__editing-container {
    border-top: 2px solid $Blue;
    margin-top: 0;
  }
}

.filter-bar__container {
  display: flex;
  flex-direction: column;
}

.filter-bar {
  flex: 0 0 100px;
  border: 1px solid $Grey;
}

.filter__add-new-filter {
  border: 1px dashed $Blue;
  color: $Blue;
  font-weight: bold;
  padding: 0px 10px 0 5px;
  line-height: 30px;
  margin-right: 3px;
  height: 30px;

  &:hover {
    background: $LightBlue;
    color: $White;
  }
}

.filter__temp-name-container {
  width: 207px;
  height: 30px;
  margin-right: 3px;
  margin-left: 3px;
  border: 1px solid $Blue;
  display: inline-flex;
  position: relative;
  padding-left: 5px;
  box-sizing: border-box;

  .filter__temp-name {
    border: none;
    flex: 100%;
  }
}

.filter__rename-filter,
.filter__delete-filter,
.filter__dismiss-filter,
.filter__save-filter,
.filter__show-results {
  color: $Blue;
  border: 1px solid $Blue;
  margin-left: 3px;
  cursor: pointer;
  width: 150px;
  height: 30px;
  line-height: 30px;
  font-weight: bold;
  position: relative;
  top: 1px;

  [class^='icon-'] {
    margin-right: 5px;
    font-size: 18px;
    top: 3px;
    position: relative;
  }

  &:hover {
    background: $LightBlue;
    color: $White;
  }
}

.filter__show-results {
  color: $Green;
  border-color: $Green;
  margin-left: auto;
  background: $White;
  top: 0;

  &:hover {
    background: $LightGreen;
    color: $White;
  }
}

.filter-bar__show-results {
  margin-left: auto;
}

.filter__navigation {
  margin-top: 10px;
  position: relative;
  flex: 0 0 43px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .filter__show-results-container {
    flex: 1;
    display: flex;
    justify-content: flex-start;
    padding-left: 40px;
  }

  .filter__option-container {
    display: flex;
    align-items: center;
  }

  .filter__option-container > * {
    margin-left: 10px;
  }

  .submenu-container {
    display: inline-block;

    .submenu {
      margin-left: 20px;
      margin-bottom: 0;
      display: inline-block;
    }
  }

  .dropdown-container {
    border: 1px solid $Grey;
    padding-left: 5px;

    .dropdown__arrow-icon {
      width: 30px;
      height: 28px;
      line-height: 28px;
      text-align: center;
      background: $LightGrey;
    }

    .dropdown__select-container {
      line-height: 28px;
    }
  }

  .filter__tab {
    margin-right: 2px;
    margin-bottom: 3px;
    height: 40px;
    line-height: 40px;
    padding: 0 20px;
    color: $Blue;
    list-style: none;
    background-color: white;
    display: inline-block;
    box-sizing: border-box;
    cursor: pointer;
  }

  .filter__tab.active {
    background-color: $Blue !important;
    color: $White;
    margin-bottom: 0;
    height: 43px;
    border-bottom: none;
  }
}

.filter__navigation li:hover {
  background: $LightBlue;
}

.filter__editing-container {
  flex: 1;
  flex-direction: column;
  margin-top: 22px;
  overflow: auto;
}

.filter__editing {
  overflow: hidden;
  flex: 1;
  position: relative;
  border: 1px solid $Grey;

  .application__details {
    padding: 5px 20px;
    padding-right: 0;
  }

  .item__mapping_area {
    height: 100%;
  }
}

.filter__delete-value {
  display: inline-block;
  cursor: pointer;
  padding-left: 6px;
  font-size: 11px;
  margin-top: 1px;
  vertical-align: text-top;
}

.filter__application {
  height: 100%;

  .application__make-model-year {
    padding-right: 10px;
  }
}

.filter__section-top {
  top: -14px;
  position: absolute;
  background: $White;
  padding: 0 10px;
}

.filter__section-delete {
  padding-left: 3px;
  padding-right: 3px;
  background: none;
  border: none;
  font-size: 11px;
  cursor: pointer;
  color: $IconGrey;
  border: 1px solid $IconGrey;
  background: $White;
  margin-left: 10px;

  .anticon-close {
    font-size: 10px;
    margin-right: 3px;
  }

  &:hover {
    background: $LightGrey;
    color: $Black;
  }
}

.filter__parameter-delete {
  padding: 0;
  border: none;
  font-size: 14px;
  cursor: pointer;
  display: inline-block;
  background: $LightGrey;
  width: 30px;
  border-left: 1px solid $Grey;
  border-bottom: 1px solid $Grey;
  color: $IconGrey;
  line-height: 29px !important;
  margin-left: 3px;
}

.filter__item-count {
  position: absolute;
  right: 10px;
  top: 7px;
  line-height: 15px;
  border: 1px solid $IconGrey;
  color: $IconGrey;
  border-radius: 10px;
  padding: 0 5px;
  font-size: 12px;
  margin-left: 10px;
}

@keyframes donut-spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.filter__item-count_loader {
  margin-left: auto;
  border: 2px solid $Grey;
  border-radius: 50%;
  width: 15px;
  height: 15px;
  border-left-color: $Blue;
  animation: donut-spin 1.2s linear infinite;
}

.filter__analyses {
  height: 100%;
}

.filter-analyses_row {
  display: flex;
  align-items: center;
  padding: 0 10px;
  box-sizing: border-box;

  .filter-analyses_row-name {
    flex: 1;
  }

  &.filter-analyses__row_even {
    background-color: $White !important;
  }
  &.filter-analyses__row_odd {
    background-color: $LightGrey !important;
  }

  &.filter-analyses__row_selected {
    background-color: $Blue !important;
    color: $White;

    .filter__item-count {
      color: $White;
      border: 1px solid $White;
    }

    .filter__item-count_loader {
      border-left-color: $White;
    }
  }

  .anticon-warning {
    font-size: 18px;
    margin-right: 5px;

    &.blue {
      color: $Blue;
    }
    &.yellow {
      color: $Yellow;
    }
    &.red {
      color: $Red;
    }
  }
}

.filter-analyses_row:hover {
  background-color: $LightBlue !important;
}

.filter-analyses__segment-row {
  border-left: 3px solid $Blue;
  color: $Blue;
}

.filter-analyses_left {
  flex: 1;
  padding: 20px;
}

.filter-analyses_middle {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 20px 20px 20px 0;
}

.filter-analyses_right {
  flex: 1;
  padding: 20px 20px 20px 0;
  display: flex;
  flex-direction: column;
}

.filter-analyses__segments-container {
  min-width: 250px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.filter-analyses__segments {
  width: 100%;
  height: 100%;
}

.filter-analyses__scorecards-container {
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;
}

.filter-analyses__scorecards {
  flex: 1;
}

.filter-analyses__alert-types-container {
  margin-bottom: 20px;
}

.filter-analyses__alert-types {
  width: 100%;
}

.filter-analyses__alerts {
  width: 100%;
  height: 100%;
}

.filter__custom {
  height: 100%;
}

.filter-custom-container,
.filter-custom-field-container {
  width: 50%;
  height: 100%;
  display: inline-flex;
  padding: 20px;
  padding-top: 10px;
  flex-flow: column;

  .filter-custom__extended {
    padding-top: 10px;
    flex: auto;
    flex-flow: column;
    overflow: auto;
  }
}

.filter__custom-fields {
  flex: auto;
  padding: 10px;
  margin-bottom: 5px;
  overflow: auto;
}

.filter__custom-item {
  flex: auto;
  padding: 10px;
  margin-bottom: 5px;
}

.filter-custom-dropdown {
  width: 100%;
  font-size: 14px;
}

.filter-custom-dropdown_small {
  font-size: 14px !important;
}

.ant-card.filter__create-custom-card {
  background: $LightBlue;

  &.ant-card {
    .ant-card-body {
      padding: 10px;
    }
  }
}

.filter-custom-label_card {
  &.ant-card {
    .ant-card-body {
      padding-top: 6px !important;
      padding-bottom: 5px !important;
      color: $IconGrey;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

.filter__custom-card:first-of-type {
  margin-top: 0;
}

.filter__custom-card {
  margin-top: 10px;

  &.ant-card {
    .ant-card-body {
      padding: 5px;
    }
  }
}

.ant-tag.filter__custom-add-tag {
  background: $White;
  border-style: dashed;

  .anticon {
    font-style: normal;
    vertical-align: -0.125em;
    color: $IconGrey;
  }

  &.disabled {
    background: $LightGrey;
    pointer-events: initial;
    cursor: not-allowed;
  }
}

.filter__custom-card {
  .ant-tag {
    .anticon {
      vertical-align: -0.125em;
    }
  }
}

.header__filter-section {
  height: 100%;
  flex: auto;
  position: relative;
  display: inline-block;
  padding-left: 10px;
  padding-right: 10px;
  border-right: 1px solid $Grey;
  height: 100px;

  .title {
    line-height: 20px;
  }

  &:last-child {
    border: none;
  }

  &:after,
  &:before {
    content: '';
    position: absolute;
    top: 0;
    height: 100%;
    width: 20px;
    background: red;
  }

  &:before {
    left: 0;
    background: linear-gradient(90deg, $White 0%, transparent 60%);
  }

  &:after {
    right: 0;
    background: linear-gradient(90deg, transparent 0%, $White 60%);
  }
}

.header__filter-parameter {
  height: 100%;
  flex: 0 0 auto;
  padding-left: 10px;
  padding-right: 10px;
}

.header__filter-parameter-name {
  line-height: 30px;
  padding-right: 5px;
}

.header__filter-values {
  display: inline-block;
  max-width: 300px;
  min-height: 35px;
  max-height: 55px;
  overflow: auto;
  box-sizing: border-box;
}

.header__filter-value {
  display: inline-block;
  border: 1px solid $Grey;
  font-size: 14px;
  background: $LightGrey;
  margin-top: 2px;
  margin-right: 5px;
  padding-left: 5px;
  padding-right: 3px;
}

.header__filter-dropdown {
  display: block;
  float: left;
  line-height: 30px;
  height: 30px;
}

.header__filter-dropdown_options {
  display: block;
  border-top: none !important;
  width: 200px;
  position: relative;
  box-sizing: border-box;
  position: absolute;
  z-index: 30;

  .row {
    padding-left: 5px;
    min-height: 0;
    height: 20px;
    line-height: 20px;
  }

  .row:hover {
    background-color: $LightBlue !important ;
  }
}

.header__filter-dropdown_option {
  display: inline-block;
  box-sizing: border-box;
  height: 30px;
  padding-left: 5px;
  padding-right: 10px;
  border-bottom: 1px solid $Grey;
}

.header__dropdown-icon {
  display: inline-block;
  vertical-align: bottom !important;
  background: $LightGrey;
  cursor: pointer;
  width: 30px;
  border-left: 1px solid $Grey;
  border-bottom: 1px solid $Grey;
  box-sizing: border-box;
  color: $IconGrey;
  height: 30px !important;
  padding-top: 7px;
}

.header__filter-block {
  flex-wrap: nowrap;
  height: 85px;
  margin-top: 10px;
  overflow-x: hidden;
  overflow-y: hidden;
}
