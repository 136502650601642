@import 'project_variables.scss';

.welcome-container {
  width: 100%;
  background-color: $LightGrey;
  height: 100%;
  .ant-tabs-tab {
    margin-left: 40px;
  }
  .ant-tabs-nav-wrap {
    border-bottom: 1px solid $DividerGrey;
  }
}

.header-details {
  width: 100%;
}

.welcome__quick-start-guide {
  width: 720px;
  .ant-steps-item-container {
    &:hover {
      background-color: $AntHoverBlue;

      .ant-steps-item-icon {
        border-color: transparent !important;
      }
    }
  }
  .ant-steps-item-active {
    .ant-steps-item-container {
      background-color: transparent !important;
    }
  }
  .ant-progress-inner {
    border-radius: 0;
  }
  .ant-steps-item-tail {
    visibility: hidden;
  }
  .ant-steps-item-finish .ant-steps-item-icon {
    background-color: $LightGrey;
    border-color: transparent;
  }
  .ant-steps-item {
    padding: 24px 0;
  }
  .ant-steps-item-icon {
    margin-top: 12px;
    margin-right: 20px !important;
    margin-left: 24px;
  }
  .skip-step {
    padding-top: 0 !important;
    color: $Blue;
  }
  .knowledge-base-link {
    padding: 0;
    margin-bottom: 16px;
  }
  .start-tour-button {
    padding: 0;
    text-align: left !important;
    margin-bottom: 16px;
  }

  .start-guide-content_navigate-button {
    margin-top: 16px;
    width: fit-content;
  }
  .start-guide-content_upgrade-plan {
    margin-top: 16px;
    margin-left: 4px;
    width: fit-content;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
  }
}

.welcome__skip-guide_warn-icon {
  color: $Yellow !important;
  padding-right: 8px;
  font-size: 20px;
}

.welcome__skip-guide_title {
  margin: 0;
  font-size: 20px;
  font-weight: 500;
}

.welcome__skip-guide_description {
  font-size: 16px;
}

.welcome__skip-guide_skip-button {
  background-color: $Yellow !important;
  border-color: $Yellow !important;
}

.welcome-message__card {
  width: 652px;
  margin-left: 20px !important;
  margin-bottom: 40px !important;
}

.welcome__steps-header-title {
  font-size: 20px;
  font-weight: 500;
  line-height: 28px;
  margin: 0;
  color: $FontColorDark !important;
}

.welcome__step-sub-title {
  line-height: 20px;
  size: 14px;
  font-weight: 400;
  color: $FontColorDark !important;
}

.welcome__start-guide__paid-tag.ant-tag {
  height: max-content;
  border-radius: 16px;
  line-height: 16px;
  margin-left: 8px;
}
