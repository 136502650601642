@import 'project_variables.scss';

.eba__group-seperator {
  .ant-form-item {
    margin-bottom: 0px;
    .ant-form-explain {
      display: none;
    }
  }

  .ant-form-item-control {
    .ant-form-item-control-input {
      min-height: 24px;
    }
  }
}

.eba__identifier {
  &.ant-form-item {
    padding-right: 20px;
  }
}

.ebp__brand-select {
  min-width: 200px;
  max-width: 300px;
  margin-right: 7px !important;
  flex: auto;
}
