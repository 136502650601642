@import 'project_variables';

.interchange__page__table {
  .ant-table-thead {
    background-color: $AntTableHeaderGrey;
  }
  .ant-table-small .ant-table-content .ant-table-header {
    background-color: $AntTableHeaderGrey;
  }
}

.interchangePage__edit__button {
  &.ant-btn-sm {
    height: 21px;
  }
}

.interchange__page__table .ant-table-fixed-left .ant-table-thead > tr > th:last-child {
  border-right-width: 1px;
  border-right-style: solid;
  border-right-color: $AntTableBorderGrey;
}
.interchange__page__table .ant-table-thead > tr:not(:last-child) > th[colspan] {
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: $AntTableBorderGrey;
}

.interchange__edit-form {
  .ant-form-item {
    margin-bottom: 16px;
  }
  .ant-list-bordered.ant-list-item {
    padding-right: 1px;
    padding-left: 10px;
  }
  .ant-form-item-required {
    display: flex;
  }
}

.interchange_input_quantity {
  .ant-input {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
}

.interchange_select_uom {
  .ant-select-selection {
    background-color: $LightGrey;
    border-left: 0;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}

.interchange {
  height: 100%;
  width: 100%;
}

.interchange-item__value_missing {
  border: 2px solid $LightRed !important;
}

.interchange__edit-form {
  .interchange__form-no-spacing.ant__divider {
    margin-top: 0;
  }
  .interchange__form-big-spacing.ant__divider {
    margin-top: 40px;
  }
}

.interchange__analyses-alert-icon {
  padding-right: 0 !important;
}
