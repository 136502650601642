@import 'project_variables.scss';

// light is our sub navigation and needs space
.ant-layout-sider-light {
  margin-right: 5px;
  box-shadow: $LightBoxShadow;
}

.ant-menu-inline .ant-menu-item {
  margin-top: 0 !important;
}

.ant-btn-green.ant-btn {
  background-color: $Green;
  color: $White;
  border-color: $Green;

  &:hover {
    background-color: #73d13d;
    color: $White;
    border-color: #73d13d;
  }
  &:focus {
    background-color: #73d13d;
    color: $White;
    border-color: #73d13d;
  }
}

.ant-drawer-close-button {
  width: 18px !important;
  height: 18px !important;
  padding: 0 !important;
  color: $FontColor !important;

  &:hover {
    color: $FontColorDark !important;
  }
}

.ant-drawer-header-title {
  flex-direction: row-reverse;

  .ant-drawer-close {
    margin-right: 0;
  }
}

.select-blue-tags {
  &.ant-select-multiple .ant-select-selection-item {
    color: #1890ff;
    background: #e6f7ff;
    border-color: #91d5ff;
  }
}

.select-blue-tags.ant-select-disabled {
  &.ant-select-multiple .ant-select-selection-item {
    border-color: $AntBorderGrey;
    color: $AntIconGrey;
    background: $AntBackgroundGrey;
  }
}

.disabledTableRow {
  color: $Grey;
}

.ant-drawer-submit-bottom {
  position: absolute;
  left: 0px;
  bottom: 0px;
  width: 100%;
  border-top: 1px solid $AntBorderGrey;
  padding: 10px 16px;
  background: #fff;
  text-align: right;
}

.ant-tabs-content {
  height: 100%;
}

.ant-white-batch {
  .ant-badge-count {
    background-color: $White;
    color: $IconGrey;
    box-shadow: 0 0 0 1px $AntBorderGrey inset;
  }
}

.ant-blue-batch {
  .ant-badge-count {
    background-color: $White;
    color: $Blue;
    box-shadow: 0 0 0 1px $Blue inset;
  }
}

.anticon.ant-info-tooltip {
  color: $Blue;
}

.ant-text-black {
  color: $FontColorDark;
}

.drag-sorting-table tr.drop-over-downward td {
  border-bottom: 1px dashed #1890ff;
}
.drag-sorting-table tr.drop-over-upward td {
  border-top: 1px dashed #1890ff;
}

::selection {
  color: initial !important;
}
