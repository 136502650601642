@import 'project_variables.scss';

.inbox__editing {
  display: flex;
  flex-direction: column;
  position: relative;
  flex: 1;
  overflow: auto;
  height: 100%;
}

.quickfix__engine-tooltip {
  column-count: 2;
  column-gap: 10px;
  margin-top: 10px;

  // Masonry bricks or child elements
  .quickfix__engine-block {
    display: inline-block;
    margin-bottom: 16px;
    width: 100%;

    table {
      border-spacing: 0;
    }
  }
}

.quickfix__tooltip-wrapper.ant-tooltip {
  max-width: 400px;

  .ant-descriptions-item {
    padding-bottom: 0 !important;
  }

  .ant-descriptions-title,
  .ant-descriptions-item-label,
  .ant-descriptions-item-content {
    color: $White;
  }
  .font-bold {
    .ant-descriptions-item-label {
      font-weight: 800;
    }
  }
}

.quickfix__transmission-tooltip {
  .quickfix__transmission-block {
    margin-bottom: 16px;
  }
}
