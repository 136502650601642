.forms-checkbox-group {
  border-bottom: 1px solid #e9e9e9;
  margin-bottom: 5px;
  padding-bottom: 5px;
}

.form-alert.ant-alert {
  margin-bottom: 10px;
}

.button-group {
  display: flex;
  flexflow: row nowrap;
  justify-content: flex-end;
  &__submit {
    margin-left: 10px;
  }
}

.form-number-input {
  &.ant-input {
    padding-right: 0;
  }
  &.ant-input-affix-wrapper {
    padding-right: 0;
  }
}
