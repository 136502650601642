.export-pdf {
  display: flex;
  flex-direction: column;

  &__select {
    width: 200px;
  }

  &__template-select.ant-select {
    margin-bottom: 10px;
  }
}
