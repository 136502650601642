.account {
  padding: 40px;
}

.ant-form.account-form {
  max-width: 550px;
  margin-bottom: 20px;
}

.account-form {
  .account-auto-generate-email {
    margin-top: 30px;
  }

  .account-email__auto-name {
    flex: 1;
    line-height: 35px;
  }
}
