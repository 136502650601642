@import 'project_variables.scss';

.item-dashboard {
  width: 100%;
}

.distribution-overview__search {
  margin-bottom: 10px !important;
  max-width: 260px;
}

.distribution-overview__table {
  min-width: 550px;
}

.distribution-overview__log-table {
  margin-bottom: 20px;
}
