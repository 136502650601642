@import 'project_variables.scss';

.search-box-list {
  height: 480px;
  width: 320px;

  .ant-input-search input {
    border: 1px solid $White;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    border-bottom: 1px solid $AntBorderGrey;

    &:focus {
      box-shadow: none;
    }
  }
}
