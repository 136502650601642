@import 'project_variables.scss';

.ebay__container {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.ebay__no-connection.ant-alert {
  width: auto;
  height: fit-content;
  margin: 20px;
}

.ebay__status-row {
  display: flex;
  width: 100%;
  justify-content: flex-end;

  .ebay__status-listed {
    margin-right: 5px;
  }

  .ebay__end-button {
    margin-right: 5px;
    margin-left: 0 !important;
  }
}

.ebay__item-settings {
  display: flex;
  flex: 1;
  flex-direction: column;
}

.ebay__item-form {
  .ant-form-item {
    margin-bottom: 12px;
  }

  label {
    width: 100%;
  }

  .ebay__button-container {
    border-top: 1px solid $AntBorderGrey;
    height: 50px;
    padding-top: 10px;
    padding-right: 10px;
  }
}

.ebay__form-headline {
  margin-top: 12px;
  margin-bottom: 12px;
  font-size: 16px;
  color: black;

  .ebay__form-headline-default {
    display: flex;
  }

  .ebay__default-switch-tag {
    font-size: 14px;
  }

  .ebay__default-switch-container {
    display: inline-flex;
  }
}

.ebay__form-label {
  display: inline-flex;
  width: 100%;
}

.ebay__default-switch-container {
  display: flex;
  justify-content: flex-end;
  flex: 1 1;

  .ebay__default-switch-tag {
    padding-right: 7px;
  }

  .ant-switch {
    margin-top: 2px;
  }
}

.ebay__epid-list {
  height: 400px;
  border: 1px solid $AntBorderGrey;
  border-radius: 4px;
  margin-top: 20px;
  margin-bottom: 20px;

  .ReactVirtualized__Table__headerRow.ebay__epid-list-row {
    background: $LightGrey;
  }

  .ebay__epid-list-header {
    text-transform: none;
  }

  .ebay__epid-list-row {
    border-bottom: 1px solid $AntBorderGrey;
  }
}
