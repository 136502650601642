@import 'project_variables.scss';

.application_listing__wrapper {
  flex: 1;
}

.application_listing__validation-selects {
  .application_listing__type-select {
    width: 180px;
  }
  .application_listing__option-select {
    width: 190px;
    margin-left: 5px;
  }

  .application_listing__validate-button {
    margin-left: 5px;

    &.ant-btn-background-ghost {
      background: $White !important;
    }
  }
}

.application_listing__filter-elements {
  display: flex;
  margin-left: 20px;

  .application_listing__search-input {
    width: 200px;

    .help__icon {
      color: $IconGrey;
    }
  }

  button {
    margin-left: 5px;
  }

  .catalogue-filter-tags .tag {
    margin: 1px;
    margin-left: 3px;
  }
}

.application_listing__table {
  box-shadow: $LightBoxShadow;

  .ReactVirtualized__Grid__innerScrollContainer {
    box-shadow: $LightBoxShadow;
  }

  .BaseTable__header-row--customized:first-of-type {
    border-bottom: none;
  }

  .BaseTable__row--hovered {
    background: $AntHoverBlue;
  }

  .application_listing__header-cell {
    height: 100%;

    .BaseTable__header-cell {
      border-right: 1px solid $AntTableBorderGrey;
      padding-left: 7px;
      padding-right: 7px;
    }
  }

  .application_listing__header-group-cell {
    .BaseTable__header-cell {
      border-right: none;
    }
  }

  .application_listing__empty-group-cell {
    height: 100%;
    border-right: 1px solid $AntTableBorderGrey;
  }

  .application_listing__group-header-cell {
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid $AntTableBorderGrey;
  }

  .application_listing__first-duplicate {
    border-top: 1px solid $Yellow;
  }

  .application_listing__duplicate-left {
    border-left: 2px solid $Yellow;
  }

  .application_listing__duplicate-right {
    border-right: 2px solid $Yellow;
  }

  .application_listing__last-duplicate {
    border-bottom: 1px solid $Yellow;
  }

  .application_listing__border-top-red {
    border-top: 1px solid $Red;
  }

  .application_listing__border-left-red {
    border-left: 1px solid $Red;
    border-bottom: 1px solid $Red;
  }

  .application_listing__border-right-red {
    border-right: 1px solid $Red;
    border-bottom: 1px solid $Red;
  }

  .application_listing__warning-icon {
    color: $Red;
  }

  .application_listing__edit-icon {
    color: $AntIconGrey;
  }

  .application_listing__overlap-cell {
    color: $Yellow;

    .application_listing__warning-icon {
      color: $Yellow;
    }
  }

  .application_listing__add-icon {
    cursor: pointer;
    color: $IconGrey;
    font-size: 15px;

    &.gray {
      color: $AntIconGrey;
    }
  }

  .application_listing__header-checkbox {
    padding-left: 15px;
    width: 60px;
    height: 100%;
    align-items: center;
    display: flex;
  }
}

.application_listing__popover {
  .ant-btn-background-ghost {
    background: $White !important;
  }
}

.application_listing__empty-header-cell {
  height: 0;
}

.application_listing__explanation-title.ant-typography {
  color: $Blue !important;
  margin-top: 30px !important;
}

.application_listing__filter-drawer-bottom {
  position: absolute;
  left: 0;
  width: 100%;
  border-top: 1px solid $MediumGrey;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 10px;
  background: $LightGrey;

  button {
    margin-left: 10px;
  }
}

.application_listing__error {
  &.ant-alert {
    margin: 20px;
  }
}
