@import 'project_variables.scss';

.manage-groups-action {
  margin-right: 20px;
}

.marketing-copy-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
  grid-template-rows: auto;
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  margin: 20px;
  align-items: start;
}

.marketing-copy-preview {
  display: flex;
  flex-direction: column;
  height: 100%;

  .ant-card-body {
    flex: 1;
    display: flex;
    flex-direction: column;

    .descriptions-list {
      flex: 1;
    }
  }

  .marketing-copy-image {
    width: 50px;
    height: 50px;
    margin-right: 10px;
    object-fit: contain;
  }

  &__title {
    color: $Blue;
  }
}

.mc-drawer {
  &__part-type {
    margin-right: 15px;
  }
}

.group-drawer {
  display: flex;
  flex-direction: column;

  .group-drawer-list {
    margin-top: 20px;
    flex: 1;
    height: 100%;
    border-left: 1px solid $AntBorderGrey;
    border-right: 1px solid $AntBorderGrey;
    border-top: 1px solid $AntBorderGrey;
    border-radius: 4px;

    &__group {
      border-bottom: 1px solid $AntBorderGrey;
      :last-child {
        border: none;
      }
    }

    &__sub-group {
      border-bottom: 1px solid $AntBorderGrey;
      background-color: $LightGrey;
    }

    &__item {
      padding: 8px;

      &-nested {
        padding: 8px 8px 8px 16px;
      }
    }

    &-edit-icon {
      font-size: 18px;
      margin-left: 8px;
    }
  }
}
