// colors
$Black: #131313;
$White: #ffffff;

$FontColor: rgba(0, 0, 0, 0.65);
$FontColorDark: rgba(0, 0, 0, 0.85);
$FontColorWhite: rgba(255, 255, 255, 0.65);

$DividerGrey: #e0e0e0;
$IconGrey: #8c8c8c;
$Grey: #bfbfbf;
$MediumGrey: #e8e8e8;
$LightGrey: #f5f5f5;
$BorderGrey: rgba(193, 193, 193, 0.2);
$TableBorderGrey: rgba(
  $color: $Grey,
  $alpha: 0.2,
);
$TextGrey: #4a5568;

$AntBorderGrey: #d9d9d9;
$AntTableHeaderGrey: #fafafa;
$AntTableBorderGrey: #e8e8e8;
$AntHoverBlue: #e6f7ff;
$AntBackgroundGrey: #f0f2f5;
$AntIconGrey: rgba(0, 0, 0, 0.25);
$AntDropdownShadow: 0 2px 8px rgba(0, 0, 0, 0.15);

$InputFocusBlue: #36a0b3;
$LightBlueShadow: 0 0 0 2px rgba(24, 140, 166, 0.2);

$LightBoxShadow: 0 1px 3px hsla(0, 0%, 0%, 0.2);

$BlackTransparent: rgba(4, 0, 0, 0.11);

$Blue: #188ca6;
$DarkBlue: #106679;
$LightBlue: #b0dde8;

$Green: #52c41a;
$LightGreen: #b7eb8f;

$Red: #f5222d;
$LightRed: #ffa39e;
$BgRed: #feb2b2;

$Yellow: #fa8c16;
$LightYellow: #ffd591;
$BgYellow: #fff7e6;

$SunriseYellow: #fadb14;

$GithubWhite: #f8f8f8;

//animation times
$slow: 1s;
$middle: 0.3s;
$fast: 0.1s;
