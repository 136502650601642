@import 'project_variables.scss';

.dropdown-container {
  width: 200px;
  line-height: 20px;
}

.dropdown-container,
.dropdown__select-container {
  background: transparent;
  position: relative;
  display: inline-block;

  &:focus {
    outline: none;
  }
}

.dropdown__select-container {
  z-index: 4;
  width: 100%;

  &.dropdown__select-container_open {
    z-index: 999;
    position: absolute;
    box-shadow: -6px 6px 4px 0px rgba(0, 0, 0, 0.1);
  }

  &.dropdown__select-container_reverse {
    bottom: 0;
  }
}

.dropdown__select,
.dropdown__preview {
  width: inherit;
  height: 20px;
  line-height: 20px;
  border: none;
  border-radius: 0;
  background: transparent;
  color: $Black;
  appearance: none;
  padding-right: 15px;
}

.dropdown__row {
  line-height: 20px;
  padding-left: 10px;
  background: $White !important;

  &:hover {
    background-color: $LightBlue !important;
  }
}

.dropdown__row_selected {
  background-color: $Blue !important;

  .dropdown__row-text {
    color: $White;
  }
}

.dropdown__arrow-icon {
  position: absolute;
  top: 4px;
  right: 2px;
  z-index: -1;
  line-height: 20px;
  font-size: 12px;
}

.dropdown__table-container {
  height: auto;
  max-height: 250px;
  width: inherit;
  box-sizing: border-box;
  border: 1px solid $Grey;
  flex-direction: column;
}

.dropdown__search-input {
  flex: 0 0 20px;
  height: 20px;
  border: none;
  border-bottom: 1px solid $Grey;
}

.dropdown__table {
  z-index: 999;
  border: none;
}
