@import 'project_variables.scss';

.icon__knowledge-base {
  width: 14px;
  height: 16px;
}

.icon__critical {
  width: 18px;
  height: 18px;
}

.icon__red {
  color: $Red !important;
}

.icon__yellow {
  color: $Yellow !important;
}

.icon__blue {
  color: $Blue !important;
}

.icon__green {
  color: $Green !important;
}
