@import 'project_variables.scss';

.catalogue-bar-filter-id {
  border: 1px solid $AntBorderGrey;
  padding: 0 4px;
  line-height: 22px;
}

.catalogue-list__multi-edit {
  .ant-tag {
    background-color: $BgRed;
    color: $TextGrey;
    border-color: $Red;
    padding: 1px 7px;
  }
  .anticon svg {
    color: $Red;
    margin-right: 7px;
  }
}
.catalogue-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  z-index: 10;
  transition: max-height 0.3s ease-out;
}

.catalogue-menu-list-container {
  display: flex;
  height: calc(100% - 45px);
}

.catalogue-left-menu {
  overflow: hidden;
  flex: 0 0 200px;
  display: flex;
  flex-direction: column;
  box-shadow: $LightBoxShadow;
  background-color: $White;
}

.catalogue-menu-container {
  overflow-y: auto;
  margin-bottom: 40px;

  .ant-menu .ant-menu-item {
    padding-right: 0px;
  }
}

.catalogue-search__helper {
  color: $Grey !important;
}

.catalogue-list-container,
.catalogue-list-container-placeholder {
  flex: 1;
  margin-left: 5px;
  background: $White;
  display: flex;
  flex-direction: column;
  padding-bottom: 1px;
  box-shadow: $LightBoxShadow;

  &.full {
    padding-bottom: 42px;
  }
}

.catalogue-list-bar {
  display: grid;
  grid-template-columns: max-content 8fr repeat(2, 160px) 1fr 40px;
  grid-column-gap: 10px;
  grid-template-rows: 20px;
  padding: 10px;
  border-bottom: 1px solid $LightGrey;
  width: 100%;
  align-items: center;

  &__select {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    > *:not(:first-child) {
      margin-left: 10px;
    }
  }

  &__actions {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    > *:not(:first-child) {
      margin-left: 10px;
    }
    > span {
      white-space: nowrap;
    }
  }

  &__filter-select {
    width: 100%;
    &.custom-filter {
      .ant-select-selection.ant-select-selection--single {
        background-color: #e6f7ff;
        border-color: #91d5ff;
        color: #1890ff;
      }
    }
  }

  &__filter-temp {
    font-style: italic;
  }

  &__selected-count {
    font-size: 13px;
  }

  &__items-count {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: flex-end;
    white-space: nowrap;
    > *:not(:first-child) {
      margin-left: 10px;
    }
  }

  .catalogue-button {
    .anticon {
      margin-left: 0;
    }
  }

  .ant-cascader {
    width: 160px;
  }
}

.catalogue-filter-tags {
  display: flex;
  overflow: auto;
  overflow: -moz-scrollbars-none;
  max-height: 35px;
  &::-webkit-scrollbar {
    display: none;
  }

  .tag {
    margin: 3px;
  }
}

.catalogue-list {
  height: 100%;

  .items-virtualized-list {
    background: $White;
  }

  .spinner {
    position: relative;
    bottom: 50px;
    transform: translateX(-50%);
  }
}

.catalogue-list-row {
  display: grid;
  grid-template-rows: 40px;
  grid-template-columns: max-content auto;
  padding: 0 10px;
  grid-column-gap: 10px;
  border-bottom: 1px solid $LightGrey;

  align-items: center;
  cursor: pointer;
  transition: box-shadow $fast;
  user-select: none;

  &:hover {
    box-shadow: 0 0 11px rgba(33, 33, 33, 0.2);
  }

  &__is-selected {
    background-color: $LightBlue;
    color: $FontColor;
  }

  &__select {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    > *:not(:first-child) {
      margin-left: 10px;
    }
  }

  &__inner-select {
    display: grid;
    align-items: center;
    grid-template-columns: max-content 1.25fr 5fr 2fr 2fr max-content;
    grid-template-rows: 40px;
    grid-column-gap: 10px;
    cursor: pointer;
  }

  &__inner-receiver-select {
    grid-template-columns: max-content 1fr 5fr 3fr 2fr 1fr;
  }

  &__data {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    white-space: nowrap;
    justify-content: flex-start;
    > *:not(:first-child) {
      margin-left: 10px;
    }
  }

  &__icon {
    width: 28px;
    font-size: 16px;
  }

  &__thumbnail {
    width: 28px;
    height: 28px;
    object-fit: contain;
  }

  &__part-number {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  &__short-name {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  &__short-name-extra {
    font-style: italic;
    color: $Grey;
  }

  &__no-part-type {
    font-style: italic;
    color: $Grey;
  }

  &__tags {
    overflow-x: hidden;
    display: flex;
    justify-content: flex-end;
    max-height: 25px;

    .ant-tag {
      margin-bottom: 2px;
    }
  }

  &__tag-wrapper {
    overflow-x: hidden;
    text-align: right;
  }

  &__tag-tooltip {
    .ant-tag {
      margin-bottom: 5px;
    }
  }

  &__submitted-status {
    margin-right: 6px;

    &-icon {
      margin-right: 3px;
    }
  }

  &__progress {
    width: 27px;
    margin-left: 10px !important;
    .ant-progress-circle-path {
      stroke: $Green !important;
    }
    .ant-progress-text {
      font-size: 8px;
      font-weight: 600;
    }
    &.red {
      .ant-progress-circle-path {
        stroke: $Red !important;
      }
    }
    &.yellow {
      .ant-progress-circle-path {
        stroke: $Yellow !important;
      }
    }
    &.blue {
      .ant-progress-circle-path {
        stroke: $Blue !important;
      }
    }
  }

  .sync-icon {
    &.yellow {
      color: $Yellow;
    }
    &.green {
      color: $Green;
    }
    &.grey {
      color: $Grey;
    }
  }
}

.catalogue-list-container {
  .star {
    cursor: pointer;
    color: rgba($color: $Grey, $alpha: 0.65);
    outline: none;
    &.marked {
      color: $SunriseYellow;
    }
    &:hover {
      color: $SunriseYellow;
    }
  }
}

.catalogoue-extended-bottom {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  border-top: 1px solid $MediumGrey;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 10px;
  background: $LightGrey;
  z-index: 2;
}

.filter-custom-menu {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-style: italic;

  &__sub {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__name {
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.icon-delete-filter {
  &:hover {
    color: $Red !important;
  }
}

.catalogue-add-drawer__form {
  display: flex;
  flex-direction: column;
}

.catalogue-add-tag {
  width: 200px;
  display: flex;
  align-items: center;
}

.catalogue-bar__tags-select {
  min-width: 150px;
  display: flex;
  margin-top: 3px;

  &.ant-select-multiple {
    .ant-select-selector {
      max-height: 30px;
      overflow-y: auto;
      overflow-x: hidden;
    }
  }
}

.catalogue-bar {
  background: $LightGrey;
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  padding: 5px;
  height: 40px;
  box-shadow: $LightBoxShadow;

  .add-button-container {
    flex: 0 0 190px;
    padding-left: 5px;

    .add-button {
      width: 100%;
    }
  }

  .catalogue-filter-container {
    display: grid;
    grid-template-columns: 200px fit-content(220px) auto 1fr;
    grid-column-gap: 0.5rem;
    width: 100%;
    align-items: center;

    .search {
      flex: 0 0 200px;
      margin: 0 auto 0 10px;
    }

    .filter-action-button-container {
      display: flex;
      align-items: center;

      .button-add-filter {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 0.5rem;
      }
      .button-save-filter {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
}
.ant-form.copy-drawer-form {
  display: flex;
  flex-flow: column nowrap;
  margin-top: 10px;
}
.copy-drawer-form__submit {
  max-width: 100%;
  margin-top: 15px;
}

.save-filter-popover {
  min-width: 300px;
  &__form {
    display: flex;
    flex-flow: column nowrap;
    &__submit {
      align-self: flex-end;
    }
  }

  .ant-popover-inner-content {
    width: 100%;
  }
}

.ant-modal-body {
  .export-modal__form-item {
    margin-bottom: 12px;
  }
  .ant-form-item-label {
    line-height: 16px;
  }
}

.catalogue-bar__sort {
  .ant-cascader-menu {
    height: auto;
    min-width: 155px;
  }
}

.no-products {
  padding: 10px;
  &__title {
    margin-top: 0;
    margin-bottom: 5px;
    color: $Blue;
  }

  &__button {
    margin-top: 20px;
  }
}
