@import 'project_variables.scss';

.item-analysis {
  display: flex;
  margin-left: 20px;
  margin-right: 10px;
  background: $White;
  overflow: auto;
  overflow: -moz-scrollbars-none;
  &::-webkit-scrollbar {
    display: none;
  }
}

.item-analysis-container {
  transition: 0.3s;
  font-size: 0.875rem;
  display: flex;
  cursor: default;
  user-select: none;
  height: 22px;
  overflow: hidden;
}

.item-analysis__message-container {
  margin-right: 10px;
  padding: 4px;
  flex: 0 0 auto;
  display: flex;
  align-items: center;

  &.blue {
    border: 1px solid $Blue;
    color: $Blue;
    background: $LightBlue;
  }
  &.yellow {
    border: 1px solid $Yellow;
    color: $Yellow;
    background: $LightYellow;
  }
  &.red {
    border: 1px solid $Red;
    color: $Red;
    background: $LightRed;
  }
}

.item-analysis__message {
  padding-right: 3px;
}
.item-analysis__info {
  padding-left: 2px;
}
.item-analysis__delete {
  font-size: 10px;
  padding-left: 7px;
  cursor: pointer;

  &:hover {
    color: $White;
  }
}
