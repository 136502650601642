@import 'project_variables.scss';

.retool {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.retool__app-input {
  width: 310px !important;
  margin-right: 5px !important;
}
