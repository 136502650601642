.input-with-preview {
  width: 100%;
  padding: 5px 12px;
  min-height: 32px;
  cursor: text;
  font-variant: tabular-nums;
  display: flex;
  align-items: center;
  &:hover {
    border: 1px solid $AntBorderGrey;
    border-radius: 4px;
    padding: 4px 11px;
  }

  &__suffix {
    color: $IconGrey;
  }

  &__error {
    border-color: $Red !important;
  }
}

.select-with-preview {
  width: 100%;
  padding: 5px 12px;
  min-height: 32px;
  cursor: pointer;
  font-variant: tabular-nums;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .icon-down {
    font-size: 12px;
    display: none;
    color: $AntIconGrey;
  }

  .icon-close-circle {
    font-size: 12px;
    display: none;
    color: $AntIconGrey;
  }

  &:hover {
    border: 1px solid $InputFocusBlue !important;
    border-radius: 4px;
    padding: 4px 11px;

    .icon-down {
      display: none !important;
    }

    .icon-close-circle {
      display: block;

      &:hover {
        color: rgba(0, 0, 0, 0.4);
      }
    }
  }
}
