@import 'project_variables.scss';

.home-container {
  width: 100%;
  height: 100%;
  overflow: auto;
  flex-wrap: wrap;
  align-items: flex-start;
  box-sizing: border-box;
  position: relative;
  background-color: $LightGrey;
  padding: 0 20px;
}

.home-container__init {
  position: absolute;
  top: 50%;
  left: 25%;
  font-size: 2.5rem;
  transform: rotate(-45deg);
  margin: auto;
}

.home-container__analyse-title {
  margin: 0;
}

.home-container__left {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.home-container__right {
  display: flex;
  flex-direction: column;
  flex: 1 1 400px;
  overflow-x: auto;
  max-width: 1080px;

  @media (min-width: 1743px) {
    height: 100%;
  }

  @media (max-width: 1743px) {
    padding-top: 20px;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
  }
}

.analyse__border {
  box-shadow: $LightBoxShadow;
  padding: 14px;
  margin: 14px;
  background: $White;
  border-radius: 6px;
}

.analyse-data-quality {
  display: flex;
}

.analyse-quality-history {
  display: flex;
  flex-direction: column;
  max-width: 960px;
  margin-right: 10px;
}

.analyse-quality-history__top {
  justify-content: space-between;
}

.analyse-quality-segment {
  display: flex;
  flex-direction: column;
  position: relative;
  line-height: initial;

  .analyse-icons__warning {
    position: absolute;
    padding: 0 1px;
    color: $Yellow;
    border: 1px solid $Yellow;
    border-radius: 10px;
    font-size: 12px;
    width: 50px;
    text-align: center;
    cursor: pointer;
  }

  .analyse-icons__error {
    position: absolute;
    padding: 0 1px;
    color: $Red;
    border: 1px solid $Red;
    border-radius: 10px;
    font-size: 12px;
    width: 50px;
    text-align: center;
    cursor: pointer;
  }

  .analyse-icons__info {
    position: absolute;
    padding: 0 1px;
    color: $Blue;
    border: 1px solid $Blue;
    border-radius: 10px;
    font-size: 12px;
    width: 50px;
    text-align: center;
    cursor: pointer;
  }

  .anticon-warning {
    padding-right: 1px;
  }
}

.analyse-recommendation {
  display: flex;
  flex-direction: column;
  @media (max-width: 1743px) {
    width: 48%;
  }
}

.analyse-recommendation__graphs {
  flex: 1 0;
  justify-content: space-around;
}

.analyse-recommendation__error {
  cursor: pointer;
}

.analyse-recommendation__single-graph {
  margin-bottom: 5px;
  position: relative;
  text-align: center;

  &-title {
    font-size: 14px;
    margin-top: 14px;
    margin-bottom: 5px;
    text-align: center;
  }

  &-wrapper {
    max-height: 100px;
    max-width: 100px;
    margin: 0 auto;
  }

  &-count-info {
    font-size: 14px;
    position: absolute;
    left: 0;
    right: 0;
    text-align: center;
    color: $Blue;
    top: 57.5%;
  }

  &-text-info {
    font-size: 14px;
    margin: 0;
    text-align: center;
    color: $Blue;
    top: -15px;
  }
}

.analyse-recommendation-items {
  max-height: 320px;
}

.analyse-latest-product-changes__table {
  flex: 1;
  overflow: auto;
  border-top: 1px solid $MediumGrey;
  border-bottom: 1px solid $MediumGrey;

  .row {
    border-bottom: 1px solid $MediumGrey;
    padding: 0 8px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  @media (max-width: 1743px) {
    max-height: 250px;
  }
}

.analyse-latest-changes {
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: auto;

  @media (max-width: 1743px) {
    width: 48%;
    margin-left: 20px;
  }
}

.analyses-not-existing {
  color: $Grey;
  font-size: 0.8em;
}

.analyse-change-brand {
  display: flex;
  align-items: center;

  &__title {
    padding-right: 0.625rem;
  }

  &__dropown-container {
    display: flex;
    align-items: center;
    border: 1px solid $Grey;
    padding: 0 3px;
  }
}

.analyse-quality-history__interval-dropdown-container {
  display: flex;
  align-items: center;
  border: 1px solid $MediumGrey;
  border-radius: 6px;
  padding: 0 3px;
  margin-bottom: 7px;
}
