@import 'project_variables.scss';

.review__row:nth-of-type(even) {
  background-color: $White;
}

.review__row:nth-of-type(odd) {
  background-color: $LightGrey;
}

.review__flyout {
  box-sizing: border-box;
  display: flex;
  position: fixed;
  font-size: 16px;
  right: 70px;
  background: rgb(255, 255, 255);
  width: 550px;
  height: 40vh;
  z-index: 20;
  box-shadow: 3px 0px 10px 3px rgba(0, 0, 0, 0.1);
  padding: 10px 10px 10px 5px;
  overflow: scroll;
}

.review__close {
  box-sizing: border-box;
  color: $IconGrey;
  height: 100%;
  cursor: pointer;
  width: 20px;
}

.review__close-text {
  box-sizing: border-box;
  color: $Blue;
  transform: rotate(90deg);
  transform-origin: left bottom;
}

.review__editing {
  flex: 1;
}
