.antdivider {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;

  &__line {
    flex: 1;
    display: flex;
    align-items: center;
  }
  &__toggle {
    margin-left: 1em;
  }
}

.ant__divider {
  &.ant-divider-horizontal.ant-divider-with-text {
    border-color: $AntBorderGrey;
  }

  &.ant-divider-horizontal.ant-divider-with-text-left::before {
    width: 0;
  }
  &.ant-divider-horizontal.ant-divider-with-text-left {
    text-align: left;

    .ant-divider-inner-text {
      padding-left: 0;
    }
  }
}
