.price-sheet__container {
  width: 100%;
  max-width: 560px;
  display: grid;
  grid-template-columns: 1fr 1fr 20px;
  grid-column-gap: 5px;
  grid-row-gap: 5px;
  &__header__checkbox {
    grid-area: 1 / 2 / span 1 / span 2;
  }
  &__inner {
    &__help {
      align-self: center;
    }
  }
}
