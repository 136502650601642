@import 'project_variables';

.mc-drawer__products-alert {
  .ant-alert {
    line-height: 0.5;
    font-size: 11px;
  }
  .ant-alert-icon {
    top: 5.8px;
  }
  .ant-alert-message {
    color: $Blue;
  }
}

.description__group_treeselect {
  .ant-select {
    width: 100%;
  }
}

.description__content-editor__small {
  .text-editor-container {
    min-height: 50px;
  }
}

.description__display__cards {
  .ant-card-small {
    .ant-card-body {
      padding: 8px;
    }
  }
}

.description__card-content {
  overflow-wrap: anywhere;
}

.description__divider {
  .ant-divider-horizontal {
    margin: 2px 0px;
  }
}

.description-drawer__edit-form {
  .ant-form-item-required,
  label {
    display: flex;
    width: 100%;
  }
  .description__auto-description {
    min-height: 80px;
  }
  .description-drawer__label-title::before {
    margin-right: 4px;
    color: $Red;
    font-family: SimSun, sans-serif;
    content: '*';
  }
  .ant-switch-small {
    align-self: center;
  }
}

.description__analyses {
  padding-left: 0px;
}
