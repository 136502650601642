@import 'project_variables.scss';

.api-automation-popover {
  .ant-card-body {
    padding: 0;
    width: 450px;
    border: 0;
  }
  .ant-select {
    width: 450px;
    margin-bottom: 24px;
  }
  .data-preview {
    height: 350px;
    border: 1px solid $AntBorderGrey;
    border-radius: 4px;
    overflow: auto;
  }
}

.api-automation-drawer {
  .external {
    margin-bottom: 0 !important;
  }
  .internal,
  .api-automation__select-event {
    .ant-form-item {
      margin-bottom: 10px !important;
    }
  }
  .text-info {
    color: $Grey;
  }
  &__copy {
    margin-bottom: 0 !important;
  }
  .preview__blue {
    color: $Blue;
  }
  &__preview {
    margin: 40px 0px 0px 8px;
    &:hover {
      color: $Blue;
    }
  }
}

.api-automation-table__row {
  &:hover {
    background-color: $AntHoverBlue;
  }

  .warning {
    color: $Red !important;
    margin-left: 8px !important;
  }
}

.api-automation-error-modal {
  white-space: pre-wrap;
}
