@import 'project_variables.scss';

.screen-browser-info {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 2.5rem;
  text-align: center;
  background-color: $LightBlue;
  color: $Blue;
}
