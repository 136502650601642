@import 'project_variables.scss';

.delete-list-modal__list-header {
  padding: 12px;
  background-color: $AntTableHeaderGrey;
  border-bottom: 1px solid $AntTableBorderGrey;
}
.delete-list-modal__list-values {
  padding: 12px;
  border-bottom: 1px solid $AntTableBorderGrey;
}

.modal-background {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 1001;
  background-color: rgba(0, 0, 0, 0.65);
}

.modal-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.modal-info {
  background-color: $White;
  display: flex;
  align-items: center;
  height: 2rem;
  padding-right: 10px;
  user-select: none;

  &.error {
    color: $Red;
  }

  &.warning {
    color: $Yellow;
  }

  &.info {
    color: $Blue;
  }
}

.modal-info__message {
  margin-right: 10px;
}

.modal-info__icon {
  display: flex;
  align-self: stretch;
  flex-direction: column;
  justify-content: center;
  padding: 10px;
  margin-right: 10px;

  &.error {
    background-color: $LightRed;
  }

  &.warning {
    background-color: $LightYellow;
  }

  &.info {
    background-color: $LightBlue;
  }
}

.modal-info__interaction {
  margin-left: auto;
  display: flex;
  align-items: center;
  cursor: pointer;

  .modal-info__interaction-text {
    font-size: 0.8em;
    text-decoration: underline;
  }
}
