@import 'project_variables.scss';
.translation-import-export {
  background-color: $White;
}
.popover-model-translation-table___border {
  border-bottom: 1px solid $MediumGrey;
}

.popover-modal-translation-table {
  height: 150px;
  margin: 4px 0;
  border: 1px solid $AntBorderGrey;
}

.translations-header-cell {
  width: 100%;
}

.translation-import-export__box {
  height: 89px;
  position: relative;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 15px;
  padding: 5px;
  flex: 0 0 235px;
}

.translations-page {
  .ant-form-item {
    margin-bottom: 0;
  }
  input {
    border: 1px solid transparent;
    padding-right: 0;
  }
  .border-visible {
    border: 1px solid $AntBorderGrey;
  }
  .fill-blue svg {
    color: $Blue;
  }
  .fill-grey svg {
    color: $Grey;
  }
  .ant-tag {
    margin: 0;
    color: $Blue;
    border-radius: 8px;
    border-color: $Blue;
    line-height: 14px;
    align-content: center;
  }
}

.language-settings-page {
  .ant-form-item {
    margin-bottom: 0;
  }
}
.language-settings-page__select {
  .ant-select {
    width: 100%;
  }
  &.border-invisible {
    .ant-select {
      &.ant-select-single {
        .ant-select-selector:not(:hover) {
          border: 1px solid transparent;
        }
        .ant-select-selector:focus-within {
          border: 1px solid $AntBorderGrey;
        }
      }
    }
  }
}
