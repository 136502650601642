@import 'project_variables.scss';

.attribute-managing-table__custom-attribute {
  margin: 0 !important;
  .ant-input-sm {
    border: transparent;
    &:hover,
    &:focus {
      border: 1px solid $Blue;
    }
  }
}

.attribute-managing__table-row.ReactVirtualized__Table__row {
  .edit-button {
    display: none;
    cursor: pointer;
  }
  &:hover,
  &:focus {
    background-color: $AntHoverBlue !important;
    .edit-button {
      display: inline-block;
      color: $Blue;
    }
  }
  .item-count.ant-tag {
    margin: 0;
    color: $Blue;
    border-radius: 8px;
    border-color: $Blue;
    line-height: 14px;
    align-content: center;
    padding-left: 4px;
    padding-right: 4px;
  }
}

.attribute-managing-page__title {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: $Blue;
}

.attribute-managing-page__sub-title {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
}

.attributes-managing__select-dropdown {
  .ant-select-item {
    padding-top: 3px;
    padding-bottom: 3px;
  }

  .ant-select-item-option-active {
    &:not(:hover) {
      background-color: white;
    }
  }

  .attributes-managing__add-attribute {
    padding-left: 11px;
    cursor: pointer;

    &:hover {
      background-color: $LightGrey;
    }
  }

  .attributes-managing__add-attribute-icon {
    padding-top: 10px;
    padding-right: 11px;
  }
}
