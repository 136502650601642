@import 'project_variables.scss';

.catalogue-shop-preview {
  overflow: scroll;
  min-height: 200px;
  max-height: 200px;
  line-height: initial;
  background-color: $LightGrey;
  box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.2);
  overflow: -moz-scrollbars-none;
  &::-webkit-scrollbar {
    display: none;
  }
}

.catalogue-shop-preview__image {
  max-height: 100%;

  img {
    height: 80px;
    width: 100%;
    background: $White;
    object-fit: contain;
  }
}

.catalogue-shop-preview__inner {
  padding: 0px 10px;
}

.catalogue-shop-preview__title {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 12px;
  margin: 0;
  margin-top: 5px;
}

.catalogue-shop-preview__content {
  margin: 10px 0;
  white-space: nowrap;
  overflow: hidden auto;
  text-overflow: ellipsis;
  font-weight: 500;
  font-size: 12px;

  display: flex;
  flex-flow: column wrap;

  .preview {
    display: flex;
    flex-flow: row nowrap;
    margin-bottom: 3px;

    .preview-label {
      text-overflow: ellipsis;
      overflow: hidden;
      width: 50%;
    }

    .preview-data {
      width: 50%;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
}
