.ReactVirtualized__Table__headerRow {
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
  text-transform: none;
  text-align: left;
  background: #fafafa;
  border-bottom: 1px solid #e8e8e8;
  line-height: initial;

  .ReactVirtualized__Table__headerTruncatedText {
    vertical-align: bottom;
  }
}

.ReactVirtualized__Table__row {
  border-bottom: 1px solid #e8e8e8;

  &:hover {
    .select-with-preview {
      border: 1px solid $AntBorderGrey;
      border-radius: 4px;
      padding: 4px 11px;

      .icon-down {
        display: block;
      }
    }

    .input-with-preview {
      border: 1px solid $AntBorderGrey;
      border-radius: 4px;
      padding: 4px 11px;
    }
  }
}

.ReactVirtualized__Table__headerColumn,
.ReactVirtualized__Table__rowColumn {
  margin-right: 16px;
}

.ReactVirtualized__Table__headerColumn:first-of-type,
.ReactVirtualized__Table__rowColumn:first-of-type {
  margin-left: 16px;
}
