@import 'project_variables.scss';

.application__mapping_left .application__row_selected {
  background-color: $Blue !important;
  color: white;
}

.application__hidden {
  visibility: hidden;
}

.application,
.application__overview-wrapper {
  height: 100%;
  width: 100%;
}

.slide {
  height: 100%;
  width: 100%;
}

.application__overview-wrapper {
  display: flex;
  flex-flow: column;
  height: 100%;
}

.application__overview-table {
  overflow: auto;
  border: 1px solid $AntBorderGrey;
  border-radius: 2px;
  padding: 15px;
  height: 100%;
}

.application__overview-container {
  padding-bottom: 15px;
}

.application__overview-top {
  padding-bottom: 20px;
}

.application-overview__title-row {
  justify-content: flex-end;
  padding-bottom: 1px;
}

.application__overview-element {
  border: 1px solid $Grey;
  cursor: pointer;

  &.application__info {
    border: 1px solid $Blue;

    .application__header_row {
      border-left: none;
    }
  }

  &.application__warning {
    border: 1px solid $Yellow;

    .application__header_row {
      border-left: none;
    }
  }

  &.application__error {
    border: 1px solid $Red;

    .application__header_row {
      border-left: none;
    }
  }
}

.application__overview-group-container {
  .application__group-tooltip {
    width: 300px;
    line-height: 20px;
  }
}

.application__overview-group {
  padding: 20px;
  padding-right: 0;
  padding-bottom: 5px;
  margin-top: 5px;
  margin-bottom: 20px;
  display: flex;
  flex-flow: row wrap;

  &.blue {
    border: 1px solid $Blue;
  }
  &.orange {
    border: 1px solid $Yellow;
  }
  &.red {
    border: 1px solid $Red;
  }
}

.application__table-wrapper {
  padding: 20px;
  padding-top: 0;
}

.application__row {
  padding-left: 10px;
  cursor: pointer;
  border-bottom: 1px solid $AntBorderGrey;
  line-height: 29px;

  &.mapped.rank0,
  &.mapped.rank {
    background-color: $Blue;
    color: white;

    &.application__row_default {
      color: $Grey !important;
    }
  }

  &.mapped {
    &.rank1 {
      color: $Green;
    }
    &.rank2 {
      color: $Yellow;
    }
    &.rank3 {
      color: $Red;
    }
  }

  &.keyboard-selected,
  &.mapped.keyboard-selected {
    background-color: $LightBlue !important;
    color: black !important;
    text-decoration: underline;
  }
}

.application__row_grey {
  color: $IconGrey;
}

.application__row_default {
  color: $IconGrey;
  font-style: italic;
}

.application__row:hover {
  background-color: $LightBlue !important;
  color: $Blue !important;
}

.application-configs__table .application__row:hover {
  background-color: $LightBlue !important ;

  .ant-blue-batch {
    .ant-badge-count {
      background-color: $LightBlue;
      color: $Blue;
      box-shadow: 0 0 0 1px $Blue inset;
    }
  }
}

.application__column .application__row:hover {
  background-color: $LightBlue !important ;
}

.application__item_attributes {
  width: 100%;
  margin-bottom: 20px;
  align-items: center;
  box-sizing: border-box;
  padding: 10px;
}

.application__search-wrapper {
  box-sizing: border-box;
  height: 30px;
  width: 100%;
  flex: 1;
  max-width: 500px;
  line-height: 29px;
  display: inline-block;
}

.application__search {
  border-bottom: 1px solid $Grey;
  display: table;
  width: 100%;
}

.application__search_button {
  text-align: center;
  background: $LightGrey;
  display: table-cell;
  cursor: pointer;
  width: 29px;
  font-size: 14px;
  border-left: 1px solid $Grey;
  box-sizing: border-box;
  vertical-align: middle;
  color: $IconGrey;
  line-height: 29px !important;
}

.application__header_row {
  background: $LightGrey;
  box-sizing: border-box;
  padding-left: 12px;
  line-height: 30px;
  border-left: 3px solid $Blue;
  color: $Blue;
  font-weight: 600;
}

.application__column {
  height: 100%;
  width: 25%;
  padding-right: 10px;
  box-sizing: border-box;
  display: flex;
  flex-flow: column;

  .spinner-center {
    margin-top: 15px;
  }
}

.application__table {
  border: 1px solid $Grey;
  border-top: 0 !important;
  border-bottom-left-radius: 2px;
  border-bottom-right-radius: 2px;
  overflow: auto;
  overflow-x: hidden;
  flex: 1;
}

.application__title {
  padding-bottom: 5px;
}

.application__part_row {
  padding-left: 15px;
}

.application__part_row:nth-of-type(even) {
  background-color: $White;
}

.application__part_row:nth-of-type(odd) {
  background-color: $LightGrey;
}

.application__part_row:hover {
  background-color: $LightBlue !important ;
}

.application__category-form {
  &.ant-form {
    padding-left: 20px;
    padding-top: 20px;
  }
}

.application__details-menu {
  border: 1px solid $Grey;
  border-radius: 2px;
  overflow: auto;
}

.application__details-menu-header {
  border: 1px solid $Grey;
  border-radius: 2px;
}

.application__config_wrapper {
  display: inline-block;

  .application__config {
    display: inline-block;
    padding: 0 5px 0 5px;
    color: $IconGrey;
  }
}

.application__subconfig-wrapper {
  display: inline-block;
}

.application__config-value {
  display: inline-block;
  line-height: 22px;
  padding: 0 5px 0 5px;
  background-color: $Blue;
  color: white;
  margin-right: 5px;
}

.application__details-container {
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.application__details {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.application__mapping_left {
  flex-direction: column;
  min-width: 300px;
  max-width: 500px;
  flex: 0 0 35%;
  height: 100%;
  overflow: hidden;
  position: relative;
}

.application__smart-mapping {
  height: 32px;
  line-height: 30px;
  overflow: hidden;
  color: $Grey;
  margin-bottom: 20px;
  flex-shrink: 0;
  max-width: 500px;
}

.application__smart-mapping_input {
  flex: 1;
}

.application__button-container {
  padding-right: 20px;
}

.application__add-button {
  margin-left: 4px;
}

.application__drawer-delete-button {
  height: 30px;
  color: $IconGrey;
  border: none;
  background: none;
  padding: 4px;
  font-size: 1.3rem;
  cursor: pointer;
}

.application__up-button,
.application__down-button {
  height: 15px !important;
  width: 16px !important;
  line-height: 10px;

  .anticon {
    font-size: 12px !important;
    vertical-align: 3px;
  }

  .ant-btn {
    height: 15px !important;
    width: 16px !important;
  }
}

.application__down-button {
  margin-top: 2px;
}

.application__details_title {
  padding-left: 10px !important;
}

.application__details_title.selected {
  .application__qty {
    color: $Grey;
  }

  .application__qty_input {
    border-bottom: 1px solid $Grey;
    color: $Grey;
  }
}

.application__details_title_text {
  display: table-cell;
  width: 100%;
  cursor: pointer;
  font-size: 14px;

  &.text-gray {
    color: $Grey;
  }
}

.application__mapping_right {
  box-sizing: border-box;
  position: relative;
  width: 100%;
}

.application__vcdb-select {
  width: 300px;

  &.ant-form-item {
    margin-bottom: 0;
  }
}

.application__segment-title {
  color: $Blue;
  font-size: 18px;
}

.application__submodel-region {
  height: 100%;
  width: 25%;

  .application__column {
    width: 100%;
    padding-right: 0;
  }
}

.application__production-region-vcdb {
  height: 100%;
  width: 50%;

  .application__column {
    width: 100%;
  }
}

.application__position-subconfig {
  .application__subconfig_column {
    height: 100%;
    width: 40%;
  }
}

.application__submodel {
  height: 60%;
  width: 100%;
}

.application__equipment-years {
  height: 60%;
  width: 50%;
}

.application__region {
  height: 40%;
  width: 100%;
  padding-top: 20px;
  box-sizing: border-box;

  &.vcdb_equipment {
    float: left;
  }
}

.application-configs__table-container {
  flex: 1;
  overflow: hidden;
}

.application-configs__table {
  height: 100%;
  width: 100%;
  flex-flow: column;

  .table {
    height: 100%;
    margin-top: 5px;
  }

  .ant-blue-batch {
    .ant-scroll-number-only {
      transition: none;
    }
  }
}

.application__config-row {
  display: flex;
  padding-left: 10px;
}

.application__config-bases-row {
  padding-left: 20px;
}

.application__config-name {
  vertical-align: top;
}

.application__config-buttons {
  flex: auto;
  text-align: right;
  display: table-cell;
}

.application__config-tag {
  &.ant-tag {
    background: $White;
    margin-right: 5px;
    cursor: pointer;
  }

  &.rank0 {
    border-color: $Blue;
    color: $Blue;
    &.mapped {
      background: $LightBlue;
    }
  }
  &.rank1 {
    border-color: $Green;
    color: $Green;
    &.mapped {
      background: $LightGreen;
    }
  }
  &.rank2 {
    border-color: $Yellow;
    color: $Yellow;
    &.mapped {
      background: $LightYellow;
    }
  }
  &.rank3 {
    border-color: $Red;
    color: $Red;
    &.mapped {
      background: $LightRed;
    }
  }
}

.application__config_more {
  color: $Blue;
  margin-right: 5px;
}

.application__qualifier-note-values {
  color: $IconGrey;
  height: 30px;
  text-align: right;

  .application__qualifier-note-value-wrapper {
    padding-right: 5px;

    span {
      padding-left: 10px;
    }
  }
}

.application__digital-asset-preview-values {
  flex: auto;
  color: $IconGrey;
  height: 30px;
  overflow: hidden;
  text-align: right;

  .application__digital-asset-value {
    height: 30px;
    max-width: 160px;
    text-overflow: ellipsis;
    display: inline-block;
    overflow: hidden;
    padding-right: 10px;
  }
}

.application__row_selected {
  .application__qualifier-note-values {
    color: $Grey;
  }

  .ant-blue-batch {
    .ant-badge-count {
      background-color: $Blue;
      color: $White;
      box-shadow: 0 0 0 1px $White inset;

      .ant-scroll-number-only {
        transition: none;
      }
    }
  }
}

.application__config-mapped-values {
  display: flex;
  flex: auto;
  text-align: right;
  // display: table-cell;

  .ant-badge {
    display: flex;
    align-items: center;
    margin-left: 5px;
  }
}

.application__config-search-error {
  padding: 10px;
}

.application__mapped-values-inner {
  height: 30px;
  overflow: hidden;
  flex: 1;
}

.application__subconfig-button {
  height: 70px;
  line-height: 70px !important;
  width: 15px;
  border: 1px solid $Grey;
  border-right: none;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
  cursor: pointer;
  font-size: 10px;
  color: $IconGrey;
  text-align: center;
}

.application__subconfig {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding-left: 10px;
  display: flex;
  flex-flow: column;
  flex: auto;
  overflow: hidden;
}

.application__make-model-year {
  width: 100%;
  box-sizing: border-box;
  padding-left: 10px;
  margin-left: 10px;
  min-height: 400px;

  .application__equipment-view {
    .application__equipment-type-col {
      height: 100%;

      .application__column {
        width: 100%;
      }
    }
  }
}

.application__make-model-wrapper {
  width: 100%;
  height: 100%;

  .application__column {
    width: 50%;
  }

  &.application__make-model-vcdb {
    height: 60%;
  }
}

.application__type-wrapper {
  height: 40%;
  width: 100%;
  padding-top: 20px;

  .application__column {
    width: 50%;
  }
}

.application__mfr-equipment-wrapper {
  height: 100%;

  .application__column {
    width: 50% !important;
  }

  &.application__mfr-equipment-vcdb {
    height: 60%;
    width: 100%;
  }
}

.application__equipment-type-wrapper {
  height: 40%;
  width: 100%;
  padding-top: 20px;

  .application__column {
    width: 100%;
  }
}

.application__equipment-type-filter {
  height: 60% !important;
}

.application__equipment-group {
  height: 40%;
  padding-top: 20px;

  .application__column {
    width: 100%;
  }
}

.application__qualifiers {
  flex: 1;
  width: 100%;
  display: flex;
  flex-flow: column;
  box-sizing: border-box;
  padding: 10px;
  padding-bottom: 0;
  margin-left: 10px;
  overflow: hidden;

  .ant-select {
    width: 100%;
  }
}

.application__notes {
  padding-top: 10px;
  height: 150px;
}

.application__notes-editor {
  margin-top: 5px;
  padding-top: 5px;
  border: 1px solid $Grey;
  height: 100px;
  overflow: auto;

  > .text-editor-container {
    border: none;
    min-height: auto;
    height: 100%;
  }
}

.application__subconfig_group {
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: hidden;
  margin-top: -16px;
  margin-right: -10px;
}

.application__subconfig_group_columns {
  display: flex;
  flex: 1;
  overflow: hidden;
}

.application__subconfig_columns {
  width: 100%;
  height: 100%;
  padding-left: 10px;
  padding-bottom: 6px;
  box-sizing: border-box;
}

.application__subconfig_column {
  width: 20%;
  height: 50%;
  padding-top: 16px;
  box-sizing: border-box;
  display: inline-block;

  .application__column {
    width: 100%;
  }
}

.application__subconfig_closed {
  flex: 0 0 30px;
  overflow: hidden;
}

.application__subconfig-card-container {
  height: 100%;

  > .ant-tabs-card {
    height: 100%;

    .ant-tabs-nav {
      margin-left: 10px;
    }
  }

  .ant-tabs-extra-content {
    display: flex;
    max-width: calc(100% - 190px);
    overflow: hidden;
  }

  .ant-tabs-content {
    display: flex;
  }

  .ant-tabs-tabpane {
    overflow-x: hidden;
    overflow-y: auto;
    width: 100%;
  }
}

.application__subconfig-card-container > .ant-tabs-card .ant-tabs-content > .ant-tabs-tabpane {
  display: flex;
}

.application__input {
  width: 100%;
  height: 33px;
  padding: 4px;
  border: 1px solid $Grey;
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
  border-bottom: none;
}

.application__overview-buttons {
  display: grid;
  grid-template-rows: 30px;
  grid-template-columns: minmax(200px, 480px) 190px auto 214px;
  grid-column-gap: 20px;
  align-items: center;
  .ant-switch {
    margin-left: 10px;
    margin-bottom: 2px;
  }
}

.application__overview_search {
  display: flex;
  align-items: center;
}

.application__overview_actions {
  text-align: right;

  .application__action-button {
    width: 125px;
    margin-left: 0;
    margin-right: 4px;
  }

  button {
    margin-left: 4px;
  }
}

.application__checkbox-button {
  height: 30px;
  width: 300px;
}

.application__overview-position-qty,
.application__bundle-position {
  flex: 1;

  &.rank1 {
    border-left: 0;

    .ant-select-selector {
      border-left: 3px solid $Green !important;
    }
  }
}

.application__overview-position {
  display: inline-block;
  margin-left: 20px;
  vertical-align: top;

  span {
    vertical-align: top;
  }
}

.application__overview-position-closed {
  display: inline-block;
  box-sizing: border-box;
  margin-left: 5px;
  padding-left: 5px;
  width: 275px;
  border: 1px solid $Grey;
}

.application__position-button.ant-btn {
  display: flex;
  flex: 1;

  &.rankBorder {
    border-left: 3px solid $Green;
  }

  i {
    flex: 0;
    margin-top: 4px;
  }

  .application__position-clear {
    height: 14px;
  }

  .application__position-down {
    text-align: right;
  }

  .anticon {
    color: $AntIconGrey;
  }
}

.application__dropdown-icon {
  cursor: pointer;
}

.application__overview-position-dropdown {
  display: inline-block;
  margin-left: 5px;
  width: 100%;
  height: 300px;

  .anticon {
    color: $AntIconGrey;
  }
}

.application__overview-position-text {
  display: inline-block;
  width: 250px;
  cursor: pointer;
}

.application__position-list {
  display: block;
  background: white;
  width: 100%;
  height: 270px;
  z-index: 10;
  position: relative;
  box-sizing: border-box;
  border-radius: 4px;
  box-shadow: $AntDropdownShadow;
  margin-top: 3px;
}

.application__position_row {
  padding-left: 10px;
  cursor: pointer;
}

.application__default {
  display: flex;
  flex: 0 0 30px;
  align-items: center;
  margin-bottom: 20px;
}

.application__default-value-title {
  color: $Blue;
  padding-right: 10px;
}

.application__default-values {
  display: flex;
  align-items: center;
  box-sizing: border-box;
  width: 100%;
  padding: 5px 0;
}

.application__default-title {
  margin-right: 10px;
}

.application__default-value {
  display: flex;
  align-items: center;
  padding: 0 10px;
  &:last-child {
    border: none;
  }
}

.application__default-mfr {
  flex: 1;
}

.application__default-part-type {
  flex: 2;
}

.application__overview-qty {
  display: flex;
  padding: 0 10px;

  input {
    width: 50px;
    margin-left: 5px;
    padding-left: 5px;
    font-size: 16px;
    border: none;
    border-bottom: 1px solid $Grey;
  }
}

.application__default-mfr-input {
  flex: 1;
  padding-left: 5px;
  font-size: 16px;
  border: none;
  border-bottom: 1px solid $Grey;
}

.application__overview-title-container {
  flex: 100%;
  display: flex;
}

.application__overview-title {
  flex: auto;
  display: inline-block;
  min-width: 200px;
}

.application__group-title-container {
  flex: 100%;
  display: flex;
  overflow: hidden;
}

.application__group-title {
  flex: auto;
  display: block;
  min-width: 200px;
  color: $Blue;
  text-overflow: ellipsis;
  overflow: hidden;
}

.application__group-title-analyses {
  flex: auto;
  display: flex;
  align-items: center;
  color: $Red;
}

.application__overview-value-wrapper {
  display: inline-block;
}

.application__overview-value {
  line-height: 20px;
}

.application__qty {
  display: inline-block;
  color: $IconGrey;
  font-weight: normal;
  padding-left: 5px;
  width: 75px;
  box-sizing: border-box;
}

.application__qty_input {
  background: transparent;
  border: none;
  border-bottom: 1px solid $IconGrey;
  width: 30px;
}

.application__qty-input_blue {
  color: $Blue;
}

.application__qty-input_grey {
  color: $IconGrey;
}

.application__delete-button {
  display: inline-block;
  padding-right: 5px;
  color: $IconGrey;
  cursor: pointer;
}

.application__qualifiers-top {
  display: flex;
  justify-content: space-between;
  flex: 0 0 30px;
  height: 40px;
  margin-bottom: 10px;
}

.application__qualifiers-table-container {
  border: 1px solid $AntBorderGrey;
  border-radius: 2px;
}

.application__qualifiers-table {
  overflow: auto;
  display: flex;
  flex-flow: column;
  flex-grow: 1;
}

.application__qualifiers-filter {
  flex: 0 0 40px;
  line-height: 40px;
  border-bottom: 1px solid $AntBorderGrey;
  padding-left: 10px;
}

.application__qualifiers-search {
  flex: 1;
  margin-right: 20px;
  max-width: 600px;
}

.application__qualifiers-value-filter {
  &.ant-select {
    width: 170px;
  }

  .ant-select-selection-placeholder {
    opacity: 1;
  }
}

.application__qualifiers-type-filter {
  flex: 0 0 150px;
  padding-right: 22px;

  .ant-select {
    width: 100%;
  }
  .ant-select-selection-placeholder {
    opacity: 1;
  }
}

.application__qualifiers-container {
  display: inline-block;
  flex: 100%;
  margin-left: 10px;
}

.application__qualifier-row {
  &.ant-list-item {
    padding-left: 10px;
    padding-right: 5px !important;
  }

  &:hover {
    .application__qualifier-input {
      border: 1px solid $AntBorderGrey;
    }
  }

  .ant-checkbox-inner {
    transition-duration: 0s;
  }
}

.application__qualifiers-type {
  display: inline-block;
  flex: 0 0 115px;
  padding-right: 5px;
  color: $IconGrey;
  text-align: right;
}

.application__qualifier-copy {
  // visibility: hidden;
  padding-left: 5px;
}

.application__qualifiers-delete {
  display: inline-block;
  flex: 0 0 20px;
  color: $IconGrey;
  text-align: left;
  padding: 0;
  background: none;
  border: none;
  font-size: 16px;
}

.application__qualifier-text {
  padding-right: 5px;
}

.application__qualifier-value {
  color: $Blue;
  padding-right: 5px;
}

.application__qualifier-input {
  min-width: 40px;
  height: 24px;
  margin-right: 5px;
  padding-left: 7px;
  padding-right: 7px;
  border: 1px solid transparent;
  border-radius: 2px;
  font-size: 14px;
  background: transparent;
  cursor: pointer;

  &::placeholder {
    font-style: italic;
  }

  &.application__qualifier-input-border {
    border-color: $AntBorderGrey !important;
  }

  &.application__qualifier-input-empty {
    font-style: italic;
    color: $Grey;
  }

  &.application__qualifier-input-required {
    border-color: $Red !important;
  }

  &.ant-input {
    width: 55px;
    margin-right: 5px;
    border-color: transparent;
    transition: none;
  }
}

.row.application__row.application__qualifiers_used {
  border-left: 2px solid $Green;
  background-color: $LightGreen;

  &.rank3 {
    border-left: 2px solid $Red;
    background-color: $LightRed;
  }
}

.application__qualifiers_selected {
  background: $Blue !important;
  color: $White;

  &:hover {
    background: $Blue !important;
  }

  input {
    background: $White;
  }

  .application__qualifiers-type,
  .application__qualifiers-delete {
    color: $White;
  }
}

.application__qualifiers-bottom {
  flex: 0 0 150px;

  .application__notes {
    flex: 100%;
    display: inline-block;
    box-sizing: border-box;
    vertical-align: top;
  }

  .application__qualifier-value-types {
    flex: 0 0 130px;
    display: inline-block;
    box-sizing: border-box;
  }
}

.application__qualifier-value-types {
  padding-top: 10px;
  padding-left: 20px;
  height: 150px;
}

.qualifier-value-types {
  padding-top: 5px;
}

.qualifier-value-type {
  color: $Blue;
  width: 50%;
  display: inline-block;
  font-size: 12px;
  cursor: default;
  user-select: none;
}

.application__part-type {
  display: flex;
  position: relative;
  margin-right: 10px;
  flex: 1;
}

.application__part-type-title {
  margin-right: 20px;
}

.application__part-type-dropdown {
  width: 100%;
  flex: 1;
}

.application__mfr-label {
  line-height: 30px;
  flex-basis: 35%;
  padding-bottom: 10px;

  input {
    margin-left: 10px;
    margin-top: 2px;
    padding-left: 5px;
    flex: 1;
  }

  .application__mfr-tooltip {
    width: 300px;
    line-height: 20px;
  }
}

.application__analysis-message {
  &.blue {
    color: $Blue;
  }
  &.yellow {
    color: $Yellow;
  }
  &.red {
    color: $Red;
  }
}

.application__analysis-message {
  padding-right: 4px;
  font-size: 20px;
  vertical-align: text-bottom;
}

.application-tag {
  box-sizing: border-box;
  font-size: 14px;
  cursor: default;
  margin-right: 10px;
  border: 1px solid $Grey;
  padding: 0px 5px;
  user-select: none;
  display: inline-flex;
  align-items: center;
  height: 20px;
  background-color: $White;
}

.application__key-container {
  display: flex;
  align-items: flex-end;
  flex: 0 0 30px;

  .key__title {
    color: $Blue;
    padding-right: 10px;
  }

  .key__tags-container {
    display: flex;
  }

  .key__info-text {
    margin-right: 5px;
  }

  .key__info-icon {
    margin-left: 5px;
    color: $IconGrey;
    font-size: 14px;
  }

  .key__used-by-brand {
    width: 15px;
    height: 2px;
    background-color: $Blue;
    margin-right: 5px;
  }
}

.application__links {
  .ant-alert {
    margin-bottom: 10px;
  }
}

.applications__grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
  grid-column-gap: 15px;
  grid-row-gap: 15px;
}

.application__position-hint {
  color: $Blue !important;
}

.applications__wrapper {
  padding: 10px;
  margin-bottom: 10px;

  &.blue-bg {
    background-color: #e6f7ff;
    border: 1px solid #91d5ff;
    border-radius: 4px;
  }

  &.orange-bg {
    background-color: #fffbe6;
    border: 1px solid #ffe58f;
    border-radius: 4px;
  }

  &.red-bg {
    background-color: #fff1f0;
    border: 1px solid #ffa39e;
    border-radius: 4px;
  }
}

.application__bundle-edit-cell {
  border: 1px solid transparent;
  border-radius: 2px;
  min-height: 24px;
  max-height: 32px;
  overflow: hidden;
  cursor: pointer;
  padding-left: 5px;

  &:hover,
  &.border-visible {
    border: 1px dashed $AntBorderGrey;
    .application__bundle-edit-icon {
      display: block;
    }
  }

  .application__bundle-value-wrapper {
    overflow: auto;
  }

  .application__bundle-edit-icon {
    color: $AntIconGrey;
    padding: 4px;
    display: none;
  }
}

.application__bundle-qualifier-value {
  padding-left: 3px;
  padding-right: 3px;
}

.application__card {
  .ant-card {
    height: 100%;
  }

  .ant-card-body {
    padding: 15px;
    height: 100%;
    display: flex;
    flex-flow: column;
  }

  .ant-descriptions-item-label {
    color: $FontColor;
    font-weight: 500;
    font-size: 12px;
    line-height: 19px;
  }

  .ant-descriptions-item-content {
    line-height: 19px;
  }

  .ant-descriptions-item {
    padding: 0;
  }

  .application__card_config {
    border: 1px solid transparent;
    border-radius: 4px;
    padding: 4px;
    position: relative;

    &:hover {
      border: 1px dashed $AntBorderGrey;
      .application__card_config__edit-icon {
        display: block;
      }
    }

    &__edit-icon {
      position: absolute;
      color: $Grey;
      bottom: 0;
      right: 0;
      padding: 4px;
      display: none;
    }
  }
}

.application__card-title {
  color: $Blue;
  font-weight: 600;
  &:hover {
    color: $Grey;
    cursor: pointer;
    .application__card_qty {
      color: $Grey;
    }
  }
}

.application__card-info-title {
  color: $Blue;
  font-weight: 600;
  margin-bottom: 10px;
}

.application__card-content {
  margin-top: 20px;
  margin-bottom: 20px;
}

.application__card_qty {
  display: inline-block;
  color: $FontColor;
  font-weight: 500;
  padding-left: 5px;
  min-width: 70px;
  max-width: 125px;
  text-align: right;

  .application__card_qty-value {
    font-weight: normal;
  }
}

.application__card_index {
  .ant-tag {
    margin-right: 0;
  }
}

.application__card_label {
  font-weight: 500;
  color: $FontColor;

  &-default {
    color: $Grey;
  }
}

.application__inner-value-list {
  .ant-descriptions-item {
    padding-bottom: 0;
  }
}

// Masonry container
.application__card_configs {
  column-count: 2;
  column-gap: 10px;
  margin-top: 10px;
  flex: 1;

  // Masonry bricks or child elements
  .application__card_config {
    display: inline-block;
    margin-bottom: 16px;
    width: 100%;

    table {
      border-spacing: 0;
    }
  }

  .application__card_config {
    display: inline-block;
    margin-bottom: 16px;
    width: 100%;

    table {
      border-spacing: 0;
    }
  }
}
.application__card_config {
  cursor: pointer;
}

.application__card-subconfigs {
  padding-top: 4px;
  word-wrap: break-word;
}

.application__analysis-container {
  .item-analysis-container {
    display: inherit;
    height: auto;

    .ant-tag {
      margin-bottom: 8px;
    }

    .item-analysis__message-container {
      height: 22px;
      display: inline-flex;
      margin-bottom: 4px;
    }
  }
}

.application__details-drawer {
  .ant-drawer-wrapper-body {
    display: flex;
    flex-direction: column;
  }

  .ant-drawer-body {
    flex: 1;
    padding-bottom: 10px;
    overflow: auto;
  }
}

.application__defaults-drawer {
  .ant-drawer-wrapper-body {
    display: flex;
    flex-direction: column;
  }

  .ant-drawer-body {
    flex: 1;
    padding-top: 0;
    padding-bottom: 0;
    padding-right: 0;
    overflow: auto;
  }

  .application__defaults {
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow-x: hidden;

    .application__defaults-form {
      flex: 1;
      display: flex;
      flex-direction: column;
      padding-top: 15px;
      padding-right: 20px;
      overflow: auto;
      overflow-y: auto;
      overflow-x: hidden;
    }

    .application__default-qualifiers-label {
      padding-bottom: 0;
    }

    .application__default-qualifiers-row {
      flex: 1;
      display: flex;
      flex-direction: column;
      min-height: 500px;
    }

    .application__default-qualifiers {
      display: flex;
      flex-direction: column;
      height: 100%;
    }

    .application__defaults-form-buttons {
      padding-top: 15px;
      padding-right: 20px;
      height: 60px;

      .application__defaults-cancel-button {
        margin-right: 15px;
      }
    }

    .ant-form-item {
      margin-bottom: 15px;
    }
  }
}

.application__linked-item-group {
  .application__unlink-button {
    color: $Red;
    &:hover {
      color: $Red;
    }
  }

  .application__linked-item-header {
    margin-bottom: 8px;
    font-weight: 500;

    .ant-tag {
      margin-left: 5px;
      margin-right: 5px;
    }
  }

  .application__card_config {
    cursor: default;

    &:hover {
      border: 1px solid transparent;
    }
  }

  .application__card-title:hover {
    color: $Blue;
    cursor: default;

    .application__card_qty {
      color: $IconGrey;
    }
  }
}

.application__linked-item-title {
  color: $Blue;

  .anticon-info-circle {
    margin-right: 5px;
  }

  .ant-btn {
    margin-left: 15px;
  }
}

.application__listing {
  margin-bottom: 6px;
}

.application__listing-explanation {
  overflow: auto;
  border: 1px solid $AntBorderGrey;
  border-radius: 4px;
  height: 100%;
}

.application__asset-preview-image {
  min-height: 150px;
  min-width: 150px;
  max-width: 400px;
  max-height: 400px;
}

.application__bundle-add-row {
  border-bottom: 1px solid $AntBorderGrey;
  padding: 0 5px;
  height: 40px;
  line-height: 40px;
  align-items: center;

  .application__bundle-brand-switch {
    margin-left: 175px;
  }
}

.application__bundle-row {
  display: grid;
  grid-template-rows: 33px;
  grid-template-columns: 55px 80px 130px 2fr 2fr 2fr 2fr 2fr 50px 60px;
  padding: 0 5px;
  grid-column-gap: 10px;
  border-bottom: 1px solid $AntBorderGrey;
  align-items: center;
  overflow: visible;

  &:last-of-type {
    border-bottom: none;
  }

  .ant-form-item {
    margin-bottom: 0;
  }
  .ant-form-item-explain {
    min-height: 10px;
    line-height: 10px;
    font-size: 12px;
  }
  .application__bundle-order {
    .ant-input-suffix {
      width: 0;
      margin-left: 0;
    }
  }
  .ant-form-item-has-error {
    .form-number-input {
      padding-right: 0;
    }
    &.application__bundle-order {
      .ant-form-item-feedback-icon {
        visibility: hidden;
      }
    }
  }

  .border-invisible {
    .ant-input-affix-wrapper:not(:hover) {
      border: 1px solid transparent;
    }
  }
}

.application__bundle-drawer {
  .application__default-qualifiers {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  .drawer-submit__content {
    overflow: hidden;
  }
}

.application__bundle-id-select {
  .ant-select-multiple {
    .ant-select-selector {
      max-height: 28px;
      overflow-y: auto;
      overflow-x: hidden;
    }
  }
}

.application__bundle-select {
  .ant-select {
    width: 100%;
  }

  &.border-invisible {
    .ant-select {
      &.ant-select-single {
        .ant-select-selector:not(:hover) {
          border: 1px solid transparent;
        }
        .ant-select-selector:focus-within {
          border: 1px solid $AntBorderGrey;
        }
      }
    }
  }
}

.qualifier__value-popover {
  min-width: 400px;
}

.application__change-vcdb-info-wrapper {
  max-width: calc(100% - 190px);
}

.application__change-vcdb-info {
  padding: 0 !important;
  padding-left: 3px !important;
  margin-right: 7px !important;
  flex: auto;

  .ant-alert-message {
    flex: auto;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}

.application__value-preview {
  margin-top: -6px;
}

.application__preview-tooltip {
  &.ant-tooltip {
    max-width: 400px;
  }

  .ant-tooltip-inner {
    background-color: $White;
  }
}
