@import 'project_variables';

.descriptions-list {
  color: rgba(0, 0, 0, 0.85);

  &__title {
    font-size: 16px;
    font-weight: bold;
    line-height: 1.5;
    margin-bottom: 10px;
  }

  &__childs {
    display: grid;
    grid-row-gap: 15px;
    grid-column-gap: 15px;
  }

  &__child-label {
    font-weight: 500;
    margin-right: 5px;
  }

  &__child-value {
    color: $FontColor;
  }

  &__child-label {
    font-weight: 500;
    margin-right: 5px;
  }
}
