@import 'project_variables.scss';

.analyses {
  height: 100%;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 20px;
  position: relative;
  overflow: auto;
}

.analyses--no-scroll {
  overflow: hidden;
}

.analyses__item-list.ant-list-sm {
  margin-bottom: 20px;

  .ant-row {
    width: 100%;
  }

  .ant-list-header,
  .ant-list-footer {
    background: $LightGrey;
  }

  .ant-list-header {
    padding: 0 !important;
  }

  .analyses__item-list-header {
    padding: 12px 16px;
  }

  .analyses__item-list-header-checkbox {
    background: $White;
    padding: 8px 16px;
    display: flex;
  }

  .analyses__item-list-check {
    margin-right: 10px;
  }

  .analyses__item-list-star {
    margin-right: 20px;
    padding-top: 2px;
    height: 16px;
    color: $Grey;
    cursor: pointer;

    &.marked {
      color: $SunriseYellow;
    }
    &:hover {
      color: $SunriseYellow;
    }
  }

  .analyses__item-list-part {
    width: 150px;
    margin-right: 20px;
  }

  .analyses__item-list-name {
    flex: 1;
  }

  .analyses__item-list-tags {
    button {
      height: 21px;
    }
  }

  button {
    background: $White !important;
  }
}

.analyses__reload-list.anticon {
  color: $Blue;
  margin-left: 5px;
  cursor: pointer;
}

.analyses__refresh_spinning {
  animation: spin $middle linear infinite;
}

.anlayses__tag-button {
  height: 22px !important;
  i {
    vertical-align: middle;
  }
}

.analyses__content {
  padding: 12px 24px;
  border: 1px solid $AntBorderGrey;
  border-radius: 4px;
  background: $LightGrey;
  margin-bottom: 20px;
}

.analyses__message {
  color: $IconGrey;
  padding-bottom: 10px;
  padding-right: 10px;
}

.analyses__message-row {
  .analyses__message {
    display: inline-block;
    width: 50px;
    text-align: right;
  }
}

.analyses__title-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: $Blue;
  margin-bottom: 10px;

  .analyses__title {
    display: flex;
    align-items: center;
    flex: 1;

    &.blue {
      color: $Blue;
    }

    &.yellow {
      color: $Yellow;
    }

    &.red {
      color: $Red;
    }
  }
  .analyses__title-name,
  .analyses__title-application {
    font-weight: 600;
  }

  .analyses__title-application {
    padding-left: 3px;
  }

  .analyses__part-tag {
    border: 1px solid;
    border-radius: 10px;
    padding: 2px 6px;
    margin-left: auto;
    font-size: 0.8em;
  }
}

.analyses__item-list {
  padding: 10px 0;
}

.analyses__item {
  padding-bottom: 2px;
}

.analyses__buttons {
  padding-top: 5px;

  button {
    margin-right: 5px;
  }
}

.analyses__item-button-wrapper {
  display: flex;
  flex-wrap: wrap;
}

.analyses__accepted,
.analyses__declined {
  color: $IconGrey;
}

.analyses__accepted {
  background: $LightGreen;
}

.analyses__declined {
  background: $LightRed;
}

.analyses__processed {
  color: $Grey;
}

.analyses__button-confirm {
  margin-right: 10px;
  width: 90px;
}

.analyses__button-decline {
  margin-right: 40px;
  width: 90px;
}

.analyses__button-add-session.ant-btn {
  padding-right: 10px;
  padding-left: 10px;

  a {
    text-decoration: none;
  }
}

.analyses__new-year-name {
  font-weight: 600;
}

.analyse__category-dropdown-container {
  width: 100%;
}

.analyse__category-dropdown-wrapper {
  background: $White;
  line-height: 25px;
  position: relative;
  cursor: pointer;
  overflow: hidden;
  flex-direction: column;
  border: 1px solid $MediumGrey;
  border-radius: 4px;
}

.analyse__category-dropdown {
  position: relative;
  background: $White;
  width: 100%;
  flex: 100%;
  overflow: hidden;
  left: -1px;
  height: 370px;
  z-index: 20;
  cursor: default;
}

.analyse__category-dropdown-table {
  height: 100%;
  overflow: auto;
  border: none;
}

.analyse-dropdown__search {
  border: none;
  width: 100%;
}

.analyse__category-dropdown-row {
  border-left: 3px solid $Blue;
  padding: 0 80px 0 20px;

  &:hover {
    .analyse__category-dropdown-choose {
      opacity: 1;
      cursor: pointer;
    }
  }
}

.analyse__category-dropdown-choose {
  border: 1px solid $Blue;
  color: $Blue;
  padding: 0 10px;
  position: absolute;
  right: 5px;
  height: 15px;
  line-height: 15px;
  font-size: 0.6em;
  top: 7px;
  opacity: 0;
  transition: $middle;

  &:hover {
    background: $LightBlue;
    color: $White;
  }
}

.analyse__category-dropdown-default {
  color: $Grey;
  position: absolute;
  right: 5px;
  top: 0;
}

.analyse-dropdown__preview-container {
  padding-right: 5px;
  padding-left: 5px;
  z-index: 2;
  flex: 0 0 25px;

  display: flex;

  &__icons {
    margin-left: auto;
    padding-top: 5px;
    display: flex;

    > :not(:first-child) {
      margin-left: 2px;
    }
  }
}

.analyse-dropdown__preview-category {
  user-select: none;
}

.analyse__breadcrumb-divider {
  padding-left: 10px;
  padding-right: 10px;
}

.analyse-tooltip {
  max-width: 280px;
}

.analyses__new-engine {
  background: $White;
  padding: 3px 10px;
  border: 1px solid $AntBorderGrey;
  border-radius: 4px;
  max-width: fit-content;
}

.analyses__engine-base {
  background: $White;
  padding: 3px 10px;
  border: 1px solid $AntBorderGrey;
  border-radius: 4px;
  font-variant: tabular-nums;
  font-feature-settings: 'tnum';
}

.analyses__engine-base-part {
  display: inline-block;
  min-width: 60px;

  &.cinch {
    padding-left: 3px;
    min-width: 75px;
  }

  &.inch {
    padding-left: 3px;
    min-width: 70px;
  }

  &.bore {
    min-width: 65px;
  }

  &.stroke {
    min-width: 65px;
  }

  &.right {
    text-align: right;
  }
}

.analyses__subconfig-label {
  margin-right: 5px;
}

.analyses__subconfig-select {
  min-width: 200px;
}

.analyses__alert-icon-message {
  &.blue {
    color: $Blue;
  }
  &.yellow {
    color: $Yellow;
  }
  &.red {
    color: $Red;
  }
  &.white {
    color: $White;
  }
}
