@tailwind components;
@tailwind utilities;
@import './global.scss';
@import './table.scss';
@import './main.scss';
@import './project_variables.scss';
@import './application_area.scss';
@import './attributes.scss';
@import './attributes_managing.scss';
@import './content.scss';
@import './description.scss';
@import './digital_assets.scss';
@import './ebay.scss';
@import './filter.scss';
@import './item_analysis.scss';
@import './items.scss';
@import './kit.scss';
@import './login.scss';
@import './marketing_copy.scss';
@import './package.scss';
@import './segment_checkboxes.scss';
@import './sidebars.scss';
@import './inbox.scss';
@import './icons.scss';
@import './import_export.scss';
@import './text_editor.scss';
@import './dropdown.scss';
@import './review.scss';
@import './github.scss';
@import './extended_info.scss';
@import './interchange.scss';
@import './pricing.scss';
@import './home.scss';
@import './settings.scss';
@import './refresh_page.scss';
@import './item_info.scss';
@import './screen_browser_info.scss';
@import './add_new_button.scss';
@import './button.scss';
@import './register.scss';
@import './analyses.scss';
@import './modal.scss';
@import './bouncing_loader.scss';
@import './digital_assets_parent.scss';
@import './channel.scss';
@import './receiver.scss';
@import './synchronisation.scss';
@import './catalogue.scss';
@import './help_center.scss';
@import './price_sheet_select.scss';
@import './ant_divider.scss';
@import './ant_global.scss';
@import './layout.scss';
@import './split-pane.scss';
@import './catalogue-shop-preview.scss';
@import './shop_preview.scss';
@import './forms.scss';
@import './receiver_catalogue.scss';
@import './user.scss';
@import './export_aces.scss';
@import './export_pdf.scss';
@import './account.scss';
@import './delivery_logs.scss';
@import './item_dashboard.scss';
@import './page.scss';
@import './image.scss';
@import './descriptions-list.scss';
@import './drawer-submit.scss';
@import './react_virtualized.scss';
@import './input_select_with_preview.scss';
@import './custom_fields_settings.scss';
@import './all_applications.scss';
@import './export_builder_advanced.scss';
@import './search_box_list.scss';
@import './api-settings.scss';
@import './language.scss';
@import './customVcdb.scss';
@import './external_sources.scss';
@import './brand_managing.scss';
@import './welcome.scss';
@import './api-automation';
@import './insights.scss';
@import './retool.scss';
