@import 'project_variables';

.help {
  &__icon {
    cursor: pointer;
    margin-left: 0.3em;
  }
  .ant-popover-inner-content {
    padding: 0;
  }
  &__button {
    min-width: 8em;
  }
}
