@import 'project_variables.scss';

@keyframes flip-in-hor-top {
  0% {
    transform: rotateX(-80deg);
    opacity: 0;
  }
  100% {
    transform: rotateX(0);
    opacity: 1;
  }
}

.register__background {
  min-height: 100vh;
  height: 100%;
  width: 100%;
  background-image: url(../images/bg/bg_login.jpg);
  background-position: center center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  display: flex;
  flex-direction: column;
}

.register__box {
  flex: auto;
  padding-top: 40px;
  padding-bottom: 40px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.register__box-content {
  display: flex;
  flex-flow: column nowrap;
  align-items: stretch;
  overflow: hidden;

  background: $White;
  box-shadow: $LightBoxShadow;
  width: 100%;
  max-width: 520px;
  padding: 40px;

  &__form {
    animation: flip-in-hor-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
    margin-top: 20px;
  }

  &__role-select.ant-radio-group {
    align-self: center;
    margin: 20px 0;

    .ant-radio-button-wrapper {
      height: auto;
      padding-top: 10px;
    }
  }

  .register__error-message {
    margin-bottom: 10px;
  }

  .ant-form-item {
    margin-bottom: 16px;

    .ant-form-item-label {
      line-height: 1.5;
    }
  }

  .register__password-form-item {
    margin-bottom: 8px;
  }
}

.register__box-header {
  text-align: center;
  margin-bottom: 40px;
}

.register--header-content {
  line-height: 1.5;
  padding-bottom: 10px;
  text-align: center;
}

.register-logo {
  background: url(../images/logos/PDM-Logo.svg);
  width: 218px;
  height: 80px;
  margin-left: auto;
  margin-right: auto;
}

.register__large-button {
  white-space: initial !important;
  height: 190px !important;
  width: 190px;
}

.register__footer {
  width: 100%;
  text-align: center;
  margin-bottom: 5px;
  font-size: 0.8em;
  color: $White;
  margin-top: auto;
}

.register__footer-link {
  color: $White;
  margin-left: 20px;
}

.register__success-box {
  background: $White;
  max-width: 470px;
  min-width: 300px;
  box-shadow: $LightBoxShadow;
  padding: 20px;
  text-align: center;
  box-sizing: border-box;
  line-height: 1.5;
}

.register__success {
  font-size: 1.1em;
}

.register__check-icon {
  color: $Green;
  font-size: 7em;
  font-weight: 300;
}

.register__go-to-login {
  color: $Green;
  font-size: 18px;
  text-decoration: none;
}

.register__login-text {
  font-size: 1.1em;
  color: $Blue;
  text-decoration: none;
  line-height: 1.5;

  &:hover {
    text-decoration: underline;
  }
}

.register__link {
  color: $Blue;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

.register__login-container {
  text-align: center;
  margin-top: 20px;
}

.forgot-password {
  margin-top: 40px;
  padding-bottom: 10px;
}

.register__brand-select.ant-select-open {
  .ant-select-selection-item {
    font-size: 18px;

    .text-xs {
      font-size: 14px;
    }
  }

  .register__brand-select-dot,
  .register__brand-select-owner {
    visibility: hidden;
    padding-right: 0;
    width: 0;
  }
}

.register__brand-select-dropdown {
  .ant-select-item {
    padding-top: 3px;
    padding-bottom: 3px;
  }

  .ant-select-item-option-active {
    &:not(:hover) {
      background-color: white;
    }
  }

  .register__add-company {
    padding-left: 11px;
    cursor: pointer;

    &:hover {
      background-color: $LightGrey;
    }
  }

  .register__add-company-icon {
    padding-top: 10px;
    padding-right: 11px;
  }
}

.register__brand-select-dot {
  font-size: 8px;
  line-height: 46px;
  padding-left: 2px;
  padding-right: 14px;
}
