@import 'project_variables.scss';

.synchronisation-container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.synchronisation__tabs {
  display: flex;
  padding-left: 10px;

  .tab {
    padding-right: 20px;
    cursor: pointer;

    &.tab-selected {
      color: $Blue;
    }
  }
}

.synchronisation-content {
  margin: 10px;
  display: flex;
  flex: 1;
  max-height: 100%;
  box-sizing: border-box;
}

.synchronisation-table__container {
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  overflow: hidden;
}

.synchronisation-table {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-height: 85%;
  margin: 3px;
  box-shadow: $LightBoxShadow;
}

.synchronisation-table__header {
  display: flex;
  width: 100%;
  border-bottom: 1px solid $TableBorderGrey;
  background: $LightGrey;
  font-weight: 600;
  flex: 1 0;
  min-height: 40px;
}

.synchronisation-table__body {
  width: 100%;
  overflow: auto;
}

.synchronisation-table__row {
  display: flex;
  flex: 1;
  border-bottom: 1px solid $TableBorderGrey;
  line-height: initial;

  &:hover {
    background: $LightBlue;
    transition: $middle;
  }

  &:last-child {
    border: 0;
  }
}

.synchronisation-table__col {
  padding: 10px;

  &.header-first {
    width: 40px;
  }

  &.col--full {
    flex: 1;
  }

  &.col--fix {
    width: 140px;
    word-wrap: break-word;
  }
}

.synchronisation-table__left {
  flex: 1;
  display: flex;
  align-items: center;
}

.synchronisation-table__right {
  flex: 1;
  display: flex;
  align-items: center;
}

.synchronisation-table__sum {
  height: 40px;
  display: flex;
  align-items: center;
}

.synchronisation-table__actions {
  display: flex;
  flex-flow: row-wrap;
  > *:not(:first-child) {
    margin-left: 10px;
  }
}

.all-products-sync {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: $Green;
  padding: 20px;

  .anticon-cloud {
    font-size: 48px;
    padding-bottom: 10px;
  }

  .all-products-sync__desc {
    font-size: 18px;
  }
}
