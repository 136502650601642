@import 'project_variables.scss';
.shopify-settings__username {
  .ant-btn {
    border: 0;
  }
}
.shopify-warn__exists {
  color: $Red;
}
.shopify-status__steps {
  .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-description {
    color: $Grey !important;
  }
  .ant-steps-item-finish {
    .ant-steps-item-icon {
      border-color: $Green !important;
    }
    .anticon {
      color: $Green !important;
    }
    .ant-steps-item-container > .ant-steps-item-tail::after {
      background-color: $Green;
    }
    .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
      color: $Green !important;
    }
  }
  .ant-steps-item-error > .ant-steps-item-container > .ant-steps-item-tail::after {
    background-color: $Red !important;
  }
}
.shopify_delete-title,
.shopify_connect-account__title {
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  margin-bottom: 8px;
}
.shopify-setting__updating-status {
  color: $Grey;
}
.shopify-settings__info {
  .ant-typography {
    margin-bottom: 4px;
    color: $Grey;
  }
}

.shopify-filter__show-results {
  align-self: flex-end !important;
}
.shopify-settings {
  &__label {
    font-size: 20px;
    line-height: 28px;
    margin-bottom: 24px;
  }
  &__name {
    width: 320px !important;
    height: 36px !important;
  }
  &__divider {
    margin: 40px 0 !important;
  }
  &__secondary-label {
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 0 !important;
  }
  &__url {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: $Grey;
    margin-top: 0;
  }
  &__info {
    width: 800px;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: $Grey;
    margin-left: 4px !important;
  }
  &__success-status {
    color: $Green;
    .anticon {
      width: 13px;
      height: 13px;
    }
  }
  .ant-btn,
  .successful-text {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
  }
}
.shopify_vehicle-filter__search {
  svg {
    color: $Grey !important;
  }
}
.shopify-filter__drawer {
  .ant-drawer-body {
    padding: 0;
  }
  .page-header__content {
    padding: 40px !important;
    background-color: $White;
    overflow: hidden !important;
  }
  .ant-drawer-close {
    margin: 0 !important;
    padding: 25px 10px 0 0 !important;
  }
}
.edit-channel-shopify {
  height: 100%;
  .page-header__content {
    padding: 0 !important;
    background-color: $White;
    overflow: hidden !important;
  }
  .ant-tabs {
    flex: 1;
  }
  .ant-tabs-tab {
    padding-top: 20px;
  }
  .shopify__vehicle-filter-search {
    width: 320px;
  }
  .add-vehicle {
    width: 129px;
  }
  .shopify-filter-table__header {
    border-right: 1px solid $MediumGrey;
  }
  .shopify-filter-table_row {
    .shopify-filter-table_action-buttons {
      visibility: hidden;
      svg {
        color: $IconGrey;
      }
    }
    &:hover {
      background-color: $AntHoverBlue;
      .shopify-filter-table_action-buttons {
        visibility: visible;
        svg {
          color: $IconGrey;
        }
      }
    }
  }
  .shopify-filter-table {
    border: 1px solid $AntTableBorderGrey;
  }
  .shopify-filter-table-col__action {
    border-right: hidden !important;
  }
}

.shopify-modal {
  .ant-modal-header {
    padding: 24px 24px 8px 24px;
    border: 0 !important;
  }
  .ant-modal-body {
    padding: 0;
  }
  .info-text {
    padding: 0 24px;
    font-weight: 400;
    line-height: 20px;
    margin-bottom: 16px;
    color: $FontColor;
  }
  .shopify-user-name {
    margin: 0px 8px;
  }
  .ant-modal-footer {
    border-top: 0;
    padding: 24px;
  }
}

.channel-table__shopify-publish-button {
  width: 98px !important;
}

.channel-table__publish-button.ant-btn {
  width: 88px !important;
}

.edit-channel-table-col__action {
  border-right: hidden !important;
  text-align: right;
}

.edit-channel-table__selector {
  width: 100%;
  svg {
    visibility: hidden;
  }
  &:hover {
    svg {
      visibility: inherit;
    }
  }
}

.channel-advisor-product-data-drawer {
  .ant-drawer-body {
    padding: 0;
  }
}

.edit-channel-table__header {
  border-right: 1px solid $MediumGrey;
}

.edit-channel-table_row {
  &:hover {
    background-color: $AntHoverBlue;
  }
}

.edit-channel-table {
  .ant-select {
    &.ant-select-single {
      .ant-select-selector:not(:hover) {
        border: 1px solid transparent;
      }
      .ant-select-selector:focus-within {
        border: 1px solid $AntBorderGrey;
      }
    }
  }
  border: 1px solid $AntTableBorderGrey;
  .ant-form-item {
    margin: 0;
  }
  .ant-form-item-control-input {
    min-height: 24px;
  }
  .ReactVirtualized__Table__rowColumn {
    .channel-product-sync {
      height: 24px !important;
    }
    .ant-form-item {
      margin: 0 !important;
    }
  }
}

.channel-content__wrapper {
  display: flex;
  flex-flow: row nowrap;
  width: 100%;
}

.channel-table {
  &--full-width {
    width: 100%;
  }
  &-main-header {
    &__push-selected-checkbox.ant-checkbox-wrapper {
      margin-left: 1em;
    }
  }

  .channel__plan-table {
    .ant-table-small {
      border-left: 0;
      border-top: 0;
      border-right: 0;
    }
  }
}

.channel-table-header {
  color: $Blue;
  font-size: 16px;
  border: none;
  min-height: 30px;
  &__profile-info {
    &__text {
      margin-left: 1em;
    }
  }
  &__button-group {
    display: flex;
    flex-flow: row wrap;
    .ant-btn {
      margin: 4px 0 0 4px !important;
    }
    &__button {
      width: auto;
    }

    button:not(:first-child) {
      margin-left: 5px;
    }
    .ant-dropdown-menu-item {
      .ant-btn-sm {
        border-color: $Blue !important;
        span {
          color: $Blue;
        }
      }
    }
  }
  &__popover {
    min-width: 400px;
    .has-error {
      .price-type-select {
        .ant-select-selection {
          border-color: $AntTableBorderGrey;
        }
      }
      .ant-form-item-children-icon {
        width: 20px !important;
        margin-top: 0 !important;
      }
    }

    .ant-popover-inner-content,
    .ant-popover-title {
      padding-left: 20px;
    }

    &-content {
      .ant-form-item {
        margin-bottom: 10px;
      }
      button {
        margin-top: 10px;
        margin-right: 5px;
        &:last-child {
          margin-right: 0px;
        }
      }
    }
    &-buttons {
      display: flex;
    }
  }

  button {
    align-self: right;
  }
}

.channel-table-row--expandable {
  cursor: pointer;
}

.channel-update {
  .ant-dropdown-trigger {
    display: inline-block;
  }

  .anticon-warning {
    color: $Red;
    font-size: 16px;
  }
}

.channel-pies-settings {
  display: flex;
  flex-direction: column;
}

.channel-pies-settings__pricesheet {
  display: flex;
  align-items: center;
  padding-bottom: 5px;
}

.channel-pies-settings__price-type {
  display: flex;
  align-items: center;
}

.channel-pies-settings__title {
  margin-right: 5px;
}

.ant-tag.channel-tag {
  border: 1px solid $Grey;
  background-color: $LightBlue;
}

.channel-no-permission {
  margin: 20px;
  display: flex;
  width: 100%;
}

.channel-no-permission__picture-container {
  width: 40%;
}

.channel__drawer {
  .ant-drawer-body {
    padding-left: 0;
    padding-bottom: 0;
    padding-right: 0;
    padding-top: 0;
    height: calc(100% - 55px) !important;
  }
}

.channel__drawer-wrapper {
  height: 100%;
  overflow: hidden;
}

.channel__drawer-menu-column {
  display: flex !important;
  flex-direction: column;
}

.channel__drawer-menu-item {
  display: inline-block;
  max-width: 90%;
  max-height: 40px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.channel__drawer-menu-search {
  padding: 15px;
  padding-top: 0;
  border-right: 1px solid $AntTableBorderGrey;
}

.channel__drawer-content {
  height: 100%;

  .ant-collapse {
    border: none;
    border-bottom: none;
    background-color: inherit;
    .ant-collapse-item > .ant-collapse-header {
      padding: 0 0 0 40px;
    }
    .antdivider {
      padding-right: 12px;
    }
    .ant-collapse-item {
      border: 1px solid #d9d9d9;
      border-radius: 4px;
      margin-bottom: 10px;
    }
  }
}

.channel__switch-tag {
  padding-right: 10px;
}

.channel__contact-card.ant-card {
  margin-top: 40px;
  margin-left: 20px;
  margin-right: 20px;
}

.channel__change-plan-button {
  margin-right: 20px;
  margin-bottom: 20px;
}

.channel__add-row {
  min-height: 50px;
}

.channel__add-button {
  margin-right: 20px;
  margin-top: 10px;
}

.channel__ebay-settings {
  .ant-form-item {
    margin-bottom: 12px;
  }
}

.channel__ebay__price-select {
  .ant-form-item-children-icon {
    .anticon {
      visibility: hidden;
    }
  }
}

.channel__form-headline {
  margin-bottom: 12px;
  font-size: 16px;
  color: black;
}

.channel__form-plan-headline {
  margin-bottom: 12px;
  font-size: 14px;
  color: $Black;
}

.channel__ebay-cancel-button {
  margin-right: 15px;
  margin-bottom: 10px;
}

.channel__ebay-refresh-button {
  margin-left: 10px;
}

.channel__integrated-tag.ant-tag {
  margin-left: 15px;
  transform: translateY(-10px);
  height: 16px;
  line-height: 14px;
  font-size: 11px;
}

.channel__ebay-description-transfer.ant-transfer {
  margin-bottom: 15px;
}

.channel__ebay-profile-message {
  margin-bottom: 15px;

  .channel__ebay-profile-link {
    padding: 0 4px;
  }
}

.channel__plan-tabs {
  display: flex;
  flex-flow: column;
  height: 100%;
  padding-top: 20px !important;
  padding-left: 20px !important;

  .ant-tabs-tabpane {
    height: 100%;
  }

  .drawer-submit__bottom {
    margin-left: -20px;
  }
}

.channel__plan-form {
  .ant-form-item {
    margin-bottom: 12px;
  }
  .channel__ftp-path {
    &.ant-input {
      margin-bottom: 12px;
    }
  }
  .export-format-options {
    margin: 12px 0 !important;
    .ant-form-item-label {
      font-weight: 400;
      display: inline-grid;
    }
  }
}

.enterprise-info-box {
  margin: 40px;
}

.enterprise-info-list {
  margin: 10px 20px 20px;
}

.channel__whitelabel {
  &-title {
    margin-bottom: 10px;
    padding-left: 10px;
    &-text {
      color: $FontColorDark;
      margin-right: 10px;
    }
  }

  &--disabled {
    color: $Grey;
  }
}

.channel__plan-whitelabel {
  label {
    width: 100%;

    .ant-switch {
      margin-top: 2px;
    }
  }
}

.channel__plan-whitelabel-switch {
  text-align: right;

  span {
    margin-right: 5px;
  }

  .ant-switch {
    margin: 0 !important;
  }
}

.channel__whitelabel-info {
  width: 500px;
}

.channel__ftp-folder {
  &.ant-form-item.ant-row {
    padding-bottom: 3px;
  }
}

.channel__ftp-path {
  &.ant-input {
    margin-bottom: 24px;
  }
}

.channel__card-container > .ant-tabs-card .ant-tabs-content {
  margin-top: -16px;
  margin-bottom: 20px;
  .export-format-options {
    margin: 12px 0 !important;
    .ant-form-item-label {
      font-weight: 400;
      display: inline-grid;
    }
  }
}

.channel__card-container > .ant-tabs-card .ant-tabs-content > .ant-tabs-tabpane {
  padding: 16px;
  border: 1px solid $AntTableBorderGrey;
  border-top: 0;
}

.channel__default-plans {
  max-height: 400px;
  border: 1px solid $AntBorderGrey;
  border-radius: 4px;
  margin-bottom: 20px;

  .ReactVirtualized__Table__headerRow.channel__default-plans-row {
    background: $White;
  }

  .channel__default-plans-header {
    text-transform: none;
  }

  .channel__default-plans-row {
    border-bottom: 1px solid $AntBorderGrey;

    &:last-of-type {
      border-bottom: none;
    }
    .ReactVirtualized__Table__rowColumn {
      overflow: visible !important;
    }
  }
}

.channel__naming-convention {
  .ant-form-item {
    margin-bottom: 12px;
  }
}

.channel__naming-convention-button {
  margin-left: 20px;
  margin-bottom: 12px;
}

.channel__naming-convention-list {
  border: 1px solid $AntBorderGrey;
  border-radius: 2px;
}

.channel__naming-convention-add {
  &.ant-form-item {
    margin-bottom: 3px;
  }

  .ant-input-group-addon {
    padding: 0;
  }

  .ant-dropdown-trigger {
    height: 30px;
  }
}

.channel__naming-convention-list {
  .ReactVirtualized__Grid__innerScrollContainer {
    overflow: visible !important;
  }
}

.channel__naming-convention-row {
  border-bottom: 1px solid $AntBorderGrey;
  overflow: visible;

  .ant-input-affix-wrapper {
    border: 1px solid transparent;
  }

  .ant-input-affix-wrapper:hover {
    border: 1px solid $AntBorderGrey;
  }

  .borderVsible {
    .ant-input-affix-wrapper {
      border: 1px solid $AntBorderGrey;
    }
  }

  .channel__naming-convention-add {
    .ant-input-group-addon {
      border: 1px solid transparent;
      border-left: 0;
      background: white;
      .ant-btn-text {
        color: transparent;
      }
    }

    &:hover,
    &:focus-within,
    &.borderVsible {
      .ant-input-group-addon {
        border: 1px solid $AntBorderGrey;
        border-left: 0;
        background: $AntTableHeaderGrey;
        .ant-btn-text {
          color: $FontColor;
        }
      }
      .ant-input-affix-wrapper {
        border: 1px solid $AntBorderGrey;
      }
    }
  }
}
