@import 'project_variables.scss';

.fill-blue svg {
  color: $Blue;
}
.fill-grey svg {
  color: $Grey;
}
.table-filter-popover {
  min-width: 300px;

  .ant-popover-inner-content {
    padding: 0;
  }
  .ant-btn-background-ghost {
    background: $White !important;
  }
  .ant-card-body {
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 10px;
    padding-top: 0;
  }
  .table-popover-search {
    padding: 10px;
    padding-right: 10px;
  }
  .table-filter-popover__search-table {
    border-bottom: 1px solid $AntTableBorderGrey;
  }
  .table-filter-popover__search-table_row {
    padding-left: 10px;
    cursor: pointer;

    &:hover {
      background-color: $LightBlue !important;
    }
  }
}

.table-sorter {
  height: 22px;
  svg {
    height: 11px;
  }
}

.table-filter-blue {
  svg {
    color: $Blue;
  }
}

.pdm-table {
  border: 1px solid $Grey;
  overflow: auto;
}

.pdm-table .row:nth-of-type(even) {
  background-color: $White;
}

.pdm-table .row:nth-of-type(odd) {
  background-color: $LightGrey;
}

.row {
  line-height: 30px;
  min-height: 30px;
  position: relative;
  user-select: none;
}

.row__choose-button {
  width: 65px;
  height: 20px;
  line-height: normal;
  margin-left: 5px;
}

.table--full-width {
  width: 100%;
}

.ag-group-value {
  flex: 1;
}
