@import 'project_variables';
.sources__info-text {
  max-width: 845px;
  min-width: 600px;
  margin-top: 26px;
  width: 50%;
}

.sources__ftp-credentials {
  margin-top: 26px;
  .ant-card-body {
    padding: 12px;
  }
  .ant-typography {
    width: 100%;
    margin-bottom: 0;
  }
  .ant-typography-copy {
    float: right;
  }
}

.sources__auth-wrapper {
  .source__password {
    margin-right: 12px;
  }
  .source__action-buttons {
    margin-top: 25px;
  }
  .ant-form-item {
    margin-bottom: 10px;

    &:first-of-type {
      margin-right: 10px;
    }
    .ant-form-item-label {
      font-weight: 500;
      line-height: initial;
    }
  }

  .sources__auth-token {
    min-width: 600px;
  }
}

.sources__connection-info-box {
  border: 1px solid $AntBorderGrey;
  border-radius: 2px;
  min-width: 600px;
}

.sources__connection-info-label {
  padding-left: 5px;
  width: 250px;
}

.sources__connection-info-row {
  border-bottom: 1px solid $AntBorderGrey;
  min-height: 40px;
  line-height: 40px;

  &:last-of-type {
    border-bottom: none;
  }
}
