@import 'project_variables';

.digital-asset__file-input {
  display: none;
}

.digital-assets__description-editor {
  border: 1px solid $Grey;
}

.digital-assets__add-container {
  display: flex;
  flex-direction: column;
  position: relative;
  background: $White;
  padding: 15px;
  height: 100%;
  width: 60vw;
  max-height: 90vh;
  overflow: hidden;
}

.digital-assets__add-title-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.digital__assets-submit-error {
  color: $Blue;
}

.digital-assets__inner-container {
  border: 1px solid $Grey;
  display: flex;
  justify-content: space-evenly;
  padding: 10px;
  max-height: 100%;
  overflow: hidden;
}

.digital-assets__preview-image {
  flex: 1;
  padding-right: 10px;
  display: flex;
  flex-direction: column;

  img {
    flex: 1;
    border: 1px solid $Grey;
    object-fit: contain;
    max-width: 100%;
    max-height: 100%;
  }
}

.digital-assets__preview-title {
  margin: 0;
  color: $Blue;
  text-align: start;
}

.digital-assets__options {
  flex: 3;
  display: flex;
  flex-direction: column;
  text-align: left;
}

.digital-assets__add-source {
  margin-bottom: 10px;
}

.digital-assets__add-source-title {
  margin: 0;
  text-align: left;
  color: $Blue;
  padding: 5px 0;
}

.digital-assets__add-source-input-wrapper {
  width: 100%;
  display: inline-flex;
  border-bottom: 1px solid $Grey;
  box-shadow: -2px 1px 2px 1px $Grey;
  line-height: initial;
}

.digital-assets__add-source-input {
  padding: 5px;
  display: table-cell;
  font-size: medium;
  font-style: italic;
  box-sizing: border-box;
  width: 100%;
  border: none;

  &_error {
    @extend .digital-assets__add-source-input;
    border: 1px solid $LightRed;
  }
}

.digital-assets__file-name {
  display: inline-flex;
  padding: 10px 0;
}

.digital-assets__file-name-input {
  flex: 1;
  border: 1px solid $Grey;
}

.digital-assets__file-type-select {
  margin-left: 10px;
  width: 25% !important;
  border: 1px solid $Grey;
  padding: 2px 5px;
}

.digital-assets__file-title {
  width: 20%;
}

.digital-assets__asset-type {
  display: inline-flex;
  justify-content: space-between;
}

.digital-assets__asset-type-title {
  width: 20%;
}

.digital-assets__asset-type-selection {
  display: flex;
  flex-direction: column;
}

.digital-assets__asset-type-label-wrapper {
  padding: 5px 0;
  display: inline-flex;
  justify-content: space-between;
}

.digital-assets__asset-type-label {
  font-size: 14px;
  border: 1px solid $Grey;
  border-left: 3px solid $Blue;
  margin-right: 5px;
  margin-bottom: 5px;
  padding: 1px 2px;
  cursor: pointer;

  &_selected {
    @extend .digital-assets__asset-type-label;
    background-color: $LightGreen;
  }
}

.digital-assets__representation {
  display: inline-flex;
  justify-content: space-between;
  padding-bottom: 10px;
}

.digital-assets__buttons {
  align-self: flex-end;
  display: flex;
  justify-content: space-between;
  width: 120px;
  height: 30px;
}

.digital-assets__import-error {
  padding: 20px;
  text-align: left;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  p {
    line-height: 20px;
    margin: 0;
  }
}

.digital-assets__import-error-title {
  padding-left: 0;
}

.import-error__inner-container {
  border: 1px solid $Grey;
  height: auto;
  padding: 20px;
  flex: auto;
  overflow: auto;
}

.import-error__inner-container-top {
  margin-bottom: 20px;
}

.import-error__footer {
  margin-top: 10px;
}

.import-error__inner-container-bottom {
  display: flex;
}

.import-error__existing-asset-image {
  width: 200px;
  border: 2px solid $Blue;
  margin-right: 20px;
  padding: 2px;

  img {
    max-width: 100%;
    max-height: 100%;
  }
}

.image-not-supported {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-width: 40px;
  height: 100%;
  max-height: 300px;
  min-height: 40px;
  background-image: url('../images/icons/data-type.svg');
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
}

.image-not-supported__text {
  color: $White;
  transform: translateY(50%);
}

.import-error__mapped-items {
  width: 100%;
}

.import-error__mapped-items-table {
  height: 90px;
  border-left: 3px solid $Blue;

  .import-error__mapped-items-row {
    padding-left: 5px;
  }
}

.file-preview-popup {
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.62);
  z-index: 500;
}

.file-preview-popup__content {
  position: relative;
  margin: 0 auto;
  width: 70%;
  height: 90%;
  background-color: $White;
  top: 5%;
  box-shadow: 0 4px 6px 0 hsla(0, 0%, 0%, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
}

.file-preview-popup__close {
  position: absolute;
  top: -8px;
  right: -8px;
  cursor: pointer;
  font-size: 0.7rem;
  border: 1px solid $Blue;
  border-radius: 15px;
  background: $White;
  padding: 3px;
  color: $Blue;

  &:hover {
    color: $White;
    background-color: $Blue;
  }
}

.file-preview-popup__pdf {
  width: 100%;
  height: 100%;
}

.file-preview-popup__img {
  max-width: 100%;
  max-height: 100%;
}

.file-preview-popup__video {
  max-width: 100%;
  max-height: 100%;
}

//DigitalAssetContainer
.digital-assets-container {
  display: flex;
  height: 100%;
  box-shadow: $LightBoxShadow;
}

.digital-assets__parent-drawer {
  .ant-drawer-body {
    padding: 0;
  }
}

.digital-asset__box-right {
  display: flex;
  flex: 1;
  margin-left: 20px;
}

// DigitalAssetUploader
.digital-asset-uploader {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding: 10px;
  flex-basis: 20%;
}

.digital-asset__upload-button {
  box-sizing: border-box;
  cursor: pointer;
  padding: 5px 10px;
  margin: 5px 0;
  border: 1px dashed $Blue;
  color: $Blue;
  font-weight: 600;
  display: flex;
  align-items: center;

  &:hover {
    background: $Blue;
    color: $White;
  }

  .add-section__text {
    margin-left: 5px;
  }
}

.digital-asset__drop-zone-box {
  border: 1px dashed $Blue;
  color: $Blue;
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  font-weight: 600;
  margin-top: 5px;
}

// DigitalAssetsViewer
.digital-asset-viewer {
  flex: 1;
  padding: 10px;
}

.digital-asset-viewer__box-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

.digital-asset-box {
  flex-basis: 31%;
  min-width: 200px;
  height: 200px;
  position: relative;
  border: 1px solid $LightGrey;
  margin: 0 5px 10px;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  cursor: pointer;

  @media (max-width: 1541px) {
    flex-basis: 48%;
  }

  @media (max-width: 1377px) {
    flex: 1;
  }

  &--selected {
    @extend .digital-asset-box;
    border: 1px solid $Blue;

    .digital-asset-box__top {
      &.preview {
        &:hover {
          cursor: zoom-in;
        }
      }
    }
  }

  &--invalid {
    @extend .digital-asset-box;
    border: 1px solid $Red;
    cursor: pointer;

    .info-icon {
      color: $Red;
    }
  }
}

.digital-asset-box__delete {
  box-sizing: border-box;
  position: absolute;
  right: -6px;
  top: -6px;
  font-size: 10px;
  color: $Blue;
  background-color: $White;
  border: 1px solid $Blue;
  border-radius: 10px;
  padding: 1.5px;
  vertical-align: middle;
  cursor: pointer;
  &:hover {
    background-color: $Blue;
    color: $White;
  }
}

.digital-asset-box__top {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  overflow: hidden;

  img {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
  }
}

.digital-asset-box__bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px;
  box-sizing: border-box;
  font-weight: 500;
  font-size: 16px;
  border-top: 1px solid $LightGrey;

  &__icons {
    margin-left: auto;
    > span:not(:last-child) {
      margin-right: 2px;
    }
  }
}

.digital-asset-box__actions {
  box-sizing: border-box;
  height: 35px;
  background-color: $LightGrey;
  color: $IconGrey;
  padding: 6px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  border-top: 1px solid $Grey;
}

// DigitalAssetInformation
.digital-asset-information {
  border-left: 1px solid $Grey;
  display: flex;
  flex-direction: column;
  flex-basis: 30%;
  box-sizing: border-box;
  min-width: 300px;
  line-height: initial;
}

.digital-asset-information__item {
  padding: 10px;
}

.digital-asset-information__asset {
  border-top: 1px solid $Grey;
  padding: 10px;
}

.digital-asset-information__description {
  padding: 0 10px 10px 10px;
}

.form-field {
  display: flex;
  align-items: center;
  margin-bottom: 10px;

  &.form-field > * {
    margin-bottom: 5px;
  }

  .form-field-name {
    margin-right: 10px;
    flex: 1;
    overflow: hidden;
  }

  .form-field-content {
    box-sizing: border-box;
    flex: 2;
    flex-wrap: nowrap;
    align-self: flex-start;
    border: 1px solid $Grey;
    padding: 2px 5px;
  }

  &.vertical {
    flex-direction: column;
    align-items: stretch;
  }

  .digital-asset-information__dropdown {
    width: 100%;
    padding: 0 3px;
    border: 1px solid $Grey;
    box-sizing: border-box;
  }

  &:last-child {
    margin-bottom: 0;
  }
}

.digital-asset-information__top {
  margin-bottom: auto;
}

.digital-asset-information__bottom {
  padding: 20px;
  border-top: 1px solid $Grey;
}

.digital-asset-information__picture-info {
  font-size: 14px;
  padding-bottom: 10px;
  color: $Grey;
}

.digital-asset-information__description-dropdown {
  width: 50%;
  padding: 0 3px;
  border: 1px solid $Grey;
  box-sizing: border-box;
}

.digital-asset-information__description-title {
  margin-bottom: 5px;
}

.digital-assets__description-delete-button {
  display: flex;
  font-size: 14px;
  position: absolute;
  right: -7px;
  top: -7px;
  border: 1px solid $Blue;
  color: $Blue;
  background-color: $White;
  border-radius: 10px;
  cursor: pointer;
  &:hover {
    color: $White;
    background-color: $Blue;
  }
}

.digital-assets__description-editor-container {
  position: relative;
  margin: 20px 0;
  padding: 20px;
}

.digital-asset-exists__options {
  display: flex;
  align-items: center;
}

.digital-asset-exists__option {
  height: 30px;
  margin-right: 20px;
  min-width: 80px;
}

.new-file-name {
  display: flex;
}

.new-file-name-input__container {
  margin-right: 20px;
  display: flex;
  align-items: center;
  line-height: 30px;
  input {
    margin-left: 5px;
    margin-right: 5px;
    border: 1px solid $Grey;
  }
}

.digital-asset-exists__cancel {
  margin-left: auto;
  height: 30px;
  width: 80px;
}
