@import 'project_variables.scss';

.pkg-form-select {
  .ant-form-item-control-input {
    max-width: 360px;
    .ant-select {
      width: 360px !important;
    }
  }
}

.package__hz-form {
  &.ant-form {
    margin-bottom: 10px;
  }

  .ant-form-item-label {
    padding-left: 50px !important;
  }
}

.package__input-group-wrapper {
  .package__dimension-input-split {
    background-color: $White !important;
  }
  .package__dimension-select {
    &.ant-select {
      width: 145px !important;
    }
  }
  .site-input-right {
    border-left-width: 0;
  }
  .site-input-right:hover,
  .site-input-right:focus {
    border-left-width: 1px;
  }
}

.package-start-edit {
  margin-top: 80px;
  text-align: 'center';
  font-size: 15px;
  img {
    width: 300px;
    height: 200px;
  }
}

.package-page-empty {
  margin-top: 60px !important;
}

.package-description {
  max-width: 600px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 0 4px;
  height: 22px;
}

.package-border-description {
  height: 24px;
  &:hover {
    border: 1px solid $InputFocusBlue;
  }
}

.package_popover {
  .ant-btn-background-ghost {
    background: $White !important;
  }
}
.package-popover__form {
  min-width: 400px;
  .ant-card-body {
    padding: 8px;
  }
}

.package-form {
  background-color: $White;

  .ant-form-item-label {
    padding-left: 18px;
  }

  .package__borderless-input {
    &.ant-input-affix-wrapper {
      border-color: transparent !important;
      &:hover {
        border: 1px solid $InputFocusBlue !important;
      }
    }
    &:hover {
      .ant-input-affix-wrapper {
        border: 1px solid $InputFocusBlue !important;
      }
    }
  }

  .package__borderless-select {
    &:hover {
      .ant-select-selector {
        border: 1px solid $InputFocusBlue !important;
      }
    }
  }

  .ant-form-item-has-error
    .ant-input-group-addon
    .ant-select.ant-select-single:not(.ant-select-customize-input)
    .ant-select-selector,
  .ant-btn-link {
    height: 20px;
  }

  .package__input-group-background {
    .ant-select-selector {
      background-color: $LightGrey !important;
    }
  }

  .package__input-group {
    .ant-input,
    .ant-input-affix-wrapper {
      border-top-right-radius: 0 !important;
      border-bottom-right-radius: 0 !important;
    }
    .ant-select-selector {
      background-color: $LightGrey !important;
      border-top-left-radius: 0 !important;
      border-bottom-left-radius: 0 !important;
    }

    .ant-form-item-control-input {
      .ant-input-affix-wrapper {
        width: 120px;
      }
      .ant-select {
        width: 240px;
      }
    }

    &.ant-form-item-has-error {
      .ant-input {
        width: 100%;
      }
    }

    &:hover {
      .ant-input,
      .ant-select-selector,
      .ant-input-affix-wrapper {
        border-color: $InputFocusBlue !important;
      }
    }
  }

  .package-border-transparent {
    .ant-select-selector {
      border-color: transparent !important;
      background-color: $White !important;
    }
    .ant-select-selection-item {
      background-color: $White !important;
    }
    input,
    .ant-input-affix-wrapper {
      border-color: transparent !important;

      .ant-input-suffix {
        margin-left: 0;
      }
    }
  }

  .ant-form-item-label > label {
    height: 20px;
    &::after {
      content: '';
    }
  }
  .ant-form-item-control-input {
    min-height: 40px;
  }

  .package__suggest-button {
    .ant-input-affix-wrapper,
    .ant-btn-dashed {
      width: 360px;
    }
  }
  .ant-form-item {
    margin: 0 !important;
    min-height: 40px;
    align-items: center;
  }
  .ant-form-item-row {
    align-items: center;
  }
  .ant-divider-horizontal {
    margin: 2px 0px;
  }
}

.package-table {
  border-top: 1px solid $AntTableBorderGrey;
  .package-type {
    font-weight: 700;
  }
  .package-sub-fields {
    margin-left: 25px !important;
    .package__add-hz {
      margin: 8px 0;
    }
    .hz-edit-info {
      padding: 9px 40px 9px 20px;
    }
  }
  .ant-table-cell {
    padding-right: 0 !important;
  }
}

.hz-table {
  margin-bottom: 33px;
  .hz-sub-fields {
    margin: 0;
  }
  .ant-table-cell {
    padding-left: 0 !important;
  }
  .ant-table-row-level-0 {
    .ant-table-row-expand-icon-cell {
      padding-left: 20px !important;
    }
  }
  .ant-table-expanded-row {
    .ant-table-cell {
      padding-top: 0 !important;
      padding-bottom: 0 !important;
    }
  }
}

.package__drawer-list-row {
  display: grid;
  grid-template-rows: 40px;
  grid-template-columns: minmax(150px, 200px) minmax(260px, 400px) minmax(350px, 1fr);
  padding: 0 10px;
  grid-column-gap: 10px;
  border-bottom: 1px solid $AntTableBorderGrey;
  align-items: center;

  &_data {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  &_text {
    text-align: right;
  }
}

.package__drawer {
  border: 1px solid $AntTableBorderGrey;
  border-radius: 4px;

  .ant-form-item {
    margin-bottom: 0;
  }
}

.package-drawer__overwrite-wrapper {
  .ant-btn-primary {
    border-left: 0px;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
  }
  .ant-select-selector {
    border-right: 0px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
  }
  &:hover {
    .ant-select-selector,
    .ant-btn-primary {
      border-color: $InputFocusBlue;
    }
  }
}

.package__analyses-alert-icon {
  padding: 0 !important;
}
