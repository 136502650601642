@import 'project_variables.scss';

.price-item-table__date-picker {
  .ant-picker {
    border: 1px solid transparent;
  }
  .ant-picker-suffix {
    visibility: hidden;
  }
  .ant-picker:hover {
    .ant-picker-suffix {
      visibility: visible;
    }
  }
}

.price-item-table__select {
  .ant-select {
    width: 100%;
  }
  &.border-invisible {
    .ant-select {
      &.ant-select-single {
        .ant-select-selector:not(:hover) {
          border: 1px solid transparent;
        }
        .ant-select-selector:focus-within {
          border: 1px solid $AntBorderGrey;
        }
      }
    }
  }
}

.price-item-table__form-fields {
  .ant-form-item {
    margin: 0;
  }
  .ant-form-item-control-input {
    min-height: 21px;
  }
  .price-item-table__input {
    .ant-input-affix-wrapper {
      border: 1px solid transparent;
      padding-right: 0;
    }
    .ant-input-affix-wrapper:hover {
      border: 1px solid $AntBorderGrey;
    }
    .border-visible {
      .ant-input-affix-wrapper {
        border: 1px solid $AntBorderGrey;
      }
    }
  }
}

.price-sheet-drawer__list-header {
  padding: 12px;
  background-color: $AntTableHeaderGrey;
  border-bottom: 1px solid $AntTableBorderGrey;
}
.price-sheet-drawer__list-values {
  padding: 12px;
  border-bottom: 1px solid $AntTableBorderGrey;
}

.input-wrapper__delete {
  width: 62px;
  padding: 0 12px;
}
.input-wrapper__delete-input {
  width: 350px;
  display: flex;
}

.price-sheet-drawer {
  .ant-picker-small {
    width: 100%;
  }
  .price-sheet__form-no-spacing.ant__divider {
    margin-top: 0;
  }
}

.price-item-table {
  .ant-table-container .ant-table-thead .ant-table-cell {
    padding-left: 20px;
    font-weight: 700;
  }
  .ant-table-container .ant-table-tbody .ant-table-cell {
    padding-left: 20px;
  }
  .input-with-preview {
    padding: 0;
    min-height: 24px;
    &:hover {
      border-radius: 4px;
      padding: 0 4px;
      margin-right: 14px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  .select-with-preview {
    padding: 0;
    min-height: 24px;
    &:hover {
      margin-right: 4px;
      border-radius: 4px;
      padding: 0 4px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
