@import 'project_variables.scss';
.page__modal-wrapper {
  overflow: hidden !important;
  .ant-modal-body {
    padding: 0;
  }
}
.page__modal {
  .ant-modal-wrap {
    overflow: hidden;
  }
  height: 100%;
  top: 10px !important;
  .ant-modal-content {
    height: 100%;
    overflow: hidden;
  }
  .ant-modal-body {
    height: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
  }
}

.page {
  height: 100%;
  width: 100%;
  overflow: hidden;

  &__header {
    padding: 2px;
    border-bottom: 1px solid $AntBorderGrey;
    height: 40px;
    .ant-btn {
      margin-left: auto;
    }
  }
  &__expand-button {
    border-bottom: 1px solid $AntBorderGrey;
  }

  &__content {
    flex: 1;
    overflow: auto;
    padding: 20px;

    &.no-spacing {
      padding: 0;
    }

    &.no-scroll {
      overflow: hidden;
    }
  }

  &__bottom {
    border-top: 1px solid $AntBorderGrey;
    width: 100%;
    position: relative;

    .status-bar {
      padding-left: 12px;
      width: 0px;
      position: absolute;
      transform-origin: left;
      transition: width 0.4s ease-out;
      background-color: $LightBlue;

      &.success {
        background-color: $LightGreen;
        width: 100%;
        height: 100%;
      }

      &.error {
        background-color: $LightRed;
        width: 100%;
        height: 100%;
      }
    }

    &-save {
      width: 100%;
      padding: 12px;
      background-color: $LightBlue;

      &-cancel {
        margin: 0 10px;
      }
    }
  }
}

.page-header {
  height: 100%;
  width: 100%;
  background-color: $LightGrey;
  overflow: hidden;

  .ant-tabs {
    overflow: hidden;
  }
  .ant-tabs-content {
    display: flex;
  }
  .ant-tabs-nav {
    margin: 0 !important;
  }

  .ant-divider {
    margin-bottom: 0;
  }

  .page-header__article-link {
    padding: 0;
    margin-top: 8px;
    &.tabs-mode {
      margin-bottom: 20px;
    }
  }

  .ant-tabs-tab {
    margin-left: 40px;
  }

  .ant-tabs-tabpane {
    display: flex;
    width: 100%;
  }

  .page-header__content {
    flex: 1;
    overflow: auto;
    padding-bottom: 20px;
    padding-top: 40px;
  }
}

.page-header__details {
  width: 100%;
}

.page-header__title {
  font-size: 32px;
  height: 40px;
  font-weight: 500;
}

.page-header__description {
  font-size: 14px;
  height: 20px;
  margin-top: 8px;
}
