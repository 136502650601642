@import 'project_variables.scss';

.brand-managing__table {
  .ant-form-item-has-error {
    .ant-form-item-control-input {
      min-height: 24px;
    }

    .ant-form-item-explain {
      font-size: 11px;
      height: 15px;
    }
  }
}

.brand-managing__table-row {
  .ant-form-item {
    margin-bottom: 0;
    padding-left: 2px;
    padding-right: 2px;
  }

  .ant-input-disabled,
  .ant-input-affix-wrapper-disabled {
    background-color: $White !important;
    border-color: transparent;
    color: $FontColor !important;
  }

  .border-invisible {
    input:not(:hover),
    .ant-input-affix-wrapper:not(:hover) {
      border-color: transparent;
    }
  }
}
