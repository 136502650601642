@import 'project_variables.scss';
.custom-vcdb-page__config {
  .ant-form-item-label {
    width: 100%;
    text-align: initial !important;
    display: contents;
  }
  .ant-select-multiple .ant-select-selection-item {
    background-color: $LightBlue;
    border-color: $Blue;
    color: $Blue;
  }
  .ant-form-item-required {
    padding-bottom: 8px;
  }
}

.vcdb-import-modal {
  .ant-upload.ant-upload-select-picture-card {
    width: 100%;
  }
  .background-icon-excel {
    opacity: 0.1;
    font-size: 4em;
  }
}
