@import 'project_variables.scss';

.xml-header-settings-page {
  .ant-tabs-content-holder {
    height: 100%;
    border: 1px solid $AntTableBorderGrey;
    margin-bottom: 16px;
  }
  .ant-tabs {
    height: 100%;
  }
  .version-icon.anticon svg {
    font-size: 20px;
  }
  .anticon-close-circle {
    color: $Red;
  }
  .green {
    color: $Green;
  }
  .yellow {
    color: $Yellow;
  }
}

.settings-container {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.settings-content {
  padding: 20px;
  border: 1px solid $Grey;
  flex: 1;
  overflow: auto;
}

.settings__row {
  margin-bottom: 10px;
}

.settings__row-text {
  width: 200px;
  display: inline-block;
}

.settings__input {
  width: 400px;

  &:disabled {
    color: $Grey;
  }
}

.settings__update-layer {
  position: fixed;
  height: 100vh;
  width: 100vw;
  background: black;
  opacity: 0.4;
  top: 0;
  left: 0;
  z-index: 40;
}

.settings__update-layer-info {
  position: relative;
  font-size: 2em;
  top: 50%;
  margin: 0 auto;
  color: $White;
  text-align: center;
}

.settings__area-container {
  display: inline-block;
  position: relative;
  margin-bottom: 20px;
}

.settings__save-button,
.settings__dismiss-button {
  position: absolute;
  right: 0;
  background-color: $Blue;
  border: none;
  color: $White;
  cursor: pointer;
}

.settings__dismiss-button {
  right: 50px;
}

.settings__edit {
  position: absolute;
  font-size: 0.8em;
  color: $Grey;
  user-select: none;
  cursor: pointer;
  margin-left: 20px;
}

.setting-custom-field__outer-wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.settings-custom-field {
  margin: 1.25rem 0;
  margin-right: 1px;
  box-shadow: $LightBoxShadow;
  transition: all 200ms ease;
}

.settings-custom-field__wrapper {
  height: 100%;
  overflow: auto;
}

.settings-custom-field__container {
  display: flex;
  align-items: center;
  padding: 0 1rem;
  height: 3rem;
  border-left: 5px solid $Blue;
  transition: 200ms;

  &.temp {
    background-color: $Blue;
    color: $White;
  }
}

.settings-custom-field__selection-wrapper {
  display: flex;
  flex: 1;
}

.settings-custom-field__info-text {
  padding-right: 0.5rem;
}

.settings-custom-field__name-wrapper {
  padding-right: 1rem;
  width: 30%;
  display: flex;

  input {
    width: 100%;
  }
}

.settings-custom-type__type-wrapper {
  padding-right: 1rem;
}

.settings-custom-field__extended {
  padding: 1.25rem;
}

.settings-custom-field__extended-title {
  color: $Blue;
}
.settings-custom-field__extended-options {
  border: 1px solid $Grey;
  box-shadow: 0 4px 3px -3px #ccc;
}

.settings-custom-field__extended-add {
  padding: 0.3125rem 1.25rem;
}

.setting-custom-field-option {
  display: flex;
  padding-left: 1.25rem;

  &:nth-child(even) {
    background-color: $LightGrey;
  }

  &.tempOption {
    background-color: $Blue !important;
  }
}

.settings-custom-field-option__name {
  flex: 1;
  display: flex;
  align-items: center;

  .settings-custom-field-option__input {
    width: 100%;
    max-width: 26.5rem;
  }
}

.settings-custom-field-option__options {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 2.5rem;
}

.settings-custom-field-option__delete {
  display: flex;
  align-items: center;
  height: 100%;
  padding: 0 0.5em;
  border-left: 1px solid $Grey;
  & span {
    font-size: 12px;
    cursor: pointer;
    color: $IconGrey;
  }
}

.settings-custom-field-option__arrows {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 0.3em;
  border-left: 1px solid $Grey;
  height: 100%;

  span {
    font-size: 20px;
    cursor: pointer;
    color: $IconGrey;
  }
}

.custom-field__value_missing {
  border: 2px solid $LightRed !important;
}

.settings-scorecard {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.settings-scorecard__title-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.setting-scorecard__title-left {
  flex: 1;
}

.setting-scorecard__title-right {
  display: flex;
}

.settings-scorecard__update-button {
  margin-right: 10px;
}

.settings-scorecard__brand-dropdown {
  border: 1px solid $Grey;
  padding: 0 5px;
}

.settings-scorecard__info {
  margin-bottom: 20px;
}

.settings-scorecard__selection {
  display: flex;
  flex: 1;
  height: 100%;
  overflow: hidden;
}

.settings-scorecard_left {
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-right: 20px;
}
.settings-scorecard_middle {
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-right: 20px;
}
.settings-scorecard_right {
  flex: 2;
  display: flex;
  flex-direction: column;
}

.settings-scorecard__table {
  box-shadow: $LightBoxShadow;
  margin: 2px;
  height: 100%;
  overflow: auto;
}

.settings-scorecard__row {
  display: flex;
  align-items: center;
  padding: 0 10px;

  &:nth-child(odd) {
    background-color: $LightGrey;
  }

  &:last-child {
    border: none;
  }

  &:hover {
    background-color: $LightBlue;
  }

  .anticon-warning {
    &.blue {
      color: $Blue;
    }
    &.yellow {
      color: $Yellow;
    }
    &.red {
      color: $Red;
    }
  }

  &.settings_scorecard__segment-row {
    border-left: 3px solid #188ca6;
    color: #188ca6;
  }
}

.settings-scorecard__switch {
  display: flex;
  margin-left: auto;
}

.settings-scorecard__select-all-alerts {
  display: flex;
  margin-left: auto;
  padding-right: 10px;
  align-items: center;

  .select-all-alerts__title {
    margin-right: 5px;
  }
}

.settings-scorecard__alert-types-container {
  margin-bottom: 20px;
}

.settings-scorecard__scorecards-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-top: 20px;
}

.settings-scorecard__validating-info {
  margin-left: auto;
  color: $Grey;
  font-size: 0.8em;

  .anticon-check-circle {
    font-size: 14px;
    color: $Green;
  }
}

.settings-default {
  height: 100%;
  width: 100%;
  overflow: scroll;

  .ant-form-item {
    margin-bottom: 12px;
  }
}

.setting-price-type-description {
  margin: 10px 5px;
}

.setting-price-type-description__sheets {
  display: flex;
  flex-wrap: wrap;
  padding-top: 10px;
}

.setting-price-type-description__row {
  display: flex;
  & > * {
    margin-right: 10px;

    &:last-child {
      margin: 0;
    }
  }
}
