@import 'project_variables.scss';

.item-page {
  .ant-form-item {
    margin-bottom: 8px;
  }
  .ant-form-item-label > label::after {
    display: none;
  }
  .ant-input-number {
    width: 100%;
  }
  .ant-input-group > .ant-input:last-child .ant-select .ant-select-selection,
  .ant-input-group-addon:last-child .ant-select .ant-select-selection:hover {
    border: 1px solid $InputFocusBlue;
    border-radius: 0;
  }

  .ant-input-group-addon {
    .ant-select-focused {
      border: 1px solid $InputFocusBlue;
      border-radius: 0;
      box-shadow: 0 0 2px rgba(24, 140, 166, 0.2);
    }
  }
}

.part-type-draw-title {
  .ant-drawer-title {
    font-weight: 0;
  }
}

.selected {
  background-color: $Blue !important;
  color: white !important;
}

.items__wrapper {
  height: 100%;
  width: 100%;

  .filter {
    padding-bottom: 5px;
    width: 100%;
  }
}

.item__top-line {
  display: flex;
  align-items: center;
}

.top-line-dates__icon {
  color: $IconGrey;
  font-size: 25px;
  top: 5px;
  position: relative;
  margin-right: 10px;
}

.top-line-dates__text,
.top-line-dates__time {
  margin-right: 20px;
}

.top-line-dates__name {
  margin-right: 40px;
}

.top-line-dates__date,
.top-line-dates__separator {
  margin-right: 5px;
}

.top-line-dates__name,
.top-line-dates__date,
.top-line-dates__time,
.top-line-dates__separator {
  color: $Grey;
}

.top-line-dates__created-by {
  margin-right: 20px;
}

.top-line-dates__changed-by {
  margin-right: 20px;
}

.item__top-line-dates {
  display: flex;
  align-items: center;
  flex: 1;
}

.top-line__button-container {
  display: flex;
  align-items: center;
}

.item__title-container {
  height: 30px;

  .header__filter-dropdown {
    display: inline-block;
    float: none;
  }
}

.item__title {
  display: inline-block;
  color: $Blue;
}

.item__mapping_area {
  height: 75%;
  padding: 20px;

  input {
    border: none;
  }

  .item__category_mapping {
    height: 90%;

    .item__category_column {
      padding-right: 10px;
      width: 33%;
      height: 100%;
      box-sizing: border-box;
      display: flex;
      flex-flow: column;
    }

    .item_table {
      border-top: 0;
      border-right: 0;
      flex: 2;

      .item_row {
        cursor: default;
        display: flex;
        width: 100%;
        box-sizing: border-box;
        padding: 0 10px;
      }

      .item_row:hover {
        background-color: $LightBlue !important ;
      }
    }

    .item__row_name {
      flex: 1 auto;
    }

    .item__row_options {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-end;
      align-items: center;
      height: 30px;
    }

    .divider > .item__row_name {
      line-height: 29px;
    }

    .item__row_parent_name {
      font-size: 14px;
      color: $Grey;
      line-height: 14px;
    }

    .item__info {
      width: 20px;
      font-size: 17px;
      color: $IconGrey;
      padding-left: 3px;
      padding-top: 3px;
    }

    .selected .item__info {
      color: white;
    }

    .item__count_container {
      height: 100%;
    }

    .item__count {
      line-height: 15px;
      border: 1px solid $IconGrey;
      color: $IconGrey;
      border-radius: 10px;
      padding: 0 5px;
      font-size: 10px;
      max-width: 42px;
      display: inline-block;
    }

    .item__icon_wrapper {
      height: 17px;
      box-sizing: border-box;
    }

    .selected .item__count {
      border: 1px solid white;
      color: white;
    }

    .item__choose-button {
      height: 17px;
      line-height: 15px;
      padding-left: 3px;
      padding-right: 3px;
      font-size: 12px;
      color: $Blue;
      font-weight: normal;
      width: 60px;
      box-sizing: border-box;

      &:hover {
        background-color: $Blue;
        color: $White;
      }
    }
  }

  .item__search_form {
    width: 100%;
    margin-bottom: 15px;
    font-size: 12pt;
  }

  .item__smart_mapping {
    width: 33%;
    height: 29px;
  }
}

.item__informations {
  margin-bottom: 20px;

  .part_number_input {
    width: 34%;
  }

  .item_info {
    width: 22%;
    margin-left: 10px;
    color: $Blue;

    input {
      color: $Blue;
    }
  }

  .item_date {
    width: 20%;
    margin-left: 10px;
  }
}

.item_input {
  border-bottom: 1px solid $Grey;
  display: table;
  height: 29px;
  line-height: 29px;

  .item__input_icon {
    padding-right: 5px;
    padding-left: 5px;
    color: $Grey;
    display: table-cell;
    white-space: nowrap;
    width: 40px;
    text-align: center;
    vertical-align: bottom;
    font-size: 20px;
  }

  .item__input_text {
    padding-right: 5px;
    display: table-cell;
    font-size: medium;
    font-style: italic;
    box-sizing: border-box;
    width: 100%;
  }

  .item__input_button {
    background: $LightGrey;
    display: table-cell;
    width: 29px;
    font-size: 14px;
    border-left: 1px solid $Grey;
    color: grey;
    text-align: center;
    cursor: pointer;
  }
}

.item-part-type__details {
  padding-top: 24px;
  width: 25% !important;
  height: 100%;
  box-sizing: border-box;
  display: flex;
  flex-flow: column;
}

.item-part-type__description {
  background: $LightGrey;
  margin-bottom: 20px;
  margin-top: 5px;
  padding: 10px;
  border-left: 3px solid $Blue;
}

.item-part-type__art-nr {
  width: 40%;
  padding-left: 10px;
  box-sizing: border-box;
}

.item-part-type__art-description {
  width: 60%;
}

.item-part-type__table_container {
  flex: 2;
  overflow: auto;
}

.item-part-type__table {
  border-left: 3px solid $Blue !important;
  width: 100%;
  border-top: 0 !important;
  border-right: 0 !important;
  border-bottom: 0 !important;
  border-spacing: 0;
}

.item-part-type_row {
  .item-part-type__art-nr {
    display: inline-block;
  }

  .item-part-type__art-description {
    display: inline-block;
  }
}

.item__breadcrumb_container {
  display: inline-flex;
  align-items: center;
  box-sizing: border-box;
  width: 100%;
  margin-top: 20px;
  padding: 0 10px;
  border: 1px solid $Grey;
  line-height: 34px;
  cursor: pointer;
  border-left: 3px solid $Blue;
  user-select: none;
}

.item__breadcrumb_left {
  display: flex;
  flex: 1;
}

.item__breadcrumb_right {
  display: flex;
  flex-basis: 170px;
  align-items: center;
  justify-content: space-between;
  margin-right: 8px;
}

.item__breadcrump_button {
  height: 22px;

  .button__icon {
    bottom: 0 !important;
  }
}

.item__breadcrump_triangle--closed {
  width: 0;
  height: 0;
  border-top: 6px solid $Black;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
}

.item__breadcrump_triangle--open {
  width: 0;
  height: 0;
  border-bottom: 6px solid $Black;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
}

.item__breadcrumb-title {
  padding: 0 10px 0 5px;
  font-size: 18px;
  user-select: none;
  color: $Blue;
}

.item__breadcrumb_button {
  cursor: pointer;
  margin: 5px 10px;
  background: transparent;
  color: $IconGrey;
  border: 1px solid $Grey;

  &:hover {
    background-color: $Grey;
    color: $LightGrey;
  }
}

.item__breadcrumb_button .icon-edit--outline {
  vertical-align: middle;
}

.item__breadcrumb {
  display: inline-block;
  padding-left: 10px;
  cursor: pointer;
}

.item__breadcrumb_divider {
  padding-left: 10px;
  padding-right: 10px;
}

.item__population {
  display: inline-flex;
  align-items: center;
}

.item__population-dropdown {
  .ant-select-selection {
    width: 200px;
    margin-left: 10px;
    margin-right: 10px;
  }
}

.item__delete button {
  text-align: right;
  border-radius: 0;
  border: 1px solid $Blue;
  background: $White;
  color: $Blue;
  padding: 2px 10px;
  cursor: pointer;
}

// React transition group start
.part-type-mapping-enter {
  height: 0;
}

.part-type-mapping-enter.part-type-mapping-enter-active {
  height: 75%;
  transition: all $middle ease-in-out;
}

.part-type-mapping-leave {
  height: 75%;
}
.part-type-mapping-leave.part-type-mapping-leave-active {
  height: 0;
  transition: all $middle ease-in-out;
}
//React transition group end

.category__input {
  border-bottom: none;
  width: 100%;
}

.category__search_icon {
  font-size: 22px;
  line-height: 29px !important     ;
  vertical-align: middle;
  width: 20px !important;
}

.category__breadcrumb_hover {
  display: block;
  font-size: 16px;
  height: 30px;
}

.category__breadcrumb_hover_name {
  display: inline-block;
  vertical-align: top;
  position: relative;
  padding-left: 10px;
  padding-right: 20px;
  box-sizing: border-box;
  background-color: $LightBlue;
}

.category__breadcrumb_hover_wrapper {
  margin-left: 5px !important;
  opacity: 1 !important;
  background-color: transparent !important;
  color: $Black !important;
  border: none !important;
  border-radius: 0 !important;
  padding: 0 2px 0 0 !important;
  pointer-events: auto !important;
  transition: none !important;

  &.place-left {
    &:after {
      border: none !important;
    }
  }

  &:hover {
    visibility: visible !important;
    opacity: 1 !important;
  }
}

.category__border {
  border-left: 3px solid $Blue !important;
}

.item__information {
  height: calc(100% - 120px);
  margin-top: 20px;
}

.item__information-text {
  display: inline-block;
  border-right: 1px solid $Grey;
  padding-left: 10px;

  &--indent {
    @extend .item__information-text;
    padding-left: 40px;
  }
}

.item__information-value {
  display: inline-flex;
  padding-left: 10px;
  padding-right: 10px;
  width: 60%;

  input {
    min-width: 70px;
    max-width: 120px;
    flex: 1;
  }

  input[type='date'] {
    flex: 0 0 120px;
  }
}

.item__information-main {
  flex: 33%;
  height: 100%;
  margin-right: 20px;
  flex-direction: column;

  .item__information-text {
    width: 40%;
  }
}

.item__information-main-table {
  flex: auto;
}

.item__information-order-informations {
  flex: 33%;
  height: 100%;
  margin-right: 20px;
  flex-direction: column;

  .item__information-text {
    width: 40%;
  }
}

.item__information-order-informations-table {
  flex: auto;
}

.item__information-category-informations {
  flex: 33%;
  flex-direction: column;

  .item__information-text,
  .item__information-value {
    width: 50%;
  }

  .item__information-value {
    input {
      min-width: 100px;
    }
  }
}

.item__information-category-informations-table {
  flex: auto;
}

.item__information-minimum-order-dropdown {
  width: 120px !important;
  margin-left: 10px;

  & select {
    width: 120px;
  }
}

.item__information-qpa {
  display: flex;
  align-items: center;
}
