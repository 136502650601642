@import 'project_variables.scss';

.image-container {
  position: relative;
  border: 1px solid $AntBorderGrey;
  border-radius: 4px;
  padding: 4px;
  .image {
    opacity: 1;
    -webkit-transition: 0.3s ease-in-out;
    transition: 0.3s ease-in-out;
    height: 100%;
    width: 100%;
    object-fit: contain;
  }
  &:hover {
    .image {
      opacity: 0.3;
    }
    .action-elements {
      visibility: visible;
    }
  }
  .action-elements {
    transition: 0.1s ease-in-out;
    visibility: hidden;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    align-items: center;
    justify-content: center;

    .element {
      cursor: pointer;
      padding: 0 5px;
      font-size: 20px;
    }
  }
}
