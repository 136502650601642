@import 'project_variables.scss';

@mixin color($name, $color) {
  .button-pdm.#{$name} {
    border: 1px solid $color;
    color: $color;
    background-color: $White;
    transition: background 0.3s, color 0.3s;

    &.inverted {
      color: $White;
      background-color: $color;

      &.loading {
        .button__loader > div {
          background: $White;
        }
      }
    }

    &.dashed {
      border: 1px dashed $color;
    }

    &.hover {
      &:hover {
        color: $White;
        background-color: $color;

        &.inverted {
          color: $color;
          background-color: $White;
        }
      }
    }

    &.hover-blue {
      background-color: inherit;
      &:hover {
        color: $Blue;
        background-color: $White;
        border: 1px solid $Blue;
      }
    }

    &.border-left {
      border-left: 3px solid $color;
    }

    &.loading {
      .button__name,
      .button_icon,
      .button__delete {
        opacity: 0;
      }
      .button__loader > div {
        background: $color;
      }
    }
  }
}

.button-pdm {
  position: relative;
  display: flex;
  justify-content: space-around;
  box-sizing: border-box;
  align-items: center;
  align-content: center;
  padding: 0.15em 0.3em;
  font-size: 0.8em;
  cursor: pointer;
  user-select: none;

  &.small {
    font-size: 0.5em;
  }

  &.big {
    font-weight: 700;
    height: 30px;

    .button__icon {
      font-size: 1.6em;
      bottom: -0.05em;
    }
  }

  .button__name {
    overflow: hidden;
    text-align: center;
    white-space: nowrap;
  }

  .button__icon {
    position: relative;
    bottom: -0.3em;
    margin-left: 0.2em;
  }

  .button__delete {
    position: relative;
    padding-left: 0.6rem;
  }

  &.application__checkbox-button {
    justify-content: center;
  }

  @keyframes button-loader {
    from {
      opacity: 1;
      transform: translateY(0);
    }
    to {
      opacity: 0.1;
      transform: translateY(-0.4rem);
    }
  }

  .button__loader {
    position: absolute;
    display: flex;
    justify-content: center;
    padding-top: 0.4rem;
  }

  .button__loader > div {
    width: 0.5rem;
    height: 0.5rem;
    margin: 0 0.2rem;
    border-radius: 50%;
    animation: button-loader 0.6s infinite alternate;
  }
  .button__loader > div:nth-child(2) {
    animation-delay: 0.2s;
  }
  .button__loader > div:nth-child(3) {
    animation-delay: 0.4s;
  }
}

.ant-btn {
  &.ant-button-disabled {
    pointer-events: none;
    background-color: $LightGrey;
    color: rgba(0, 0, 0, 0.25);
  }
}

@include color('grey', $Grey);
@include color('blue', $Blue);
@include color('red', $Red);
@include color('white', $White);
@include color('green', $Green);
