@import 'project_variables.scss';

.delivery-log__process {
  color: $Yellow;
}

.delivery-log__success-button {
  color: $Green !important;
  border-color: $Green !important;
  cursor: default !important;
}

.delivery-log__warn-button {
  color: $Yellow !important;
  border-color: $Yellow !important;
}

.delivery-content__wrapper {
  flex: 1;
}

.delivery__receiver-plan-label {
  font-style: italic;
  color: $Grey;
}

.delivery__log-table {
  box-shadow: $LightBoxShadow;

  .ReactVirtualized__Grid__innerScrollContainer {
    box-shadow: $LightBoxShadow;
  }
}

.delivery-content__wrapper {
  overflow: hidden;

  .delivery__log-table-header {
    text-transform: none;
  }

  .delivery__log-table-row {
    border-bottom: 1px solid $AntTableBorderGrey;
  }
}

.delivery__log-top-spinner.ant-spin {
  width: 100%;
  margin-top: 50px;
}

.delivery__error-table {
  .anticon {
    &.blue {
      color: $Blue;
    }

    &.yellow {
      color: $Yellow;
    }

    &.red {
      color: $Red;
    }
  }
}
