.export-aces__version {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 20px;
}

.export-aces__label {
  margin-right: 20px;
  margin-bottom: 5px;
}

.export-aces__grouping {
  display: flex;
  flex-wrap: wrap;
}
