@import 'project_variables';

.extended-info__drawer-warn-icon {
  .anticon {
    color: $Red;
  }
}

.extended-info__drawer {
  border: 1px solid $AntTableBorderGrey;
  border-radius: 4px;
}

.extended-info__drawer-list-row {
  display: grid;
  grid-template-rows: 40px;
  grid-template-columns: minmax(150px, 200px) minmax(260px, 400px) minmax(350px, 1fr);
  padding: 0 10px;
  grid-column-gap: 10px;
  border-bottom: 1px solid $AntTableBorderGrey;
  align-items: center;

  &_data {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  &_text {
    text-align: right;
  }
}

.extended-info-drawer__overwrite-wrapper {
  .extended-info__select-tag .extended-info__select-tag_length {
    border-right: 0px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
  }
  .ant-btn-primary {
    border-left: 0px;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
  }
  .ant-select-selector {
    border-right: 0px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
  }
  &:hover {
    .ant-select-selector {
      border-color: $InputFocusBlue;
    }
    .extended-info__select-tag_length {
      border-color: $InputFocusBlue;
    }
    .ant-btn-primary {
      border-color: $InputFocusBlue;
    }
  }
}

.extended-info__warning-icon_empty-placeholder {
  width: 14px;
}

.extended-info__row {
  line-height: 40px;
  border-bottom: 1px solid $AntBorderGrey;
}

.extended-info__select_tag_red {
  &.ant-tag {
    background-color: $BgRed;
    border-color: $Red;
    color: $TextGrey;
  }
  .anticon svg {
    color: $Red;
  }
}
.extended-info__select_tag_yellow {
  &.ant-tag {
    background-color: $BgYellow;
    border-color: $Yellow;
    color: $TextGrey;
  }
  .anticon svg {
    color: $Yellow;
  }
}
.extended-info__select_tag_blue {
  &.ant-tag {
    background-color: $LightBlue;
    border-color: $Blue;
    color: $TextGrey;
  }
  .anticon svg {
    color: $Blue;
  }
}

.extended-info__checkable-tag_yellow {
  .ant-tag.ant-tag-checkable.ant-tag-checkable-checked {
    background-color: $BgYellow;
    border-color: $Yellow;
    color: $TextGrey;
  }
}

.extended-info__checkable-tag_red {
  .ant-tag.ant-tag-checkable.ant-tag-checkable-checked {
    background-color: $BgRed;
    border-color: $Red;
    color: $TextGrey;
  }
}

.extended-info__checkable-tag_blue {
  .ant-tag.ant-tag-checkable.ant-tag-checkable-checked {
    background-color: $LightBlue;
    border-color: $Blue;
    color: $TextGrey;
  }
}

.extended-info__checkable-tags {
  .ant-tag-checkable {
    border: 1px solid $Grey;
    height: 24px;
    line-height: 23px;
    margin-right: 3px;
    margin-bottom: 3px;
  }

  .ant-tag-checkable:not(.ant-tag-checkable-checked):hover {
    border: 1px solid $Blue;
  }

  .extended-info__tags-wrapper {
    line-height: 30px;
    padding-top: 3px;
    padding-bottom: 3px;
    align-items: center !important;
  }
  .extended-info__tag-input {
    margin-top: 3px;
  }
}

.extended-info__select-tag {
  .ant-select-selector {
    border-right: 0px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
  }
  .extended-info__select-tag_length {
    border: 1px solid $AntBorderGrey;
    border-radius: 4px;
    white-space: nowrap;
    padding-bottom: 1px;
    border-left: 0px;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    padding-left: 7px;
    padding-right: 7px;
    line-height: 1.5;
    height: 24px;
  }
  .extended-info__select-tag_text-warning {
    .extended-info__select-tag_length {
      color: $Red;
    }
  }
  .extended-info__input_highlight {
    .extended-info__select-tag_length {
      transition-delay: 0.1s;
      border-color: $InputFocusBlue;
      box-shadow: $LightBlueShadow;
    }
  }
}

.extended-info__select-dropdown {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.extended-info__select-tag_wrapper {
  padding-top: 3px;
  padding-bottom: 3px;

  .ant-select {
    flex: 1 1 100%;
  }

  .ant-select-sm {
    line-height: 16px;
  }

  .ant-tag {
    margin-right: 5px;
    line-height: 14px;
    height: 18px;
    margin-top: 1px;
    margin-bottom: 1px;
  }

  &:hover {
    .ant-select-selector {
      border-color: $InputFocusBlue;
    }
    .extended-info__select-tag_length {
      border-color: $InputFocusBlue;
    }
  }
}

.extended-info__drawer-select {
  .ant-select-multiple {
    .ant-select-selector {
      max-height: 30px;
      overflow-y: auto;
      overflow-x: hidden;
    }
  }
}

.extended-info__form {
  .ant-collapse {
    border-width: 0px;
    padding: 0px;
  }
  .ant-collapse-content > .ant-collapse-content-box {
    padding: 1px 0px;
  }
  .ant-form-item {
    margin: 0px;
  }
  .extended-info__label {
    white-space: pre-wrap;
    min-width: 340px;
    max-width: 400px;
  }
  .right__warn-icon {
    padding-right: 10px;
    padding-left: 10px;
    display: flex;
    flex: 0 0 20px;
    align-items: center;
  }
  .multi-edit-button {
    display: flex;
    align-items: center;
  }
}

.extended-info__analysis-message {
  &.blue {
    color: $Blue;
  }
  &.yellow {
    color: $Yellow;
  }
  &.red {
    color: $Red;
  }
  &.white {
    color: $White;
  }
}
