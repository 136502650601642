@import 'project_variables.scss';

.media-and-marketing-copy {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.media-and-marketing-copy__content {
  flex: 1;
  overflow: auto;
  padding: 20px;
}

.digital-asset-parent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  padding: 20px;
  overflow: hidden;
}

.digital-asset-parent__edit-container {
  display: flex;
  height: 100%;
}

.digital-asset-parent__list-container {
  display: flex;
  flex-direction: column;
  width: 420px;
  margin-right: 20px;
}

.digital-asset-parent__list-search {
  margin-bottom: 20px;
}

.digital-asset-parent__list {
  display: flex;
  flex-direction: column;
  flex: 1;
  box-shadow: $LightBoxShadow;
  overflow: auto;
}

.digital-asset-parent__list-single {
  display: flex;
  align-items: center;
  border-bottom: 1px solid $Grey;
  // padding: 10px;
  cursor: pointer;
  min-height: 45px;

  &--img-title {
    width: 310px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &--selected {
    @extend .digital-asset-parent__list-single;
    background-color: $Blue !important;
    color: $White;
  }
  &:nth-child(even) {
    background-color: $LightGrey;
  }

  .thumbnail {
    margin: 0 10px;
    min-width: 35px;
    max-width: 35px;
    min-height: 35px;
    max-height: 35px;
    object-fit: cover;
  }

  .text {
    flex: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: flex;
    align-items: center;
    height: 100%;
  }
}

.digital-asset-parent__edit {
  flex: 1;
  border-left: 3px solid $Blue;
  justify-content: space-around;
  padding: 20px;
  box-shadow: $LightBoxShadow;
  margin: 2px;
  overflow: auto;
  line-height: initial;
}

.digital-asset-parent__edit-content {
  min-width: 900px;
  display: flex;
}

.digital-asset-parent__edit-preview {
  width: 33%;
  margin-right: 20px;

  img {
    max-width: 100%;
  }
}

.digital-asset-parent__edit-column {
  height: 100%;
  width: 33%;
  margin-right: 20px;
  display: flex;
  flex-direction: column;
}

.digital-asset-parent__edit-box {
  display: flex;
  flex-direction: column;
}

.edit-box__top {
  margin-bottom: 20px;
}

.edit-box__content {
  flex: 1;
  padding: 20px;
}
