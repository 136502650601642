@import 'project_variables.scss';

@mixin border-radius($radius) {
  -webkit-border-radius: $radius;
  -moz-border-radius: $radius;
  -ms-border-radius: $radius;
  border-radius: $radius;
}
@mixin container-size($size) {
  @if $size == 1200 {
    @media (min-width: $size+px) {
      @content;
    }
  } @else if $size == 992 {
    @media (min-width: $size+px) {
      @content;
    }
  } @else if $size == 768 {
    @media (min-width: $size+px) {
      @content;
    }
  }
}

.clearfix {
  &:after {
    content: '';
    display: table;
    clear: both;
  }
}

ul {
  margin: 0;
  padding: 0;
}

body {
  height: 100%;
  width: 100%;
  margin: 0;
  color: $FontColor;
  font-size: 14px;
  font-family: 'Chinese Quote', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC',
    'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif,
    'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
}

.logo {
  flex: 1;
  background-image: url('../images/logos/PDM-Logo.svg');
  background-repeat: no-repeat;
  margin: 12px 16px 7px 26px;
}

.logo-beta {
  flex: 1;
  background-image: url('../images/logos/PDM-Logo-BETA.svg');
  background-repeat: no-repeat;
  margin: 12px 16px 7px 26px;
}

.scrollable {
  overflow-x: hidden;
  &::-webkit-scrollbar {
    width: 16px;
    display: block !important;
  }
  &::-webkit-scrollbar-track {
    border: solid 3px transparent;
    border-radius: 10px;
  }
  &::-webkit-scrollbar-thumb {
    box-shadow: inset 0 0 16px 16px rgba($color: $IconGrey, $alpha: 0.3);
    border: solid 4px transparent;
    border-radius: 10px;
  }
  &::-webkit-scrollbar-thumb:hover {
    box-shadow: inset 0 0 16px 16px rgba($color: $IconGrey, $alpha: 1);
    border: solid 4px transparent;
  }
}

.scrollable--horizontal {
  overflow-x: auto !important;
}

button:focus,
input:focus,
div:focus {
  outline: none;
}

.search {
  margin-top: 20px;
}

#search {
  @include border-radius(0px);
  border: 1px solid $Grey;
  padding: 10px;
}

.login a {
  display: none;
}

#intercom-container {
  z-index: 1000 !important;

  .intercom-launcher-frame {
    transform: scale(1.04);
  }
}

.intercom-lightweight-app {
  .intercom-launcher {
    transform: scale(1.04);
  }
}

.help-icon-container {
  width: 50px;
  height: 50px;
  position: absolute;
  left: 138px;
  bottom: 30px;
  border-radius: 50%;

  .help-icon {
    width: 50px;
    border-radius: 50%;
  }

  &.collapsed {
    left: 15px;
    bottom: 10px;
  }
}

.spinner-center {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 20px;
}

.select-list {
  .ant-input-search {
    input {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
  }
}

.no-scrollbar {
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
}
