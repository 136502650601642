@import 'project_variables.scss';

.cfs_item-table {
  .ant-form-item {
    margin: 0;
  }
}

.cfs-wysiwyg-editor-wrapper {
  height: 300px;
  padding-top: 4px;
  padding-bottom: 4px;
  white-space: initial;

  ul {
    padding-inline-start: revert;
  }

  .jodit-react-container {
    min-height: 290px;
    max-height: 290px;
  }

  .cfs-jodit-editor {
    &:hover {
      border: 1px solid $InputFocusBlue;
    }
  }

  h1 {
    font-size: 2em;
  }

  blockquote {
    margin: revert;
  }
}

.cfs-table-drag {
  cursor: move;
  font-size: 20px;
}

.cfs-preview-type {
  width: 300px !important;
}

.cfs-options__table {
  margin-top: 20px;
  border: 1px solid $AntBorderGrey;
  border-radius: 4px;

  &__row {
    border-bottom: 1px solid $AntBorderGrey;
    padding: 5px 10px;
    z-index: 1010;

    &:last-child {
      border-bottom: none;
    }
  }

  &__drag {
    padding-right: 10px;
    font-size: 16px;
    cursor: move;
  }
}

.cfs__external-link {
  img {
    height: 16px;
    width: 16px;
  }
}

.cfs__item-top-bar-search {
  max-width: 480px;
}

.stylizedHelper {
  border: 1px solid #efefef;
  box-shadow: 0 5px 5px -5px rgba(0, 0, 0, 0.2);
  background-color: rgba(255, 255, 255, 0.9);
  border-radius: 3px;

  &.horizontalItem {
    cursor: col-resize;
  }

  &.gridItem {
    background-color: transparent;
    white-space: nowrap;
    box-shadow: none;
    border: none;

    .wrapper {
      background-color: rgba(255, 255, 255, 0.9);
      box-shadow: 0 0 7px rgba(0, 0, 0, 0.15);
    }
  }

  &:focus {
    box-shadow: 0 0px 5px 1px #4c9ffe;
  }
}
