@import 'project_variables.scss';

.sidebar-left {
  overflow: auto;
  margin-bottom: 100px;
}

.sidebar-brand-products {
  height: 100%;
  overflow: auto;
  box-shadow: $LightBoxShadow;

  .sidebar-brand-products__menu-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .sidebar-brand-products__divider {
    border-top: 1px solid $AntBorderGrey;
  }
}

.products-page {
  height: 100vh;
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;

  .products-edit-outer {
    height: 100%;
  }

  .brand-products-content {
    overflow: auto;
    box-shadow: $LightBoxShadow;
    display: flex;
    flex-direction: column;
    height: 100%;
    background: $White;
  }
}

.layout {
  height: 100vh;
}

.content {
  padding: 20px;
  background: $White;
  box-shadow: $LightBoxShadow;
}

.main-page-content {
  height: 100vh;
  background: $White;
}

.badge-small {
  .ant-badge-count {
    height: 14px;
    line-height: 14px;
    padding: 0 4px;
  }
}

.page-layout {
  display: flex;
  flex-flow: column nowrap;
  height: 100%;
  background: $AntBackgroundGrey;

  &__top-bar {
    background: $AntBackgroundGrey;
    padding-bottom: 3px;

    &__container {
      display: flex;
      flex-flow: row nowrap;
      justify-content: flex-start;
      align-items: center;

      background: $LightGrey;
      padding: 10px 10px 10px 15px;
      margin-bottom: 5px;
      box-shadow: $LightBoxShadow;
    }

    &__search {
      max-width: 500px;
    }

    &__actions {
      margin-left: auto;
    }
  }

  &__content {
    height: 100%;
    overflow: auto;
    box-shadow: $LightBoxShadow;
    background: $White;
  }
}
