.receiver-catalogue {
  height: 100vh;
  padding-bottom: 5px;
}

.receiver-catalogue__filter-brand {
  padding: 10px 10px 0 10px;
  width: 200px;

  &__label {
    font-weight: 500;
    padding-bottom: 10px;
  }
  &__dropdown {
    width: 100%;
  }
}

.receiver-catalogue__sidebar-divider {
  margin-bottom: 0px !important;
}

.receiver-catalogue-list__container {
  display: flex;
  flex-direction: column;
  width: 100%;

  .catalogue-list-container {
    margin-left: 0;
  }
}

.receiver-catalogue-list__header {
  background: $LightGrey;
  display: grid;
  grid-template-rows: 40px;
  grid-template-columns: 15px 2fr 100px 1fr 160px 1fr 180px;
  padding: 0 10px;
  grid-column-gap: 10px;
  align-items: center;
  font-weight: 500;
  box-shadow: $LightBoxShadow;
  padding-right: 30px;
}

.receiver-catalogue__list-scrollbar {
  .receiver-catalogue__list-row {
    padding-right: 10px;
  }
}

.receiver-catalogue__list-row {
  display: grid;
  grid-template-rows: 40px;
  grid-template-columns: 15px 2fr 100px 1fr 160px 1fr 180px;
  padding: 0 10px;
  grid-column-gap: 10px;
  border-bottom: 1px solid $MediumGrey;
  align-items: center;
  user-select: none;
  padding-right: 30px;

  &__request-status {
    display: flex;
    flex-direction: row;
    justify-self: left;
    align-items: center;
    .ant-tag {
      margin: 0;
      cursor: auto;
    }
  }

  &__actions {
    justify-self: end;
  }

  &:hover {
    box-shadow: 0 0 11px rgba(33, 33, 33, 0.2);
  }

  .green {
    color: $Green;
  }
  .yellow {
    color: $Yellow;
  }
  .grey {
    color: $Grey;
  }
  .red {
    color: $Red;
  }
}

.receiver-catalogue__spinner {
  .ant-spin-dot {
    position: absolute;
    top: 50%;
    left: 50%;
  }
}

.receiver-catalogue__spinner-bottom {
  .ant-spin-dot {
    position: absolute;
    bottom: 20px;
    left: 50%;
  }
}

.receiver-catalogue__product-button.ant-btn {
  border-radius: 10px;
  line-height: 12px;
  height: 20px;
}

.receiver__import-tooltip-row {
  display: grid;
  grid-template-columns: 50px 30px 200px 200px;
}

.receiver__source-filter-popover {
  .ant-popover-inner-content {
    padding: 0;
  }
}

.receiver-catalogue__auto-delivery-link {
  border-bottom: 1px solid $MediumGrey;
  text-decoration: none;
}
