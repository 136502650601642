@import 'project_variables.scss';

.drawer-submit {
  &__content {
    flex: 1;
    padding: 24px;
    overflow: auto;

    &.margin-top {
      margin-top: 20px;
    }
  }

  &__bottom {
    display: flex;
    padding: 12px;
    border-top: 1px solid $AntBorderGrey;

    &-cancel {
      margin: 0 20px;
    }
  }

  .ant-drawer-body {
    flex: 1;
    display: flex;
    flex-direction: column;
    padding: 0;
    overflow: hidden;
  }

  .ant-drawer-wrapper-body {
    display: flex;
    flex-direction: column;
  }
}
