@import 'project_variables.scss';

.user__wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.user__drawer-buttons {
  display: flex;
  justify-content: space-between;
}

.user-content__wrapper {
  height: 100%;
  overflow: hidden;
  flex: 1;
}

.user__role-button {
  margin-right: 20px;
}

.user__add-role-button {
  margin-top: 15px;
  margin-bottom: 20px;
}

.user__search-input.ant-input-search {
  width: 500px;
}

.user__reset-pw-button {
  margin-left: 5px;
}

.user__role-menu-column {
  display: flex !important;
  flex-direction: column;

  .user__role-menu-buttons {
    padding-left: 24px;
    padding-right: 16px;
    border-right: 1px solid $AntTableBorderGrey;
  }
}

.user__role-form-column {
  padding-left: 15px !important;
  padding-right: 15px !important;
}

.user__role-form {
  .ant-form-item {
    margin-bottom: 10px;
  }

  .ant-switch {
    width: 55px;
    margin-left: 20px;
  }

  .user__permission-info {
    margin-left: 5px;
    color: $Blue;
  }

  .user__role-form-segments {
    .ant-col:first-of-type {
      padding-left: 5px !important;
    }
    .ant-col:last-of-type {
      padding-right: 3px !important;
    }
  }

  .user__permission-row {
    .user__permission-name {
      flex: 1;
      line-height: 35px;
    }

    .ant-form-item {
      margin-bottom: 0;
    }
  }
}

.user__settings-form {
  .ant-form-item {
    margin-bottom: 10px;
  }

  .ant-switch {
    width: 55px;
    margin-left: 20px;
  }

  .user__permission-row {
    .user__permission-name {
      flex: 1;
      line-height: 35px;
    }

    .user__permission-info {
      margin-left: 5px;
      color: $Blue;
    }

    .ant-select {
      width: 100px;
    }
  }

  .user__admin-permission {
    margin-top: 30px;
  }

  .user__drawer-button-row {
    display: flex;
    justify-content: flex-end;
  }

  .user__drawer-disable-button {
    margin-top: 30px;
  }

  .user__divider_wrapper {
    margin-top: 40px;
  }
}

.user__divider_wrapper {
  margin-top: 40px;

  .ant-select {
    width: 100px;
    margin-left: 20px;
    margin-top: 12px;
  }

  .ant-switch {
    width: 55px;
    margin-left: 20px;
    margin-top: 16px;
  }
}

.user__divider {
  &.ant-divider-horizontal.ant-divider-with-text-left::before {
    width: 0;
  }
  &.ant-divider-horizontal.ant-divider-with-text-left {
    .ant-divider-inner-text {
      padding-left: 0;
    }
  }
}

.user__role-menu {
  .drawer-submit__content {
    padding-left: 0;
    padding-right: 0;

    padding-top: 0;
    padding-bottom: 0;
    height: 100%;
    overflow: hidden;
  }
}
