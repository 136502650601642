@import 'project_variables.scss';

// colors
.green {
  color: $Green;
}

.yellow {
  color: $Yellow;
}

.grey {
  color: $Grey;
}

.red {
  color: $Red;
}

.yellow {
  color: $Yellow;
}

.blue {
  color: $Blue;
}

// FLEX CSS

.flex {
  display: flex;
}

.flex-col {
  display: flex;
  flex-direction: column;
}

// flex wrap
.flex-no-wrap {
  flex-wrap: nowrap;
}

.flex-wrap {
  flex-wrap: wrap;
}

// align-items
.items-center {
  align-items: center;
}

// justify content
.justify-start {
  justify-content: flex-start;
}

.justify-center {
  justify-content: center;
}

.justify-end {
  justify-content: flex-end;
}

.justify-between {
  justify-content: space-between;
}

.justify-around {
  justify-content: space-around;
}

// flex grow
.flex-1 {
  flex: 1 1 0%;
}

.flex-auto {
  flex: 1 1 auto;
}

.font-medium {
  font-weight: 500;
}
