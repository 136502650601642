@import 'project_variables.scss';

.add-new-button {
  display: flex;
  box-sizing: border-box;
  justify-content: center;
  align-items: center;
  border: 1px dashed $Blue;
  padding: 0;
  margin-bottom: 0.5rem;
  color: $Blue;
  width: 100%;
  cursor: pointer;
  user-select: none;
  transition: background 0.3s, color 0.3s;

  &:hover {
    background: $Blue;
    color: $White;
  }

  &.main {
    height: 3rem;
    font-size: 1.2rem;
  }

  &.sub {
    height: 2rem;
    font-size: 1rem;
  }
}
