@import 'project_variables.scss';

.receiver__bulk-invite-button {
  height: 32px;
  border: 0;
  background-color: $Blue;
  color: $White;
  padding: 4px 15px;
  &:hover {
    cursor: pointer;
    background-color: $InputFocusBlue;
  }
}

.receiver-container {
  .page-layout__top-bar__container {
    justify-content: space-between !important;
  }
}

.receiver-content__wrapper {
  flex: 1 1 auto;
}

.receiver__integrated-tag.ant-tag {
  margin-left: 15px;
  transform: translateY(-9px);
  height: 16px;
  line-height: 14px;
  font-size: 11px;
}

.receiver-table {
  .ant-tag {
    cursor: auto;
  }

  .ReactVirtualized__Table__row {
    &:hover {
      background-color: $AntHoverBlue;
    }
  }
}

.receiver__company-profile {
  padding-left: 40px;
  padding-right: 40px;
  .receiver__modal-grid {
    display: grid;
    grid-template-columns: #{'repeat(auto-fill, minmax(min(350px, 100%), 1fr))'};
    grid-column-gap: 40px;
    grid-row-gap: 30px;
  }
  .receiver__modal-divider {
    height: 32px;
    width: 100%;
    background: $LightGrey;
  }
  .about-us {
    margin-top: 80px;
  }

  .warehouse-distributor,
  .social-profile,
  .receiver__contact-info {
    display: flex;
    flex-direction: column;
    .receiver__profile-edit-button {
      visibility: hidden;
      align-self: flex-end;
      border: 0;
      margin-top: auto;
    }
    &:hover {
      .receiver__profile-edit-button {
        visibility: inherit;
      }
    }
  }
}

.receiver__detail-modal {
  background: $LightGrey;

  .ant-modal-header {
    background: $LightGrey;
  }

  .ant-modal-content {
    background: $LightGrey;
    box-shadow: none;
  }

  .receiver__modal-divider {
    height: 32px;
    width: 100%;
    background: $LightGrey;
  }

  .receiver__modal-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(270px, 1fr));
    grid-column-gap: 20px;
    grid-row-gap: 20px;
  }

  .about-us {
    margin-top: 20px;
  }
}

.receiver__invite-modal {
  .ant-modal-body {
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 0;
  }

  .receiver__template-button {
    padding-left: 3px;
  }

  .receiver__bulk-form-wrapper {
    .receiver__bulk-action-buttons {
      margin-top: 25px;
    }

    .receiver__invite-brand-select {
      width: 200px;

      .ant-select-multiple {
        .ant-select-selector {
          max-height: 32px;
          overflow-y: auto;
          overflow-x: hidden;
        }
      }
    }

    .ant-form-item {
      margin-bottom: 10px;
      margin-right: 10px;

      .ant-form-item-label {
        font-weight: 500;
        line-height: initial;
      }
    }
  }
}

.receiver__declined-icon {
  margin-left: 5px;
}

.receiver__reject-popover {
  width: 410px;
}

.receiver__brand-popover {
  &__company-profile {
    padding: 0 !important;
  }
  &__action-buttons {
    margin: 14px 0 0 20px !important;
  }
}

.receiver__profile-link {
  .ant-btn {
    padding: 0;
  }
}
