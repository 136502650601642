@import 'project_variables.scss';

.item-info {
  padding: 10px;

  li {
    padding-top: 5px;
  }
}

.item_info__header__select-existing-link {
  color: $Blue;
  cursor: pointer;
  span {
    font-size: 16px;
    padding-left: 5px;
    padding-right: 2px;
  }
}

.item_info__multiple-selected {
  margin: 0;
}

.item-info__no-item-title {
  margin: 0;

  &_small-header {
    margin: 0;
    margin-left: 10px;
  }
}

.item-info__no-item-body {
  margin: 0;
}

.item-info__list {
  padding-left: 20px;
}

.item-info__list-item {
  list-style: disc;
}
